
import { CustomGridRender, FilterValueDto, QueryResultDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { Trip_GRIDRENDER, Trip_LOCAL,   TripViewModelQuery,  Trip_FILTERS_LOCAL, TripViewModel } from '../Model/TripModels';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class TripApi {
  TripGetGrid = async (tableQuery: TripViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "Trip/Get", options);
    var res = await response.json();
    return res;
  }
  TripCreate = async (model: TripViewModel): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Trip";
    let response = await fetch(url, options);
    return await response.json();
  }

  TripGetResource = async (id?:string): Promise<ResultDto<TripViewModel>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Trip/Resource";
    if(id !== undefined)
    url += "?id="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  TripDelete = async (id?:string): Promise<ResultDto> => {
    const options = {
      method: "DELETE",
      headers: await GetHeader(),
    };
    let url = basePath + "Trip";
    if(id !== undefined)
    url += "?id="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  TripEdit = async (model: TripViewModel): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Trip";
    let response = await fetch(url, options);
    return await response.json();
  }


  TripDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Trip/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }
  TripDownloadExcell = async (id: string): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Trip/Excell?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }


  TripGetFilters = async (columName: string, columValue?: string, queryFilter?: TripViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "Trip/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json())  as ResultDto<FilterValueDto[]>;
  }
}


export class TripApiOffline {
  TripGetGrid = async (tableQuery: TripViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    let items: TripViewModel[] | null = await localforage.getItem(Trip_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(Trip_GRIDRENDER);
//todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.TripCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.TripType?.forEach(s => {
    //   items = items?.filter(x => x.TripType === s) ?? []
    // });
    let res: ResultDto< QueryResultDto >= {data :{ gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length }}
    return res;
  }

  TripDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Trip/File?id=" + id;
    let response = await fetch(url, options);
     return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  TripGetFilters = async (columName: string, columValue?: string, queryFilter?: TripViewModelQuery): Promise<ResultDto<FilterValueDto[]>  > => {
    let items: ResultDto<FilterValueDto[] >    = {data: await localforage.getItem(Trip_FILTERS_LOCAL + columName) ?? []};
    return items;
  }
}

