import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import DatePickerComponent from '../../Components/FormAndFilters/DatePickerComponent';
import InputPlaceComponent from '../../Components/FormAndFilters/InputPlaceComponent';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';
import MappaConPercorso from '../../Components/MappaConPercorso';
import '../../Css/AziendaList.css';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { ProductInTripViewModel, TripViewModelResource } from '../../Model/TripModels';
import { VehicleViewModelQuery } from '../../Model/VehicleModels';
import { CreateTrip } from '../../Redux/Action/Trip/TripCreateAction';
import { UpdateTrip } from '../../Redux/Action/Trip/TripEditAction';
import { GetVehicleViewModel } from '../../Redux/Action/Vehicle/VehicleGridAction';
import { RootState } from '../../Redux/Store/rootStore';
import ProductInTripComponent from '../Component/Trip/ProductInTripComponent';
import ProductInTrip from '../Forms/ProductInTrip';
import { GetAziendaViewModel } from '../../Redux/Action/Azienda/AziendaGridAction';
import { AziendaViewModelQuery } from '../../Model/Azienda';

interface Props {
  action: {
    closeModal(changed: boolean): any | void
    onCreate?: (data: TripViewModelResource) => void
  }
  isVisibleModal: boolean
  edit: boolean,
  data?: TripViewModelResource | null,
  id?: string
}

const TripModal: React.FC<Props> = (props) => {
  const [isVisibleModal, setisVisibleModal] = useState(false);
  const [ProductInTripEdit, setInTripEdit] = useState(false);
  const [inTripSelected, setInTripSelected] = useState<ProductInTripViewModel | null>(null);
  const Aziende = (state: RootState) => state.AziendaGridReducer.gridResult;
  let AziendeResource = useSelector(Aziende);


  const Vehicle = (state: RootState) => state.VehicleGridReducer.gridResult;
  let VehicleResource = useSelector(Vehicle);

  useEffect(() => {
    GetAziendaViewModel({} as AziendaViewModelQuery);
    GetVehicleViewModel({} as VehicleViewModelQuery);
    if (props.edit === false && props.data !== null && props.data !== undefined) {
      setFormData(props.data);
    }
  }, [])

  useEffect(() => {
    if (props.data !== null && props.data !== undefined) {
      setFormData(props.data);
    }
  }, [props.data])



  const validazioneClient = (formData: TripViewModelResource | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    if (stringIsNullOrEmpty(formData?.destination))
      addInvalidProperty("destination");

      if (stringIsNullOrEmpty(formData?.nomeTrasportatore))
      addInvalidProperty("nomeTrasportatore");

    if (stringIsNullOrEmpty(formData?.origin))
      addInvalidProperty("origin");

    if (stringIsNullOrEmpty(formData?.vehicleId))
      addInvalidProperty("vehicleId");

    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
  };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeDate, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm, onChangeForceFormData } = useFormTableCrud<TripViewModelResource>(
    CreateTrip,
    UpdateTrip,
    props.action.onCreate
  );

  const dtoEditResourceState = (state: RootState) => state.TripCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    if (editResource) {
      const copy = { ...editResource };
      setFormData(copy);
    }
  }, [editResource]);



  function GetVehicleValue(): any | undefined {
    let data = VehicleResource?.items?.filter(x => x.id === formData?.vehicleId);
    if (data != null && data?.length > 0) {
      return ({ value: data[0].id ?? "", label: data[0].model ?? "" })
    } else {

      let data = VehicleResource?.items;
      if (data != null && data?.length > 0) {
        SetVehicleSelect({ value: data[0].id }  );
    }
  }
  return undefined;
}
function SetVehicleSelect(e: any): void {
  var copy = { ...formData } as TripViewModelResource;
  let data = VehicleResource?.items?.filter(x => x.id === e.value);
  if (data != null) {
    copy.targa = data[0].licensePlate;
  }
  copy.vehicleId = e.value;
  copy.vehicle = e.label;
  setFormData(copy);
}
const closeModal = () => {
  setisVisibleModal(false);
}

const returnElementProductInTrip = (data: ProductInTripViewModel) => {
  var copy = { ...formData as TripViewModelResource };
  if (copy.productInTripViewModels == null) {
    copy.productInTripViewModels = [];
  }
  if (ProductInTripEdit) {
    var codeIndex = copy.productInTripViewModels.findIndex(x => x.tripId === data.tripId);
    if (codeIndex > -1)
      copy.productInTripViewModels.splice(codeIndex, 1);
  }
  copy.productInTripViewModels.push(data);

  setFormData(copy);
  setisVisibleModal(false);
}
const cancelProductInTrip = (data) => {
  setisVisibleModal(false);

}
const onOriginChanged = useCallback((newOrigin) => {
  formData && onChangeForceFormData("origin", newOrigin, formData);
}, [formData, onChangeForceFormData]);

const onDestinationChanged = useCallback((newDestination) => {
  formData && onChangeForceFormData("destination", newDestination, formData);
}, [formData, onChangeForceFormData]);

const addInTrip = (inTrip?: ProductInTripViewModel) => {
  if (inTrip !== undefined) {
    setInTripSelected(inTrip);
    setInTripEdit(true);
  } else {
    setInTripSelected(null);
    setInTripEdit(false);
  }
  setisVisibleModal(true);
}
const removeInTrip = (inTrip: ProductInTripViewModel) => {
  var copy = { ...formData };
  if (copy.productInTripViewModels == null) {
    copy.productInTripViewModels = [];
  }
  var codeIndex = copy.productInTripViewModels.findIndex(x => x.tripId === inTrip.tripId);
  if (codeIndex > -1)
    copy.productInTripViewModels.splice(codeIndex, 1);
  setFormData(copy as TripViewModelResource);
}
function GetAziendaValue(id: string): any | undefined {
  let data = AziendeResource?.items?.filter(x => x.id === id);
  if (data != null && data?.length > 0) {
    return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
  }
  return undefined;
}

function onChangeSelectLocal(arg0: string, e: any): void {
  let copy = { ...formData } as TripViewModelResource;
  let data = AziendeResource?.items?.filter(x => x.id === e.value);
  if (data != null && data?.length > 0) {
    let first = data[0];
    if (arg0 === "origine") {
      copy.aziendaOrigineId = e.value;
      copy.aziendaOrigine = first.name;
      copy.origin = first.location ?? "";
    } else {

      copy.aziendaDestinazioneId = e.value;
      copy.aziendaDestinazione = first.name;
      copy.destination = first.location ?? "";
    }
    setFormData(copy);
  }
}

return (
  <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
    <form id="form" className='w-100' onChange={() => setChanged(true)}>

      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>ProductInTrip</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isVisibleModal ?
            <ProductInTrip action={{ returnElement: returnElementProductInTrip, cancel: cancelProductInTrip }} data={inTripSelected} />
            : <></>}</Modal.Body>
      </Modal>

      <div className="row mx-0 col-12 px-0">
        <div className="col-12 col-md-6">
          <InputPlaceComponent onChange={onChange} value={formData?.origin} name={'origin'} title={'Origine'} type={'text'} validation={validation} validationMessage={'Origine obbligatorio'} />
          <div className="form-group">
            <SelectComponent
              value={GetAziendaValue(formData?.aziendaOrigineId ?? "")}
              options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" } })}
              onChange={(e) => onChangeSelectLocal("origine", e)}
              name={'aziendaOrigineId'}
              title={'Azienda Origine'}
              isMulti={false}
              modal={{ modalType: "Azienda" }}

            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <InputPlaceComponent onChange={onChange} value={formData?.destination} name={'destination'} title={'Destinazione'} type={'text'} validation={validation} validationMessage={'Destinazione obbligatorio'} />
          <div className="form-group">
            <SelectComponent
              value={GetAziendaValue(formData?.aziendaDestinazioneId ?? "")}
              options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" } })}
              onChange={(e) => onChangeSelectLocal("destinazione", e)}
              name={'aziendaOrigineId'}
              title={'Azienda Destinazione'}
              isMulti={false}
              modal={{ modalType: "Azienda" }}

            />
          </div>
        </div>
        {formData &&
          <div className="col-12 ">
            <MappaConPercorso origin={formData?.origin ?? ""} destination={formData?.destination ?? ""}
              onDestinationChanged={onDestinationChanged}
              onOriginChanged={onOriginChanged}
            />
          </div>}

        <div className="col-12 col-md-6">
          <InputTextComponent onChange={onChange} value={formData?.status} name={'status'} title={'Status'} type={'text'} />
        </div>
        <div className="col-12 col-md-6">
          <DatePickerComponent onChange={onChangeDate} value={formData?.startTime} name={'startTime'} title={'Partenza'} />
        </div>
        <div className="col-12 col-md-6">
          <DatePickerComponent onChange={onChangeDate} value={formData?.endTime} name={'endTime'} title={'Arrivo'} />
        </div>

        <div className="col-12 col-md-6">
          <SelectComponent
            value={GetVehicleValue()}
            options={VehicleResource?.items?.map(x => { return { value: x.id ?? "", label: x.model ?? "" } })}
            onChange={(e) => SetVehicleSelect(e)}
            name={'vehicleId'}
            title={'Veicolo'}
            isMulti={false}
            validation={validation}
            validationMessage={'Veicolo obbligatorio'} />
        </div>
        <div className="col-12 col-md-6">
          <InputTextComponent onChange={onChange} value={formData?.nomeTrasportatore} name={'nomeTrasportatore'} title={'Trasportatore'} type={'text'} validation={validation} validationMessage={'Nome trasportatore obbligatorio'} />
        </div>
        <div className="col-12 col-md-6">
          <InputTextComponent onChange={onChange} value={formData?.targa} name={'targa'} title={'Targa'} type={'text'} />
        </div>
      </div>
      <hr />
      <div className=' mx-0 col-12 p-0 d-flex justify-content-center row w-100'>
        <h4>Prodotti in viaggio <button className='btn btnOutline' type='button' onClick={() => addInTrip()}>Add</button></h4>
        <div className='col-12 col-md-6 row'>
          {formData?.productInTripViewModels?.map((x, i) => (
            <ProductInTripComponent key={"variant" + i} model={x} action={{
              addInTrip, removeInTrip
            }} />

          ))}
        </div>
      </div>
      <hr />
      <div className="col-12 justify-content-end mt-4 d-flex footerModal">
        <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
          ANNULLA
        </button>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, (e) => { refresh(false); })} type="button">
          SALVA
        </button>
      </div>
    </form>


  </div>
);
}
export default TripModal





