
import { CustomGridRender, FilterValueDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { User_GRIDRENDER, User_LOCAL, UserListQueryResultDto, UserModel, UserModelQuery, User_FILTERS_LOCAL, UserModelResource } from '../Model/UserModels';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class UserListApi {
  UserListGetGrid = async (tableQuery: UserModelQuery): Promise<ResultDto<UserListQueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "UserManage/Get", options);
    var res = await response.json();
    return res;
  }
  UserListCreate = async (model: UserModel): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(model)
    };
    let url = basePath + "UserManage";
    let response = await fetch(url, options);
    return await response.json();
  }

  UserGetResource = async (id?: string): Promise<ResultDto<UserModelResource>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "UserManage/Resource";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }


  UserListEdit = async (model: UserModelResource): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
      body: JSON.stringify(model)
    };
    let url = basePath + "UserManage";
    let response = await fetch(url, options);
    return await response.json();
  }


  UserListDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "UserManage/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }


  UserListGetFilters = async (columName: string, columValue?: string, queryFilter?: UserModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "UserManage/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json()) as ResultDto<FilterValueDto[]>;
  }
}


export class UserListApiOffline {
  UserListGetGrid = async (tableQuery: UserModelQuery): Promise<ResultDto<UserListQueryResultDto>> => {
    let items: UserModel[] | null = await localforage.getItem(User_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(User_GRIDRENDER);
    //todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.UserCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.UserType?.forEach(s => {
    //   items = items?.filter(x => x.UserType === s) ?? []
    // });
    let res: ResultDto<UserListQueryResultDto> = { data: { gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length } }
    return res;
  }

  UserListDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "UserManage/File?id=" + id;
    let response = await fetch(url, options);
     return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  UserListGetFilters = async (columName: string, columValue?: string, queryFilter?: UserModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    let items: ResultDto<FilterValueDto[]> = { data: await localforage.getItem(User_FILTERS_LOCAL + columName) ?? [] };
    return items;
  }
}

