import { ApiCallWithErrorHandling, ApiCallWithErrorHandlingFile } from "../../../Business/Common/CommonBusiness";
import { FileResult, ReturnFile } from '../../../Model/CommonModel';
import { ProdottoApi } from "../../../Business/ProdottoBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from "../NotificationAction";
import { rootStore } from "../../Store/rootStore";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { ProdottoViewModelQuery } from "../../../Model/ProdottoModels";


export async function DownloadProdottoItem(id: number) {

    setLoader("ADD", "DownloadProdottoItem");
    let res: ReturnFile | undefined;
    let api = new ProdottoApi();

    res = await ApiCallWithErrorHandlingFile<Promise<ReturnFile>>(() =>
       api.ProdottoDownload(id)
    );

    if(res?.FileName === null){
      rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning  }));
      setLoader("REMOVE", "DownloadProdottoItem");
    }else{

      let fileNameBase = res?.FileName?.split(";")[1] ?? "";
      var index = fileNameBase?.indexOf('"') + 1;
      var lastIndex = fileNameBase?.indexOf('"', index);
      let fileName = fileNameBase?.substring(index, lastIndex);
      let result = res?.File.then((x) => {
          setLoader("REMOVE", "DownloadProdottoItem");
          return { file: x, fileName: fileName } as FileResult;
      });
      setLoader("REMOVE", "DownloadProdottoItem");
      return result;
    }
}

export async function GetProdottoReport(queryFilter?:  ProdottoViewModelQuery) {
	let res: ReturnFile | undefined;
	let api = new ProdottoApi();

	setLoader("ADD", "GetProdottoReport");

		res = await ApiCallWithErrorHandling<Promise<ReturnFile>>(() =>
			api.prodottoExportReport(
				queryFilter || {} 
			)
		);
	
	let fileNameBase = res?.FileName.split(";")[1] ?? "";
	var index = fileNameBase?.indexOf('"') + 1;
	var lastIndex = fileNameBase?.indexOf('"', index);
	let fileName = fileNameBase?.substring(index, lastIndex);
	let result = res?.File.then((x) => {
		setLoader("REMOVE", "GetProdottoReport");

		return { file: x, fileName: fileName } as FileResult;
	});
	setLoader("REMOVE", "GetProdottoReport");

	return result;
}
