import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { WarehouseViewModel, Warehouse_LOCAL, Warehouse_FILTERS_LOCAL } from "../../../Model/Warehouse"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreWarehouse = async (Warehousedata: WarehouseViewModel, fileName: string) => {
  let Warehouse: WarehouseViewModel[] = await localforage.getItem(Warehouse_LOCAL) ?? [];
  // Warehouse.fileName = fileName;
  // Warehouse.on = formatDateWithTime(new Date())?.toString() ?? "";
  Warehouse.push(Warehousedata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Warehouse.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Warehouse_LOCAL, arrayUniqueByKey);
}

export const StoreWarehouseFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Warehouse_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Warehouse_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


