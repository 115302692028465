import { type } from "os";

export interface IOverrideBehavior {
    operation: Function;
    overrideProperty?: string;
}
export interface  QueryResultDto {
  /**
   *
   * @type {number}
   * @memberof UserListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<UserList>}
   * @memberof UserListQueryResultDto
   */
  items?: any[];
  /**
   *
   * @type {CustomGridRender}
   * @memberof UserListQueryResultDto
   */
  gridRender?: CustomGridRender;
}

/**
 *
 * @export
 * @interface QueryObject
 */
export interface QueryObject {
    /**
     *
     * @type {string}
     * @memberof QueryObject
     */
    sortBy?: string;
    /**
     *
     * @type {boolean}
     * @memberof QueryObject
     */
    isSortAscending?: boolean;
    /**
     *
     * @type {number}
     * @memberof QueryObject
     */
    page?: number;
    /**
     *
     * @type {number}
     * @memberof QueryObject
     */
    pageSize?: number;
    /**
     *
     * @type {DateFilter}
     * @memberof QueryObject
     */
    lastModified?: DateFilter;
    /**
     *
     * @type {number}
     * @memberof QueryObject
     */
    principalId?: number;
    /**
     *
     * @type {boolean}
     * @memberof QueryObject
     */
    deleted?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof QueryObject
     */
    orphan?: boolean;
    /**
     *
     * @type {string}
     * @memberof QueryObject
     */
    lastModifiedBy?: Array<string>;
}

/**
 *
 * @export
 * @interface DateFilter
 */
export interface DateFilter {
    /**
     *
     * @type {Date}
     * @memberof DateFilter
     */
    startDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof DateFilter
     */
    endDate?: Date;
}

/**
 *
 * @export
 * @interface FilterValueDto
 */
export interface FilterValueDto {
    /**
     *
     * @type {string}
     * @memberof FilterValueDto
     */
    value: string;
    /**
     *
     * @type {string}
     * @memberof FilterValueDto
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof FilterValueDto
     */
    dto?: any;
}

// /**
//  *
//  * @export
//  * @interface ResultDto
//  */
// export interface ResultDto {
//     /**
//      *
//      * @type {boolean}
//      * @memberof ResultDto
//      */
//     warning?: boolean;
//     /**
//      *
//      * @type {string}
//      * @memberof ResultDto
//      */
//     info?: string;
//     /**
//      *
//      * @type {ModelObject}
//      * @memberof ResultDto
//      */
//     data?: any;
// }

export type ResultDto<T = undefined> = {
  /**
   *
   * @type {boolean}
   * @memberof ResultDto
   */
  warning?: boolean;
  /**
   *
   * @type {string}
   * @memberof ResultDto
   */
  info?: string;

} & (T extends undefined ? any  : {
  data: T;
})

export interface CustomGridRender {
    /**
     *
     * @type {string}
     * @memberof CustomGridRender
     */
    className: string;
    /**
     *
     * @type { Array<RenderDetail>}
     * @memberof RenderDetail
     */
    render: Array<RenderDetail>;
}

export interface FileResult {
    file: Blob;
    fileName: string;
}
export interface ReturnFile {
    File: Promise<Blob>;
    FileName: string;
}

/**
 *
 * @export
 * @interface RenderDetail
 */
export interface RenderDetail {
    /**
     *
     * @type {string}
     * @memberof RenderDetail
     */
    propertyName: string;
    /**
    *
    * @type {string}
    * @memberof RenderDetail
    */
    propertyText: string;
    /**
     *
     * @type {boolean}
     * @memberof RenderDetail
     */
    show: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RenderDetail
     */
    showFilter: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RenderDetail
     */
    ignore: boolean;
    /**
     *
     * @type {number}
     * @memberof RenderDetail
     */
    order: number;
    /**
     *
     * @type {GridFilterType}
     * @memberof RenderDetail
     */
    type: GridFilterType;
    /**
     *
     * @type {number}
     * @memberof RenderDetail
     */
    valueType: number;
    /**
    *
    * @type {string}
    * @memberof RenderDetail
    */
    icon?: string;
    /**
    *
    * @type {string}
    * @memberof RenderDetail
    */
    tab?: string;
    /**
    *
    * @type {string}
    * @memberof RenderDetail
    */
    colorHeader?: string;
}

/**
 *
 * @export
 * @interface SaveGrid
 */
export interface SaveGrid {
    /**
     *
     * @type {Array<RenderDetail>}
     * @memberof SaveGrid
     */
    render?: Array<RenderDetail>;
    /**
     *
     * @type {string}
     * @memberof SaveGrid
     */
    className?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum GridFilterType {
    CheckBoxFilter = 0 as any,
    CheckBoxFilterNumber = 1 as any,
    DateRangeFilter = 2 as any,
}

export interface DataModalConfirm {
    title: string;
    buttonText: string;
    buttonSecond?: string;
    onlyOneButton?: boolean;
    cancelText?: string;
    message: string;
    hideCancel?: boolean;
    item?: number | string;
    isOpen: boolean;
    actions: {
        confirm(): any;
        confirmSecond?(): any;
        cancel(): any;
    };
}

export const stateConfirm = {
    title: "",
    buttonText: "",
    message: "",
    isOpen: false,
    actions: {
        cancel: () => {
            return;
        },
        confirm: () => {
            return;
        },
    },
};



export const GRID_RENDER = "GRID_RENDER";
