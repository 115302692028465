import React, { useContext } from "react";
import { DarkTheme } from "../../App";
import '../../Css/Filters.css'
import '../../Css/Index.css'
import '../../Css/AziendaList.css';
import Icon from "./Icon";


interface Props {
    action?(): any
    icon: string
    height?:number
}

const ButtonActionIcon: React.FC<Props> = (props) => {

    return (
        <div className="row mx-0">
            <div className="pointer-event cursoricon" onClick={() => props.action && props.action()}>
                <Icon height={props.height} icon={props.icon} />
            </div>
        </div>
    )
}
export default ButtonActionIcon
