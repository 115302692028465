import React, { useState } from "react";
// import { useZxing } from "react-zxing";
import { useZxingCustom } from "../../Hook/Layout/useZxingCustom";
import { GetProdottoViewModel } from "../../Model/ProdottoModels";
import { VariantViewModel } from "../../Model/VariantViewModel";
import VariantComponent from "../Component/Variant/VariantComponent";

interface Props {
  productVariant: GetProdottoViewModel[],
  action: {
    save(variant: GetProdottoViewModel[]): void;
    closeModal: () => void;
  }
}


export const CodeBarReader: React.FC<Props> = (props) => {
  const [result, setResult] = useState("");
  const [variant, setVariant] = useState([] as Array<GetProdottoViewModel>)
  const { ref } = useZxingCustom({
    onResult(result) {
      setResult(result.getText());

      var data = props.productVariant.filter(x => x.code === result.getText());
      if (data != null && data?.length > 0) {
        var codeIndex = variant.findIndex(x => x.id === data[0].id);
        if (codeIndex === -1){
        let copy = [...variant];
        copy.push(data[0]);
        setVariant(copy);
        }



      }
    },
  });
  const removeVariant = (variantModel: VariantViewModel) => {
    var copy = [...variant];
    var codeIndex = copy.findIndex(x => x.id === variantModel.id);
    if (codeIndex > -1)
      copy.splice(codeIndex, 1);
    setVariant(copy);
  }

  return (
    <>
      <video ref={ref} width={"100%"} />
      <p>
        <span>Matching:</span>
        <span>{result}</span>
        {variant.map((x,i) =>
          <VariantComponent index={i} productName={x.name} action={{ removeVariant: () => removeVariant(x as VariantViewModel) }} model={x as VariantViewModel} />
        )}

      </p>

      <div className="col-12 justify-content-end mt-4 d-flex footerModal">
        <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal()} type="button">
          ANNULLA
        </button>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => props.action.save(variant)} type="button">
          ADD
        </button>
      </div>

    </>
  );
};


