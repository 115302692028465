import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {  ProductSize_LOCAL, ProductSizeViewModel, EDIT_ProductSize, ProductSizeEdit,   } from '../../../Model/ProductSizeModel';
import { ProductSizeApi } from "../../../Business/ProductSizeBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';
import { ResultDto } from '../../../Model/CommonModel';
import { GET_EDIT_ProductSize } from '../../../Model/ProductSizeModel';

export async function UpdateProductSize(data: ProductSizeViewModel) {
	setLoader("ADD", "EditProductSize");
	let api = new ProductSizeApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProductSizeEdit(data));
	let rtn = { dto: null, resultDto: result } as ProductSizeEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_ProductSize, payload: rtn });
	setLoader("REMOVE", "EditProductSize");
	return rtn;
}
export async function GetProductSizeCreateResource(id?: string) {
	setLoader("ADD", "GetProductSizeEditResource");
	let api = new ProductSizeApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<ProductSizeViewModel>>>(() => api.ProductSizeGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as ProductSizeEdit;
	rootStore.dispatch({ type: GET_EDIT_ProductSize, payload: rtn });
	setLoader("REMOVE", "GetProductSizeEditResource");
	return rtn.dto;
}

// export const ChangeStatusProductSizeViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusProductSizeViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new ProductSizeApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProductSizeItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_ProductSize_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProductSizeViewModelDetail });

//         let list = await localforage.getItem(ProductSize_LOCAL) as ProductSizeViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(ProductSize_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusProductSizeViewModel");
//     return
// }

// export const ChangeStatusProductSizeRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusProductSizeRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new ProductSizeApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProductSizeItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_ProductSize_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProductSizeViewModelDetail });

//         let list = await localforage.getItem(ProductSize_LOCAL) as ProductSizeViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(ProductSize_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusProductSizeRejected");
//     return
// }

// export const RejectProductSizeViewModel = async (id: number) => {
//     setLoader("ADD", "RejectProductSizeViewModel");
//     let api = new ProductSizeApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProductSizeItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_ProductSize_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProductSizeViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectProductSizeViewModel");
//     return
// }


