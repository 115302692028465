import React, { useContext, useState } from 'react';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { RootState } from '../../Redux/Store/rootStore';
import { useEffect } from 'react';
import { DarkTheme } from '../../App';
import { useSelector } from 'react-redux';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { ProductSizeGroupedViewModel, ProductSizeViewModel } from '../../Model/ProductSizeModel';
import { CreateProductSize } from '../../Redux/Action/ProductSize/ProductSizeCreateAction';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import { MultiValue } from "react-select";
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import { UpdateProductSize } from '../../Redux/Action/ProductSize/ProductSizeEditAction';
import InputNumberComponent from '../../Components/FormAndFilters/InputNumberComponent';
import ProductSizeGroupedForm from '../Component/ProductSizeGrouped/ProductSizeGroupedForm';
import { Modal } from 'react-bootstrap';
import ProductSizeGroupedComponent from '../Component/ProductSizeGrouped/ProductSizeGroupedComponent';

interface Props {
  action: {
    closeModal(changed: boolean): any | void
    onCreate?: (data: ProductSizeViewModel | any) => void

  }
  isVisibleModal: boolean
  edit: boolean
  id?: string
}

const ProductSizeModal: React.FC<Props> = (props) => {

  const [isVisibleModal, setisVisibleModal] = useState(false);
  const [variantEdit, setVariantEdit] = useState(false);
  const [codeBarSelected, setcodeBarSelected] = useState<ProductSizeGroupedViewModel | null>(null);

  const validazioneClient = (formData: ProductSizeViewModel | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    if (stringIsNullOrEmpty(formData?.name))
      addInvalidProperty("name");


    // if (stringIsNullOrEmpty(formData?.ProductSize))
    //   addInvalidProperty("ProductSizeName");

    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
  };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<ProductSizeViewModel>(
    CreateProductSize,
    UpdateProductSize,
    props.action.onCreate

  );

  const dtoEditResourceState = (state: RootState) => state.ProductSizeCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    setFormData(editResource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editResource])

  const returnElementVariant = (data: ProductSizeGroupedViewModel) => {
    var copy = { ...formData } as ProductSizeViewModel;
    if (copy.productSizeGroupedViewModels == null) {
      copy.productSizeGroupedViewModels = [];
    }
    if (variantEdit) {
      var codeIndex = copy.productSizeGroupedViewModels.findIndex(x => x.id === data.id);
      if (codeIndex > -1)
        copy.productSizeGroupedViewModels.splice(codeIndex, 1);
    }
    copy.productSizeGroupedViewModels.push(data);

    setFormData(copy);
    setisVisibleModal(false);
  }

  const cancelVariant = (data) => {
    setisVisibleModal(false);

  }
  const closeModal = () => {
    setisVisibleModal(false);
  }

  const addVariant = (variant?: ProductSizeGroupedViewModel) => {
    if (variant !== undefined) {
      setcodeBarSelected(variant);
      setVariantEdit(true);
    } else {
      setcodeBarSelected(null);
      setVariantEdit(false);
    }
    setisVisibleModal(true);
  }
  const removeVariant = (variant: ProductSizeGroupedViewModel) => {
    var copy = { ...formData } as ProductSizeViewModel;
    if (copy.productSizeGroupedViewModels == null) {
      copy.productSizeGroupedViewModels = [];
    }
    var codeIndex = copy.productSizeGroupedViewModels.findIndex(x => x.id === variant.id);
    if (codeIndex > -1)
      copy.productSizeGroupedViewModels.splice(codeIndex, 1);
    setFormData(copy);
  }



  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>Variante</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isVisibleModal ?
            <ProductSizeGroupedForm action={{ returnElement: returnElementVariant, cancel: cancelVariant }} data={codeBarSelected} />
            : <></>}</Modal.Body>
      </Modal>


      <form id="form" className='w-100' onChange={() => setChanged(true)}>
        <div className="row mx-0 col-12 px-0">
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.name} name={'name'} title={'Nome'} type={'text'} validation={validation} validationMessage={'Name obbligatorio'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.description} name={'description'} title={'Descrizione'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputNumberComponent onChange={onChange} value={formData?.colliDefault} name={'colliDefault'} title={'Colli di Default'} />
          </div>
          <h4>Varianti <button className='btn btnOutline' type='button' onClick={() => addVariant()}>Add</button></h4>

          {formData?.productSizeGroupedViewModels?.map((x, i) => (
            <ProductSizeGroupedComponent key={"variant" + i} model={x} action={{
              addVariant, removeVariant
            }} />

          ))}

        </div>
        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
          <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
            ANNULLA
          </button>
          <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, refresh)} type="button">
            SALVA
          </button>
        </div>
      </form>


    </div>
  );
}
export default ProductSizeModal





