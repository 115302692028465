 import { ADD_User_GRID, GET_User_FILTERS, GET_User_GRID, UserModelGrid } from '../../../Model/UserModels';

const initState: UserModelGrid = {
    gridResult: null,
    filter: null,
}

export const UserListGridReducer = (state = initState, action: { type: string, payload: UserModelGrid }) => {
  if(action.payload != null && action.payload ){
  const {gridResult,filter}  = action.payload;
  switch (action.type) {
        case GET_User_GRID:
        case ADD_User_GRID:
            {
                return { ...state,gridResult }
            }
        case GET_User_FILTERS:
             return { ...state, filter }
        default:
            return state;
    }
}
return state;}

