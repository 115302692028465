import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import Layout from './Components/Layout';
import './custom.css'
import Loader from './Components/Loader';
import ErrorNotification from './Components/ErrorNotification';
import ProfileContainer from './Screen/Containers/ProfileContainer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './Redux/Store/rootStore';
import Login from './Screen/Login';
import ListaUtentiContainer from './Screen/Containers/ListaUtentiContainer';
import RoleContainer from './Screen/Containers/RoleContainer';
import ProdottoContainer from './Screen/Containers/ProdottoContainer';
import AziendaContainer from './Screen/Containers/AziendaContainer';
import {CodeBarReader} from './Screen/Containers/CodeBarReader';
import { removeAccessToken } from './Redux/Action/AuthenticationAction';
import ProductSizeContainer from './Screen/Containers/ProductSizeContainer';
 import MovimentazioneContainer from './Screen/Containers/MovimentazioneContainer';
import Imagetest from './Components/CanvasComponent';
import CanvasComponent from './Components/CanvasComponent';
import WarehouseContainer from './Screen/Containers/WarehouseContainer';
import VehicleContainer from './Screen/Containers/VehicleContainer';
import TripContainer from './Screen/Containers/TripContainer';
import ProformaContainer from './Screen/Containers/ProformaContainer';


export const DarkTheme = React.createContext(false)

const App: React.FC = () => {


   const location = useLocation();



  const [isOnline, setIsOnline] = useState<boolean>(true)
  const [darkTheme, setDarkTheme] = useState<boolean>(false)

  const auth = useSelector((rootStore: RootState) => rootStore.AuthReducer);
    // const canvasRef = useRef<HTMLCanvasElement | null>(null);


  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true))
    window.addEventListener("offline", () => setIsOnline(false))

    let theme = localStorage.getItem("theme")
    let root = document.getElementById("root")

    if (theme === "dark") {
      setDarkTheme(true)
      if (root) {
        root.setAttribute("class", "darkTheme")
      }
    } else {
      if (root) {
        root.setAttribute("class", "")
      }
      setDarkTheme(false)
    }
  }, [])
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
    const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code')?? "";
  const userId = searchParams.get('email') ?? "";
  return (   
    <DarkTheme.Provider value={darkTheme}>
      <Router>
        <Loader></Loader>
        <ErrorNotification OnModal={false} />
        <Layout darkTheme={darkTheme}>
          {auth.token && auth.validityDate && new Date(auth.validityDate) > today ?
            <Switch>
              <Route exact path='/' >
                <ProdottoContainer isOnline={isOnline}></ProdottoContainer>
              </Route>
              <Route exact path='/Taglie' >
                <ProductSizeContainer isOnline={isOnline}></ProductSizeContainer>
              </Route>
              <Route exact path='/User' >
                <ListaUtentiContainer isOnline={isOnline}></ListaUtentiContainer>
              </Route>
              <Route exact path='/RoleList' >
                <RoleContainer isOnline={isOnline}></RoleContainer>
              </Route>
              <Route exact path='/Settings' >
                <ProfileContainer logout={function () {
                  removeAccessToken();
                }} setDarkTheme={setDarkTheme}  ></ProfileContainer>
              </Route>
              <Route exact path='/Prodotto' >
                <ProdottoContainer isOnline={isOnline}></ProdottoContainer>
              </Route>
              <Route exact path='/Warehouse' >
                <WarehouseContainer isOnline={isOnline}></WarehouseContainer>
              </Route>
              <Route exact path='/Azienda' >
                <AziendaContainer isOnline={isOnline}></AziendaContainer>
              </Route>
              <Route exact path='/Veicoli' >
                <VehicleContainer isOnline={isOnline}></VehicleContainer>
              </Route>
              <Route exact path='/Viaggi' >
                <TripContainer isOnline={isOnline}></TripContainer>
              </Route>
              <Route exact path='/Movimentazione' >
                <MovimentazioneContainer   isOnline={isOnline}/>
              </Route>             
              <Route exact path='/Proforma' >
                <ProformaContainer   isOnline={isOnline}/>
              </Route>             
            </Switch>
            :
            <Login code={code} userId={userId} />
          } </Layout>
      </Router>
    </DarkTheme.Provider>
  );
}

export default App
