import { type } from "os";
import React from "react"
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GridApi } from "../../../Business/GridApi";
import { ResultDto, SaveGrid } from "../../../Model/CommonModel";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from '../LoaderAction';
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'



export async function SaveCustomGridRender(data: SaveGrid) {
    setLoader("ADD", "SaveCustomGridRender");
    let api = new GridApi();

    let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => 
    api.Save(data)
    );
    let rtn = { ResultDtoCreate: result};
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "SaveCustomGridRender");
    return rtn;
}
export async function DeleteCustomGridRender(data: string) {
    setLoader("ADD", "DeleteCustomGridRender");
     let api = new GridApi();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Delete(data));
    let rtn = { ResultDtoCreate: result};
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    setLoader("REMOVE", "DeleteCustomGridRender");
    return rtn;
}