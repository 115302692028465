import { EDIT_ProductSize, GET_ProductSize_DETAIL, ProductSizeModelDetail } from '../../../Model/ProductSizeModel';

const initState: ProductSizeModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const ProductSizeDetailReducer = (state = initState, action: { type: string, payload: ProductSizeModelDetail }) => {
    switch (action.type) {
        case GET_ProductSize_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_ProductSize:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
