import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import { ProductSizeGroupedViewModel } from '../../../Model/ProductSizeModel'

interface Props {
  action?: {
    addVariant?(data: ProductSizeGroupedViewModel): any | void,
    removeVariant?(data: ProductSizeGroupedViewModel): any | void,  
  } 
  model: ProductSizeGroupedViewModel,
}
const ProductSizeGroupedComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><MaterialIcon icon="star" height={30} /><label>{props.model.name}</label></div>
          <div className='float-right mt-3 mr-1 row'>
             {props.action && props.action.addVariant && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addVariant && props.action.addVariant(props.model)} /></div>}
            {props.action && props.action.removeVariant && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeVariant && props.action.removeVariant(props.model)} /></div>}                 
            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
              
            
            <div className='col-12 col-md-6'> <label><MaterialIcon icon="description" />{props.model.description} colli di default :{props.model.colliDefault}  </label></div>
           </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSizeGroupedComponent