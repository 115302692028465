import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { VehicleApi } from "../../../Business/VehicleBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Vehicle, VehicleViewModel, EDIT_Vehicle, VehicleCreate,  GET_CREATE_Vehicle, PositionAndDates } from "../../../Model/VehicleModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetVehicleCreateResource(id?: string) {
	setLoader("ADD", "GetVehicleCreateResource");
	let api = new VehicleApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<VehicleViewModel>>>(() => api.VehicleGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as VehicleCreate;
	rootStore.dispatch({ type: GET_CREATE_Vehicle, payload: rtn });
	setLoader("REMOVE", "GetVehicleCreateResource");
	return rtn.dto;
}


export async function GetVehicleGpsPosition(id?: string) {
	setLoader("ADD", "GetVehicleGpsPosition");
	let api = new VehicleApi();

	let data = await ApiCallWithErrorHandling<Promise<ResultDto<PositionAndDates[]>>>(() => api.VehicleGetPosition(id));
 	setLoader("REMOVE", "GetVehicleGpsPosition");
	return data.data;
}


export async function CreateVehicle(data: VehicleViewModel) {
	setLoader("ADD", "CreateVehicle");
	let api = new VehicleApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.VehicleCreate(data));
	let rtn = { dto: null, resultDto: result } as VehicleCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Vehicle, payload: rtn });
	setLoader("REMOVE", "CreateVehicle");
	return rtn;
}
