import { CustomGridRender, FilterValueDto, ResultDto } from "./CommonModel";
import { RoleModel } from "./RoleModels";
  interface UserModelBase {
  id?: string;
  email?: string;
  userName?: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
}
/**
 *
 * @export
 * @interface UserModel
 */
 export interface UserModel extends UserModelBase {
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserModel
   */
  phone?: string;
  /**
   *
   * @type {Array<RoleModel>}
   * @memberof UserModel
   */
  role?: Array<RoleModel>;
}

export interface UserModelResource extends UserModelBase {
  roleResource?: string[] | undefined;
  role?: string[] | undefined;
  password:string;
}


/**
 *
 * @export
 * @interface UserModelQuery
 */
 export interface UserModelQuery {
  /**
   *
   * @type {string}
   * @memberof UserModelQuery
   */
  lang?: string;
  /**
   *
   * @type {string}
   * @memberof UserModelQuery
   */
  sortBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserModelQuery
   */
  isSortAscending?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserModelQuery
   */
  page?: number;
  /**
   *
   * @type {number}
   * @memberof UserModelQuery
   */
  pageSize?: number;
  /**
   *
   * @type {string}
   * @memberof UserModelQuery
   */
  id?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  email?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  userName?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  firstName?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  lastName?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  phone?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  role?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  claimsType?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof UserModelQuery
   */
  claimsValue?: Array<string>;
}
export interface UserModelGrid {
  gridResult: UserListQueryResultDto | null
  filter: FilterValueDto[] | null
}

export interface UserModelGrid {
  gridResult: UserListQueryResultDto | null
  filter: FilterValueDto[] | null
}

export interface UserModelDetail {
  dtoEdit: UserModel | null,
  resultDto: ResultDto<null> | null,
}

export interface UserCreate {
  dto: UserModelResource | null,
  resultDto: ResultDto<null> | null,
}
export interface UserModelDownloaded {
  dtoDownload: number[],
}

export interface UserListQueryResultDto {
  /**
   *
   * @type {number}
   * @memberof UserListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<UserList>}
   * @memberof UserListQueryResultDto
   */
  items?: Array<UserModel>;
  /**
   *
   * @type {CustomGridRender}
   * @memberof UserListQueryResultDto
   */
  gridRender?: CustomGridRender;
}

export const GET_User_GRID = "GET_User_GRID"
export const ADD_User_GRID = "ADD_User_GRID"
export const GET_User_FILTERS = "GET_User_FILTERS"
export const GET_User_DETAIL = "GET_User_DETAIL"
export const EDIT_User = "EDIT_User"
export const CREATE_User = "CREATE_User"
export const GET_CREATE_User = "GET_CREATE_User"


//#region offline definition

export const User_LOCAL = "User_LOCAL"
export const User_FILTERS_LOCAL = "User_FILTERS_LOCAL"

export const User_GRIDRENDER = "User_GRIDRENDER"

//#endregion
