import React, { useContext, useState } from "react";
import { DarkTheme } from "../../App";
import '../../Css/NavMenu.css';
import { Link } from 'react-router-dom';
import { delayClose } from "../../Hook/Common/Utility";
import NavLinkList from "./NavLinkList";

interface Props {
  isMobile: boolean;
}

const NavHeader: React.FC<Props> = (props) => {
  const darkTheme = useContext(DarkTheme);
  const [open, SetOpen] = useState<boolean>(false);

  return (
    <div className={`col-12 px-0 row mx-0 headerNavbar justify-content-between   mobile  ${darkTheme ? "dark" : ""}`}>
   
        <div className="d-flex flex-row align-items-center pl-4">
            <button className="btn btnIcon burger" type="button" onClick={() => SetOpen(!open)}>
            <img alt="burger" src={require('../../svg/burger.png')}></img>
          </button>
          <div className={`navHeader-list ${open ? "absolute pr-5" : "d-none"}`} onMouseLeave={() => delayClose(() => SetOpen(false))}>
            <NavLinkList />
          </div>
    
        </div>
        <div className="navbarHeader-brand ms-auto"> {/* Aggiunto ms-auto per spingere il logo a destra */}
          <img src={require('../../img/logo/icon-512-512.png')} alt="logo"></img>
        </div>
      


      {/* Commentato per rimuovere gli elementi non necessari alla dimostrazione */}
      {/* <div className="d-flex align-items-center px-4">
        <ButtonNotification isMobile={props.isMobile} />
        <button type="button" className="btn btnIcon">
          <img alt="user" className={`${props.isMobile ? "brightness" : ""}`} src={require('../../svg/User.png')}></img>
        </button>
      </div> */}
    </div>
  );
}

export default NavHeader;
