import { EDIT_Movimentazione, GET_Movimentazione_DETAIL, MovimentazioneModelDetail } from '../../../Model/MovimentazioneModel';

const initState: MovimentazioneModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const MovimentazioneDetailReducer = (state = initState, action: { type: string, payload: MovimentazioneModelDetail }) => {
    switch (action.type) {
        case GET_Movimentazione_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Movimentazione:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
