import React, { useEffect, useState } from "react";
import { CommonValidation } from "../../../Business/Common/CommonBusiness";
import { ErrorType } from "../../../Components/ErrorNotification";
import InputNumberComponent from "../../../Components/FormAndFilters/InputNumberComponent";
import InputTextComponent from "../../../Components/FormAndFilters/InputTextComponent";
import { useFormTableCrud } from "../../../Hook/Grid/useFormTableCrud";
import { ProductSizeGroupedViewModel } from "../../../Model/ProductSizeModel";


interface Props {
  action: {
    returnElement(data: ProductSizeGroupedViewModel),
    cancel(canged: boolean): void
  }
  data: ProductSizeGroupedViewModel | null,
}

const ProductSizeGroupedForm: React.FC<Props> = (props) => {
 
  const [notifications, setNotifications] = useState<ErrorType[]>([])
  const { formData, setFormData, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<ProductSizeGroupedViewModel>(

  );


  const validazioneClient = (formData: ProductSizeGroupedViewModel | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };
    // if (formData?.buyValue)
    //   addInvalidProperty("buyValue");

    // if (formData?.sellValue)
    //   addInvalidProperty("sellValue");

    // if (formData?.aziendaId)
    //   addInvalidProperty("aziendaId");

    setValidation(copyValidation);
    return copyValidation;

  }
  const returnElement = () => {
    var messages: ErrorType[] = [...notifications]
 
    var valid = true;
    // if (formData?.buyValue === undefined) {
    //   valid = false;
    //   messages.push({ message: `prezzo acquisto mancante`, notifyType: NotifyType.error });

    // }

    // if (formData?.sellValue === undefined)
    // {
    //   valid = false;
    //   messages.push({ message: `prezzo vendita mancante`, notifyType: NotifyType.error });

    // }
    // if (formData?.aziendaId === undefined){
    //   valid = false;
    //   messages.push({ message: `Azienda mancante`, notifyType: NotifyType.error });

    // }
    // if (formData?.sizeId === undefined){
    //   valid = false;
    //   messages.push({ message: `Formato mancante`, notifyType: NotifyType.error });

    // }

    if (valid) {
      formData && props.action.returnElement(formData);
    }
    setNotifications(messages);
  }


  useEffect(() => {
    if (props.data != null) {
      setFormData(props.data);
    } else {
      setFormData({} as ProductSizeGroupedViewModel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

   
  return (
    <form id="form" className='w-100' onChange={() => setChanged(true)}>
     <div className="col-12 col-md-6">
        <InputTextComponent onChange={onChange} value={formData?.name} name={'name'} title={'Nome'}  type={'text'} validation={validation} validationMessage={'Name obbligatorio'} />
      </div>
      <div className="col-12 col-md-6">
        <InputTextComponent onChange={onChange} value={formData?.description} name={'description'} title={'Descrizione'}  type={'text'}   />
      </div>
      <div className="col-12 col-md-6">
        <InputNumberComponent onChange={onChange} value={formData?.colliDefault} name={'colliDefault'} title={'Colli di Default'}  />
      </div>
     
      <hr />
      <div className="col-12 justify-content-end mt-4 d-flex footerModal">
        <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.cancel && props.action.cancel(changed)} type="button">
          ANNULLA
        </button>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => returnElement()} type="button">
        Aggiungi
        </button>
      </div>
    </form>
  );
}

export default ProductSizeGroupedForm;