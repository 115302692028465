import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {  Prodotto_LOCAL, ProdottoViewModel, EDIT_Prodotto, ProdottoEdit, ProdottoViewModelResource } from '../../../Model/ProdottoModels';
import { ProdottoApi } from "../../../Business/ProdottoBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';
import { ResultDto } from '../../../Model/CommonModel';
import { GET_EDIT_Prodotto } from '../../../Model/ProdottoModels';

export async function UpdateProdotto(data: ProdottoViewModelResource) {
	setLoader("ADD", "EditProdotto");
	let api = new ProdottoApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProdottoEdit(data));
	let rtn = { dto: null, resultDto: result } as ProdottoEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Prodotto, payload: rtn });
	setLoader("REMOVE", "EditProdotto");
	return rtn;
}
export async function GetProdottoCreateResource(id?: string) {
	setLoader("ADD", "GetProdottoEditResource");
	let api = new ProdottoApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<ProdottoViewModelResource>>>(() => api.ProdottoGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as ProdottoEdit;
	rootStore.dispatch({ type: GET_EDIT_Prodotto, payload: rtn });
	setLoader("REMOVE", "GetProdottoEditResource");
	return rtn.dto;
}

// export const ChangeStatusProdottoViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusProdottoViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new ProdottoApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProdottoItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Prodotto_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProdottoViewModelDetail });

//         let list = await localforage.getItem(Prodotto_LOCAL) as ProdottoViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Prodotto_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusProdottoViewModel");
//     return
// }

// export const ChangeStatusProdottoRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusProdottoRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new ProdottoApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProdottoItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Prodotto_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProdottoViewModelDetail });

//         let list = await localforage.getItem(Prodotto_LOCAL) as ProdottoViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Prodotto_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusProdottoRejected");
//     return
// }

// export const RejectProdottoViewModel = async (id: number) => {
//     setLoader("ADD", "RejectProdottoViewModel");
//     let api = new ProdottoApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProdottoItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Prodotto_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProdottoViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectProdottoViewModel");
//     return
// }


