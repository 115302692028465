import { EDIT_Role, GET_Role_DETAIL, RoleModelDetail } from '../../../Model/RoleModels';

const initState: RoleModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const RoleDetailReducer = (state = initState, action: { type: string, payload: RoleModelDetail }) => {
    switch (action.type) {
        case GET_Role_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Role:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
