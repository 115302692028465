import { SetStateAction } from "react"
import { useState } from "react"
import { FilterValueDto, QueryObject, RenderDetail } from "../../Model/CommonModel"
import { useEffect } from 'react';
import setLoader from "../../Redux/Action/LoaderAction";


export interface FilterResource {
    propertyName: string
    filterList: FilterValueDto[]
}

export function useFilterCustom<T extends QueryObject>(applyFilters: (obj: SetStateAction<QueryObject>, forceClassic?: boolean) => any, initialState: QueryObject, functionForGetFilter: Function, query: QueryObject, filtersPropertyRender: RenderDetail[]) {
    const [queryToFilter, setQueryToFilter] = useState<QueryObject>(query)
    const [filtersResource, setFiltersResource] = useState<FilterResource[]>([])

    //UPDATE
    useEffect(() => {
        setQueryToFilter(query)
    }, [query])

    //GET
    const getAllFilters = async () => {
        let copy = [...filtersResource] as FilterResource[]
        filtersPropertyRender?.filter(x => x.showFilter === true && x.type !== 3 && x.type !== 7)?.map(x => {
            setLoader("ADD", functionForGetFilter.name);
            functionForGetFilter(x.propertyName, "", initialState).then(result => {
                 let index = copy?.findIndex(f => f.propertyName === x.propertyName)
                if (index !== -1 && index !== undefined) {
                    copy[index] = { propertyName: x.propertyName, filterList: result.data as FilterValueDto[] }
                } else {
                    copy.push({ propertyName: x.propertyName, filterList: result.data as FilterValueDto[] })
                }
                setLoader("REMOVE", functionForGetFilter.name);
            })
        })
        setFiltersResource(copy)
    }

    //SUBMIT
    const prepareSubmit = (callBackAfterApply?: Function) => {
        let copy = { ...queryToFilter } as QueryObject
        copy.page = 1;
        applyFilters(copy, true)
        if (callBackAfterApply != undefined) {
            callBackAfterApply(true)
        }
    }

    const resetFilters = async (init: QueryObject, propertyToNotOverride?: string) => {
        if (propertyToNotOverride != undefined) {
            let toNotOverride = query[propertyToNotOverride];
            if (toNotOverride != undefined) {
                init[propertyToNotOverride] = toNotOverride;
            }
        }
        await getAllFilters().then(x => {
            applyFilters(init, true)
        })
    }

    //SELECT FILTER
    const selectValueFromSelect = (property: string, obj: any) => {
        let copy = { ...queryToFilter }
        if (copy[property] != undefined) {
            copy[property] = [obj['key']]

        } else {
            let arr = [] as (string | number)[]
            arr.push(obj['key'])
            copy[property] = arr
        }
        setQueryToFilter(copy)
    }

    //BOOLEAN DOUBLE RADIO
    const changeSingleBooleanValue = (property: string, value: boolean) => {
        let copy = { ...queryToFilter }
        copy[property] = value
        setQueryToFilter(copy)
    }    
    
    const selectFilterAction = { onChange: selectValueFromSelect }
    const booleanRadioFilterAction = { onChange: changeSingleBooleanValue }

    return { getAllFilters, submit: prepareSubmit, reset: resetFilters, filtersResource, selectFilterAction, booleanRadioFilterAction ,filtersSelected: queryToFilter };
}