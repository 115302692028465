
import React, { useEffect, useState } from 'react'
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import { ModalSelector } from './ModalSelector';
import { Modal } from 'react-bootstrap';


interface Props {

  value: OptionsOrGroups<{
    value: string;
    label: string;
  } | GroupBase<{
    value: string;
    label: string;
  }>, GroupBase<{
    value: string;
    label: string;
  } | GroupBase<{
    value: string;
    label: string;
  }>>> | undefined,
  options: OptionsOrGroups<{
    value: string;
    label: string;
  } | GroupBase<{
    value: string;
    label: string;
  }>, GroupBase<{
    value: string;
    label: string;
  } | GroupBase<{
    value: string;
    label: string;
  }>>> | undefined,
  onChange: (e: any) => void,
  name: string,
  title: string,
  validation?: any,
  validationMessage?: string,
  isMulti: boolean,
  modal?: {
    modalType: string
  }
  id?:string
}

const SelectComponent: React.FC<Props> = (props) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState<string>("")
  const [options, setOptions] = useState<any[]>(props.options as any[])
  const [newForm, setNewForm] = useState(false);
  console.log(edit)
  const Edit = (value: any) => {
    let valueString = value.value;
    setEdit(true)
    if(props.id){
      setId(props.id);
    }else{
      setId(valueString);
    }
    
    setIsVisibleModal(true);
  }
  const Add = () => {
    setEdit(false)
    setNewForm(true)
    setId("");
    setIsVisibleModal(true);
  }
  const closeModal = () => {
    setIsVisibleModal(false);
  }
  useEffect(() => {
    setOptions(props.options as any[]);
  }, [props.options]);

  return (
    <div className="form-group col-12 pl-0">
      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>{edit ? "Modifica " + props.modal?.modalType : "Aggiungi " + props.modal?.modalType}</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ModalSelector
            action={{
              closeModal: closeModal,
              onCreate: (e) => {
                let copy = [...options];  // crea una copia dell'attuale stato delle opzioni
                copy.push({ label: e.name, value: e.id });  // aggiungi la nuova opzione
                setOptions(copy);  // aggiorna lo stato delle opzioni con la copia modificata
              },
            }}
            new={newForm} id={id} edit={edit} isVisibleModal={isVisibleModal} type={props.modal?.modalType ?? ""} />
        </Modal.Body>
      </Modal>
      <label className="labelForm voda-bold text-uppercase w-100">
        {props.title ?? props.name ?? ""}{props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? "*" : ""}
        <div className="row align-items-center">
          <div className="col">
            <Select
              className="w-100"
              options={options}
              value={props.value}
              onChange={(e) => props.onChange(e)}
              isMulti={props.isMulti}
            />
          </div>
          {props.modal && (
            <div className="col-auto">
              <button type="button" className="btn btn-link" onClick={() => Add()}>
                <img className="btnEdit" src={require("../../img/add.png")} alt="add" />
              </button>
              {props.value && (
                <button type="button" className="btn btn-link" onClick={() => Edit(props.value)}>
                  <img className="btnEdit" src={require("../../img/edit.png")} alt="edit" />
                </button>
              )}
            </div>
          )}
        </div>
        {props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? (
          <label className="validation">{props.validationMessage}</label>
        ) : null} </label>
    </div>

  )
}
export default SelectComponent
