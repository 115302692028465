import LoginRequest, { AuthStateModel } from "../Model/AuthModel";
import { ResultDto } from "../Model/CommonModel";
import setLoader from "../Redux/Action/LoaderAction";
import { rootStore } from "../Redux/Store/rootStore";
import { basePath, GetHeader } from "./Common/configuration";

export class AuthAPi {

  Login = async (tableQuery: LoginRequest): Promise<AuthStateModel> => {
    setLoader("ADD", "Login")
    let data: AuthStateModel = { } as AuthStateModel;
    try {
      const options = {
        method: "POST",
        headers: await GetHeader(),
        body: JSON.stringify(tableQuery),
      };
      let response = await fetch(basePath + "Authenticate/Login", options);
      let auth = await response.json() as ResultDto<AuthStateModel>;

      await localStorage.setItem('token', auth.data!.token);
      // rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: auth.data.token });
      rootStore.dispatch({ type: "AUTH_INSTANCE", payload: auth.data });


      data = auth.data!;
    } catch (error) {

    }
    setLoader("REMOVE", "Login")
    return data;
  }
}
