import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import Icon from '../../../Components/Various/Icon'
import { AziendaViewModel } from '../../../Model/Azienda'

interface Props {
  action?: {
    addAzienda?(data: AziendaViewModel): any | void,
    removeAzienda?(data: AziendaViewModel): any | void,
    chartBar?(data: AziendaViewModel): any | void,
  }
  model: AziendaViewModel,
}
const AziendaComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><Icon icon={require("../../../img/store.png")} height={30} /><label>{props.model.name}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar && <div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)} /></div>}
            {props.action && props.action.addAzienda && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addAzienda && props.action.addAzienda(props.model)} /></div>}
            {props.action && props.action.removeAzienda && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeAzienda && props.action.removeAzienda(props.model)} /></div>}
            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
          <div className='col-12 col-md-6'>{props.model.tipo ? <label ><MaterialIcon icon="grade" /> {props.model.tipo}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.description ? <label ><MaterialIcon icon="description" /> {props.model.description}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.email ? <a href={"mailto:" + props.model.email}><MaterialIcon icon="mail" /> {props.model.email}</a> : null} </div>
            <div className='col-12 col-md-6'>{props.model.piva ? <label ><MaterialIcon icon="fingerprint" /> partita iva: {props.model.piva}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.phone ? <a href={"tel:" + props.model.phone} ><MaterialIcon icon="call" /> {props.model.phone}   </a> : null} </div>
            <div className='col-12 col-md-6'>{props.model.website ? <a href={props.model.website}><MaterialIcon icon="language" /> {props.model.website}</a> : null} </div>
            <div className='col-12 col-md-6'>{props.model.seller ? <label ><MaterialIcon icon="sell" /> {props.model.seller ? "si" : "no"}</label> : null} </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AziendaComponent