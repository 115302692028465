import React, { useState } from 'react'
import MaterialIcon from 'react-google-material-icons'
import { formatPriceWithLocale } from '../../../Business/Common/CommonBusiness'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import { formatTime } from '../../../Hook/Common/TimeAndDate'
import { MovimentazioneProductVariantViewModel, MovimentazioneViewModel } from '../../../Model/MovimentazioneModel'

interface Props {
  action?: {
    ShowVariant(item: MovimentazioneProductVariantViewModel[]),
    CreateTrip(item: MovimentazioneViewModel),
    CreateFattura(item: MovimentazioneViewModel),
    Paga(item: string),
    removeMovimentazione?(data: MovimentazioneViewModel): any | void,
    edit?(data: MovimentazioneViewModel): any | void,

  }
  model: MovimentazioneViewModel,
}
const MovimentazioneComponent: React.FC<Props> = (props) => {

  const [isProductsVisible, setIsProductsVisible] = useState(false);

  // Funzione per invertire la visibilità
  const toggleProductsVisibility = () => setIsProductsVisible(!isProductsVisible);


  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><MaterialIcon icon="forklift" height={15} /><label>{props.model.numeroFattura}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            <div className='col '>
              <ButtonActionIconGoogleIcon action={() => props.action?.ShowVariant(props.model.movimentazioneProductVariant)} icon="list" />
            </div>
            <div className='col '>
              <ButtonActionIconGoogleIcon action={() => props.action?.CreateTrip(props.model)} icon="add_road" />
            </div>

            <div className='col '>
              <ButtonActionIconGoogleIcon action={() => props.action?.CreateFattura(props.model)} icon="receipt_long" />
            </div>

            <div className='col '>
              <ButtonActionIconGoogleIcon action={() => props.action?.Paga(props.model.id)} icon="payments" />
            </div>

            {props.action && props.action.removeMovimentazione && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeMovimentazione && props.action.removeMovimentazione(props.model)} /></div>}
            {props.action && props.action.edit && <div className='col '>  <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.edit && props.action.edit(props.model)} /></div>}



            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-md-6'>{props.model.movimantazioneDate ? <label ><MaterialIcon icon="calendar_month" />Data: {formatTime(props.model.movimantazioneDate)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.azienda ? <label ><MaterialIcon icon="store" />Azienda: {props.model.azienda}  </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.aziendaSeller ? <label ><MaterialIcon icon="store" />Azienda Interna: {props.model.aziendaSeller} </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.pagato !== undefined ? <label ><MaterialIcon icon="payments" />Pagato : {props.model.pagato === true ? "si" : "no"} </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.total ? <label ><MaterialIcon icon="money" />Total : {formatPriceWithLocale(props.model.total)} </label> : null} </div>
            <div className='col-12 col-md-6'> <label ><MaterialIcon icon="description" />Operazione : {props.model.operation === 0 ? "In" : "Out"} </label>  </div>
            <div className='row ml-2'>
            <div className="col-12 btn btn-link" onClick={toggleProductsVisibility}>
                   Prodotti: {isProductsVisible ? 'Nascondi' : 'Mostra'}
             
            </div>
              {isProductsVisible && props.model.movimentazioneProductVariant?.map((s: MovimentazioneProductVariantViewModel, i: number) =>
                <div className='col-12' key={s.id ?? "sss" + i}>
                  <p><b>{s.productVariantName}</b></p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MovimentazioneComponent