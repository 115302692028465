import { ApiCallWithErrorHandling, ApiCallWithErrorHandlingFile } from "../../../Business/Common/CommonBusiness";
import { FileResult, ReturnFile } from '../../../Model/CommonModel';
import { ProductSizeApi } from "../../../Business/ProductSizeBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from "../NotificationAction";
import { rootStore } from "../../Store/rootStore";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { ProductSizeViewModelQuery } from "../../../Model/ProductSizeModel";


export async function DownloadProductSizeItem(id: number) {

    setLoader("ADD", "DownloadProductSizeItem");
    let res: ReturnFile | undefined;
    let api = new ProductSizeApi();

    res = await ApiCallWithErrorHandlingFile<Promise<ReturnFile>>(() =>
       api.ProductSizeDownload(id)
    );

    if(res?.FileName === null){
      rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning  }));
      setLoader("REMOVE", "DownloadProductSizeItem");
    }else{

      let fileNameBase = res?.FileName?.split(";")[1] ?? "";
      var index = fileNameBase?.indexOf('"') + 1;
      var lastIndex = fileNameBase?.indexOf('"', index);
      let fileName = fileNameBase?.substring(index, lastIndex);
      let result = res?.File.then((x) => {
          setLoader("REMOVE", "DownloadProductSizeItem");
          return { file: x, fileName: fileName } as FileResult;
      });
      setLoader("REMOVE", "DownloadProductSizeItem");
      return result;
    }
}

export async function GetProductSizeReport(queryFilter?:  ProductSizeViewModelQuery) {
	let res: ReturnFile | undefined;
	let api = new ProductSizeApi();

	setLoader("ADD", "GetProductSizeReport");

		res = await ApiCallWithErrorHandling<Promise<ReturnFile>>(() =>
			api.productsizeExportReport(
				queryFilter || {} 
			)
		);
	
	let fileNameBase = res?.FileName.split(";")[1] ?? "";
	var index = fileNameBase?.indexOf('"') + 1;
	var lastIndex = fileNameBase?.indexOf('"', index);
	let fileName = fileNameBase?.substring(index, lastIndex);
	let result = res?.File.then((x) => {
		setLoader("REMOVE", "GetProductSizeReport");

		return { file: x, fileName: fileName } as FileResult;
	});
	setLoader("REMOVE", "GetProductSizeReport");

	return result;
}
