import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";

import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { rootStore } from './Redux/Store/rootStore';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const localWindow = window as any


ReactDOM.render(
  <Provider store={rootStore}>
    <Router basename={baseUrl ?? ""}>
      <App />
    </Router>
  </Provider>,
  rootElement);
//registerServiceWorker(localWindow.ENV.REACT_APP_SW_FILE);
unregister()

 //reportWebVitals();
