import {  CREATE_Trip, TripCreate,EDIT_Trip, GET_EDIT_Trip } from "../../../Model/TripModels"

const initState: TripCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const TripEditReducer = (state = initState, action: { type: string, payload: TripCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case EDIT_Trip:
            {

                return { ...state, resultDto }
            }
        case GET_EDIT_Trip:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
