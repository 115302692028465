import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CommonValidation } from "../../Business/Common/CommonBusiness";
import { ErrorType } from '../../Components/ErrorNotification';
import InputNumberComponent from "../../Components/FormAndFilters/InputNumberComponent";
import InputTextComponent from "../../Components/FormAndFilters/InputTextComponent";
import SelectComponent from "../../Components/FormAndFilters/SelectComponent";
import Notification from '../../Components/Notification';
import { useFormTableCrud } from "../../Hook/Grid/useFormTableCrud";
import { ProductInWarehouseViewModel } from "../../Model/ProductInWarehouse";
import { WarehouseViewModelQuery } from "../../Model/Warehouse";
import { GetWarehouseViewModel } from "../../Redux/Action/Warehouse/WarehouseGridAction";
import { NotifyType } from '../../Redux/Reducer/NotificationReducer';
import { RootState } from "../../Redux/Store/rootStore";
interface Props {
  action: {
    returnElement(data: ProductInWarehouseViewModel),
    cancel(canged: boolean): void
  }
  data: ProductInWarehouseViewModel | null,
}

const BarCode: React.FC<Props> = (props) => {
  const { formData, setFormData, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<ProductInWarehouseViewModel>(

  );
  const Warehouse = (state: RootState) => state.WarehouseGridReducer.gridResult;
  let WarehouseResource = useSelector(Warehouse);
  const [notifications, setNotifications] = useState<ErrorType[]>([])





  const validazioneClient = (formData: ProductInWarehouseViewModel | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    // if (stringIsNullOrEmpty(formData?.Prodotto))
    //   addInvalidProperty("Prodotto");


    // if (stringIsNullOrEmpty(formData?.Prodotto))
    //   addInvalidProperty("ProdottoName");

    setValidation(copyValidation);
    return copyValidation;

  }
  const returnElement = () => {

    var messages: ErrorType[] = [...notifications]
 
    var valid = true;
    if (formData?.warehouseId === undefined) {
      valid = false;
      messages.push({ message: `Magazzino mancante`, notifyType: NotifyType.error });

    }

    if (formData?.position === undefined)
    {
      valid = false;
      messages.push({ message: `posizione mancante`, notifyType: NotifyType.error });

    }
  
 

    if (valid && formData) {
      formData.quantity =formData?.quantity ?? 0;  
      props.action.returnElement(formData);
    }
    setNotifications(messages);
   }


  useEffect(() => {
    if (props.data != null) {
      setFormData(props.data);
    } else {
      setFormData({} as ProductInWarehouseViewModel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])


  useEffect(() => {
    GetWarehouseViewModel({} as WarehouseViewModelQuery);
 
  }, [])




  function GetWarehouseValue(): any | undefined {
    let data = WarehouseResource?.items?.filter(x => x.id === formData?.warehouseId);
    if (data != null && data?.length > 0) {
      return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
    }
    return undefined;
  }



  function SetWarehouseSelect(e: any): void {
    var copy = { ...formData } as ProductInWarehouseViewModel;
    copy.warehouseId = e.value;
    copy.warehouse = e.label;
    setFormData(copy);
  }
  return (
    <form id="form" className='w-100' onChange={() => setChanged(true)}>
        <div className='row' style={{
                position: "fixed",
                right: "0",
                top: "15px",
                minHeight: "100px",
                zIndex: 10000
            }}> {notifications && notifications.map((x, i) =>
                <div className='col-12 offset-lg-8 col-md-4' key={i + "containerNotification"} >
                    <Notification message={x.message} notifyType={x.notifyType} key={i + "not"} isOpen={true} OnModal={false} />
                </div>
            )}
            </div>
      <div className="row mx-0 col-12 px-0">
        <div className="col-12 col-md-6">
          <InputTextComponent onChange={onChange} value={formData?.position} name={'position'} title={'posizione'} type={'text'} validation={validation} validationMessage={'position obbligatorio'} />
        </div>
        <div className="col-12 col-md-6">
          <InputNumberComponent onChange={onChange} value={formData?.quantity ?? 0} name={'quantity'} title={'quantita'} type={'text'} />
        </div>
        <div className="col-12 col-md-6">
          <InputTextComponent onChange={onChange} value={formData?.status} name={'status'} title={'status'} type={'text'} />
        </div>
    
        

        <div className="col-12 col-md-6">
          <SelectComponent
            value={GetWarehouseValue()}
            options={WarehouseResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" } })}
            onChange={(e) => SetWarehouseSelect(e)}
            name={'warehouseId'}
            title={'Magazzino'}
            isMulti={false}
            modal={{ modalType: "Warehouse" }}

          />
        </div>      
      </div>
      <div className="col-12 justify-content-end mt-4 d-flex footerModal">
        <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.cancel && props.action.cancel(changed)} type="button">
          ANNULLA
        </button>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => returnElement()} type="button">
          Aggiungi
        </button>
      </div>
    </form>
  );
}

export default BarCode;