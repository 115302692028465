 import { ADD_Trip_GRID, GET_Trip_FILTERS, GET_Trip_GRID, TripViewModelGrid } from '../../../Model/TripModels';

const initState: TripViewModelGrid = {
    gridResult: null,
    filter: null,
}

export const TripGridReducer = (state = initState, action: { type: string, payload: TripViewModelGrid }) => {
  if(action.payload != null && action.payload ){
  const {gridResult,filter}  = action.payload;
  switch (action.type) {
        case GET_Trip_GRID:
        case ADD_Trip_GRID:
            {
                return { ...state,gridResult }
            }
        case GET_Trip_FILTERS:
             return { ...state, filter }
        default:
            return state;
    }
}
return state;}

