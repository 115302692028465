import { EDIT_Prodotto, GET_Prodotto_DETAIL, ProdottoModelDetail } from '../../../Model/ProdottoModels';

const initState: ProdottoModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const ProdottoDetailReducer = (state = initState, action: { type: string, payload: ProdottoModelDetail }) => {
    switch (action.type) {
        case GET_Prodotto_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Prodotto:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
