import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CommonValidation } from "../../Business/Common/CommonBusiness";
import InputNumberComponent from "../../Components/FormAndFilters/InputNumberComponent";
import SelectComponent from "../../Components/FormAndFilters/SelectComponent";
import { useFormTableCrud } from "../../Hook/Grid/useFormTableCrud";
import { ProdottoViewModelQuery } from "../../Model/ProdottoModels";
import { ProductInTripViewModel } from "../../Model/TripModels";
import { GetProdotto } from "../../Redux/Action/Prodotto/ProdottoGridAction";
import { RootState } from "../../Redux/Store/rootStore";
import { setNotification } from "../../Redux/Action/NotificationAction";
import { NotifyType } from "../../Redux/Reducer/NotificationReducer";
import { stringIsNullOrEmpty } from "../../Hook/Common/Utility";
interface Props {
  action: {
    returnElement(data: ProductInTripViewModel),
    cancel(canged: boolean): void
  }
  data: ProductInTripViewModel | null,
}

const ProductInTrip: React.FC<Props> = (props) => {
  const { formData, setFormData, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<ProductInTripViewModel>(

  );
  const Product = (state: RootState) => state.ProdottoGridReducer.gridResult;
  let ProductResource = useSelector(Product);





  const validazioneClient = (formData: ProductInTripViewModel | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    if (formData?.quantity)
      addInvalidProperty("quantity");


    // if (stringIsNullOrEmpty(formData?.Prodotto))
    //   addInvalidProperty("ProdottoName");

    setValidation(copyValidation);
    return copyValidation;

  }
  const returnElement = () => {
    debugger;
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    if (formData?.quantity === undefined || formData?.quantity <= 0) {
      addInvalidProperty("quantity");
     }
    if (formData?.colliXProdotto === null || formData?.colliXProdotto === undefined || formData?.colliXProdotto <= 0) {
      addInvalidProperty("colliXProdotto");
     }
    if (stringIsNullOrEmpty(formData?.productVariantId)) {
      addInvalidProperty("ProdottoId");
     }
    setValidation(copyValidation);

    if (copyValidation.property.length > 0) {

    } else {
      formData && props.action.returnElement(formData);

    }


  }


  useEffect(() => {
    if (props.data != null) {
      setFormData(props.data);
    } else {
      setFormData({} as ProductInTripViewModel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])


  useEffect(() => {
    GetProdotto({} as ProdottoViewModelQuery);

  }, [])




  function GetProdottoValue(): any | undefined {
    let data = ProductResource?.items?.filter(x => x.variantId === formData?.productVariantId);
    if (data != null && data?.length > 0) {
      return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
    }
    return undefined;
  }

  function GetProdottoID(): any | undefined {
    let data = ProductResource?.items?.filter(x => x.variantId === formData?.productVariantId);
    if (data != null && data?.length > 0) {
      return data[0].prodottoId
    }
    return undefined;
  }

  function SetProdottoSelect(e: any): void {
    var copy = { ...formData } as ProductInTripViewModel;
    copy.productVariantId = e.value;
    copy.productName = e.label;
    let data = ProductResource?.items?.filter(x => x.variantId === e.value);
    copy.azienda = data?.[0]?.azienda ?? "";
    var prodCollo = data?.[0]?.prodottiXCollo ?? 1;
    prodCollo = prodCollo === 0 ? 1 : prodCollo;
    copy.colliXProdotto = prodCollo;

    setFormData(copy);
  }
  return (
    <form id="form" className='w-100' onChange={() => setChanged(true)}>
      <div className="row mx-0 col-12 px-0">
        <div className="col-12 col-md-6">
          <InputNumberComponent onChange={onChange} value={formData?.quantity} name={'quantity'} title={'quantita'} type={'number'} validation={validation} validationMessage="Quantità richiesta" />
        </div>
        <div className="col-12 col-md-6">
          <InputNumberComponent onChange={onChange} value={formData?.colliXProdotto ?? undefined} min={1} name={'colliXProdotto'} title={'colli per prodotto'} type={'number'} validation={validation} validationMessage="numero colli richeisto"/>
        </div>
        <div className="col-12 col-md-6">
          <SelectComponent
            value={GetProdottoValue()}
            options={ProductResource?.items?.map(x => { return { value: x.variantId ?? "", label: `${x.name} ${x.size} ${x.azienda}` } })}
            onChange={(e) => SetProdottoSelect(e)}
            name={'ProdottoId'}
            title={'Prodotto'}
            isMulti={false}
            modal={{ modalType: "Prodotto" }}
            id={GetProdottoID()}
            validation={validation}
            validationMessage="Selezionare un prodotto"

          />
          {formData?.azienda}          

        </div>
      </div>
      <div className="col-12 justify-content-end mt-4 d-flex footerModal">
        <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.cancel && props.action.cancel(changed)} type="button">
          ANNULLA
        </button>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => returnElement()} type="button">
          ADD
        </button>
      </div>
    </form>
  );
}

export default ProductInTrip;