import { EDIT_Azienda, GET_Azienda_DETAIL, AziendaModelDetail } from '../../../Model/Azienda';

const initState: AziendaModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const AziendaDetailReducer = (state = initState, action: { type: string, payload: AziendaModelDetail }) => {
    switch (action.type) {
        case GET_Azienda_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Azienda:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
