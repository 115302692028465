import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { AziendaApi } from "../../../Business/AziendaBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { DELETE_Azienda } from "../../../Model/Azienda";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function deleteAzienda(id: string) {
	setLoader("ADD", "deleteAzienda");
	let api = new AziendaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.AziendaDelete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_Azienda, payload: rtn });
	setLoader("REMOVE", "deleteAzienda");
	return rtn;
}

// export async function DeleteDeepAzienda(id: number) {
// 	let api = new AziendaApi();
// 	setLoader("ADD", "DeleteDeepAzienda");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.aziendaDeleteDeep(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: DELETE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "DeleteDeepAzienda");
// 	return rtn;
// }

// export async function GetRelatedRecordsAzienda(id: number) {
// 	let api = new AziendaApi();
// 	setLoader("ADD", "GetRelatedRecordsAzienda");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.aziendaGetRelatedRecords(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	if (result?.warning) rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: NotifyType.error }));
// 	setLoader("REMOVE", "GetRelatedRecordsAzienda");
// 	return rtn;
// }

// export async function RestoreAzienda(id: number) {
// 	setLoader("ADD", "RestoreAzienda");
// 	let api = new AziendaApi();
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.aziendaRestore(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: RESTORE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "RestoreAzienda");
// 	return rtn;
// }
