
import { CustomGridRender, FilterValueDto, QueryResultDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { Movimentazione_GRIDRENDER, Movimentazione_LOCAL, MovimentazioneViewModelQuery, Movimentazione_FILTERS_LOCAL, MovimentazioneViewModel, MovimentazioneProductVariantViewModel } from '../Model/MovimentazioneModel';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class MovimentazioneApi {
  GetProdottoMovimentazione = async (name: string | undefined, aziendaId: string| undefined, sizeId: string| undefined): Promise<ResultDto<MovimentazioneProductVariantViewModel>> => {

    const body = JSON.stringify({
      name: name,
      aziendaId: aziendaId,
      sizeId: sizeId
    });
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: body
    };
    let url = basePath + "Movimentazione/prodotto";


    let response = await fetch(url, options);
    return (await response.json());
  }
  MovimentazioneGetGrid = async (tableQuery: MovimentazioneViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "Movimentazione/Get", options);
    var res = await response.json();
    return res;
  }
  // MovimentazioneCreate = async (model: MovimentazioneViewModel): Promise<ResultDto> => {
  //   const options = {
  //     method: "POST",
  //     headers: await GetHeader(),
  //     body:JSON.stringify(model)
  //   };
  //   let url = basePath + "Movimentazione";
  //   let response = await fetch(url, options);
  //   return await response.json();
  // }
  MovimentazioneCreate = async (model: MovimentazioneViewModel): Promise<ResultDto> => {
    const formData = new FormData();

    // Aggiungi il modello serializzato in JSON
    formData.append("model", JSON.stringify(model));

    // Aggiungi il file, se presente
    if (model.file) {
      formData.append("file", model.file, model.file.name);
    }

    const options = {
      method: "POST",
      headers: await GetHeader(true, "multipart/form-data"), // Assicurati che gli headers siano compatibili con multipart/form-data
      body: formData
    };

    let url = basePath + "Movimentazione";
    let response = await fetch(url, options);
    return await response.json();
  }

  MovimentazioneGetResource = async (id?: string): Promise<ResultDto<MovimentazioneViewModel>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Movimentazione/Resource";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  Paga = async (id?: string): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
    };
    let url = basePath + "Movimentazione/pagato";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }
  Annulla = async (id?: string): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
    };
    let url = basePath + "Movimentazione/Annulla";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  MovimentazioneDelete = async (id?: string): Promise<ResultDto> => {
    const options = {
      method: "DELETE",
      headers: await GetHeader(),
    };
    let url = basePath + "Movimentazione";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  MovimentazioneEdit = async (model: MovimentazioneViewModel): Promise<ResultDto> => {
    const formData = new FormData();

    // Aggiungi il modello serializzato in JSON
    formData.append("model", JSON.stringify(model));

    // Aggiungi il file, se presente
    if (model.file) {
      formData.append("file", model.file, model.file.name);
    }

    const options = {
      method: "PUT",
      headers: await GetHeader(true, "multipart/form-data"), // Assicurati che gli headers siano compatibili con multipart/form-data
      body: formData
    };

    let url = basePath + "Movimentazione";
    let response = await fetch(url, options);
    return await response.json();
  }


  MovimentazioneDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Movimentazione/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }
  MovimentazioneExportReport = async (model: MovimentazioneViewModelQuery): Promise<ReturnFile> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(model)
    };
    let url = basePath + "Movimentazione/Export";
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  MovimentazioneGetFilters = async (columName: string, columValue?: string, queryFilter?: MovimentazioneViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "Movimentazione/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json()) as ResultDto<FilterValueDto[]>;
  }
  MovimentazioneOcr = async (localFile: any, name: any, operation: string) => {

    const formData = new FormData();
    formData.append("invoice", localFile, localFile?.name);
    formData.append("name", name);
    formData.append("operation", operation);
    let response = await fetch(basePath + "Movimentazione/UploadImageByAreaName", {
      method: "POST",
      body: formData,
      headers: await GetHeader(true)
    });
    return response;

  }

}


export class MovimentazioneApiOffline {
  MovimentazioneGetGrid = async (tableQuery: MovimentazioneViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    let items: MovimentazioneViewModel[] | null = await localforage.getItem(Movimentazione_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(Movimentazione_GRIDRENDER);
    //todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.MovimentazioneCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.MovimentazioneType?.forEach(s => {
    //   items = items?.filter(x => x.MovimentazioneType === s) ?? []
    // });
    let res: ResultDto<QueryResultDto> = { data: { gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length } }
    return res;
  }

  MovimentazioneDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Movimentazione/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  MovimentazioneGetFilters = async (columName: string, columValue?: string, queryFilter?: MovimentazioneViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    let items: ResultDto<FilterValueDto[]> = { data: await localforage.getItem(Movimentazione_FILTERS_LOCAL + columName) ?? [] };
    return items;
  }




}

