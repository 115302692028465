import React from 'react';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value: any,
  onChange: any,
  name: string,
  title: string,
  placeholder?: string,
  options: Array<{ label: string, value: any }>,
  validation?: any,
  validationMessage?: string
}

const RadioButtonComponent: React.FC<Props> = (props) => {
  const radioStyle = {
    width: '20px !important', // Larghezza fissa per il pulsante radio
    height: '20px !important', // Altezza fissa per il pulsante radio
    // Altri stili possono essere aggiunti qui
  };
  return (
    <div className="form-group col-12 pl-0">
      <label className="labelForm voda-bold text-uppercase w-100">
        {props.title ?? props.name ?? ""}
        {props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? "*" : ""}
      </label>
      {props.options.map((option, index) => (
        <div className="form-group w-100 col-12 pl-0" key={"radio"+option.label}>
          <label htmlFor={`radio-${option.value}`} className="radio-label">{option.label}</label>

          <input
            id={`radio-${option.value}`}
            type="radio"
            name={props.name}
            value={option.value}
            checked={props.value === option.value}
            style={radioStyle}
            onChange={(e) => props.onChange(props.name, e)}
            className="inputForm w-100 form-control"
          />
        </div>

      ))
      }
      {
        props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? (
          <label className="validation">{props.validationMessage}</label>
        ) : null
      }
    </div >
  );
};

export default RadioButtonComponent;
