 import { ADD_Movimentazione_GRID, GET_Movimentazione_FILTERS, GET_Movimentazione_GRID, MovimentazioneViewModelGrid } from '../../../Model/MovimentazioneModel';

const initState: MovimentazioneViewModelGrid = {
    gridResult: null,
    filter: null,
}

export const MovimentazioneGridReducer = (state = initState, action: { type: string, payload: MovimentazioneViewModelGrid }) => {
  if(action.payload != null && action.payload ){
  const {gridResult,filter}  = action.payload;
  switch (action.type) {
        case GET_Movimentazione_GRID:
        case ADD_Movimentazione_GRID:
            {
                return { ...state,gridResult }
            }
        case GET_Movimentazione_FILTERS:
             return { ...state, filter }
        default:
            return state;
    }
}
return state;}

