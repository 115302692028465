import React from 'react'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import Icon from '../../../Components/Various/Icon'
import { GetProdottoViewModel } from '../../../Model/ProdottoModels'
import { VehicleViewModel } from '../../../Model/VehicleModels'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import { useOperationTableCrud } from '../../../Hook/Grid/useOperationTableCrud'
import { formatTime } from '../../../Hook/Common/TimeAndDate'

interface Props {
  action?: {
    addVehicle?(data: VehicleViewModel): any | void,
    removeVehicle?(data: VehicleViewModel): any | void,
    chartBar?(data: VehicleViewModel): any | void,
    getMaps?(id: string): any | void,
    sendPosition?(id: string): any | void,
  }
  model: VehicleViewModel,
}
const VehicleComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><Icon icon={require("../../../img/local_shipping.png")} height={30} /><label>{props.model.model}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar && <div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)} /></div>}
            {props.action && props.action.addVehicle && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addVehicle && props.action.addVehicle(props.model)} /></div>}
            {props.action && props.action.removeVehicle && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeVehicle && props.action.removeVehicle(props.model)} /></div>}
            {props.action && props.action.getMaps && <div className='col mr-0'> <ButtonActionIconGoogleIcon icon='map' action={() => props.action && props.action.getMaps && props.action.getMaps(props.model.id)} /></div>}
            {props.action && props.action.sendPosition && <div className='col mr-0'> <ButtonActionIconGoogleIcon icon='location_on' action={() => props.action && props.action.sendPosition && props.action.sendPosition(props.model.id)} /></div>}

            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-md-6'>{props.model.status ? <label ><MaterialIcon icon="description" /> {props.model.status}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.licensePlate ? <label ><MaterialIcon icon="fingerprint" /> targa: {props.model.licensePlate}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.lastMaintenanceDate ? <label ><MaterialIcon icon="calendar_month" />ultima manutenzione: {formatTime(props.model.lastMaintenanceDate)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.nextMaintenanceDate ? <label ><MaterialIcon icon="calendar_month" />prossima manutenzione: {formatTime(props.model.nextMaintenanceDate)}   </label> : null} </div>
            <div className='col-12 w-100'>{props.model.gpsInfo ? <label className='w-100'><MaterialIcon icon="explore_nearby" />
              {props.model.gpsInfo &&
                <div className='row w-100'>
                  <div className='col-6'>Sito:<b>{props.model.gpsInfo?.siteGps} </b> <br />
                    Gps Id:<b>{props.model.gpsInfo?.gpsIdentifier} </b> <br />
                    Telefono:<b>{props.model.gpsInfo?.gpsPhoneNumber} </b> <br />
                  </div>
                  <div className='col-6'>
                    User:<b>{props.model.gpsInfo?.userName} </b> <br />
                    Password:<b>{props.model.gpsInfo?.password} </b> <br /></div>

                </div>}
            </label>

              : null} </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleComponent