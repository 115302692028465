import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_Azienda_GRID, AziendaViewModelGrid, AziendaViewModelQuery, AziendaViewModel, Azienda_GRIDRENDER, ADD_Azienda_GRID, GET_Azienda_FILTERS, AziendaQueryResultDto } from '../../../Model/Azienda';
import { FilterValueDto, QueryResultDto, ResultDto } from '../../../Model/CommonModel';
import { AziendaApi, AziendaApiOffline } from "../../../Business/AziendaBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreAziendaFilters } from './AziendaStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetAziendaViewModel = async (tableQuery: AziendaViewModelQuery) => {
    setLoader("ADD", "GetAziendaViewModel");
    let api = navigator.onLine ? new AziendaApi():new AziendaApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.AziendaGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,Azienda_GRIDRENDER);
    rootStore.dispatch({ type: GET_Azienda_GRID, payload: { gridResult: result?.data, filter: null } as AziendaViewModelGrid });
    setLoader("REMOVE", "GetAziendaViewModel");

}
export const GetAddResultToGrid = async (tableQuery: AziendaViewModelQuery, dataToConcat: AziendaViewModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new AziendaApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.AziendaGetGrid(tableQuery));
    if (data && !arrayIsNullOrEmpty(data.data.items) && data.data.items !== undefined) {
        let arr = dataToConcat?.concat(data.data.items as any[])
        // data.totalItems = data.totalItems;
        data.data.items = arr;
    }
    rootStore.dispatch({ type: ADD_Azienda_GRID, payload: { gridResult: data?.data, filter: null } as AziendaViewModelGrid });
    return data?.data.items
}


export async function GetFilterColumnAziendaViewModel(columName: string, columValue: string, queryFilter?: AziendaViewModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new AziendaApi(): new AziendaApiOffline();
    // setLoader("ADD", "GetFilterColumnAziendaViewModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.AziendaGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.AziendaGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_Azienda_FILTERS, payload: { gridResult: null, filter: result?.data } as AziendaViewModelGrid });

    result && StoreAziendaFilters(result.data ?? [],columName);
    return result
}


