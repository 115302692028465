import { WarehouseModelDownloaded } from '../../../Model/Warehouse';

const initState: WarehouseModelDownloaded = {
    dtoDownload: [],
}



export const WarehouseDownloadReducer = (state = initState, action: { type: string, payload: number[] }) => {
    switch (action.type) {
        case "PUSH_DOWNLOAD":
            {
                let arr = [...state.dtoDownload]
                action.payload.map(x => {
                    if (arr.findIndex(a => a === x) === -1){
                        arr.push(x)
                    }
                })
                return { ...state, dtoDownload: arr }
            }
        case "REPLACE_DOWNLOAD":
            {
                return { ...state, dtoDownload: action.payload }
            }

        default:
            return state;
    }
}
