import {  CREATE_Azienda, AziendaCreate,EDIT_Azienda, GET_EDIT_Azienda } from "../../../Model/Azienda"

const initState: AziendaCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const AziendaEditReducer = (state = initState, action: { type: string, payload: AziendaCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case EDIT_Azienda:
            {

                return { ...state, resultDto }
            }
        case GET_EDIT_Azienda:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
