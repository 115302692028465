import { ApiCallWithErrorHandling, ApiCallWithErrorHandlingFile } from "../../../Business/Common/CommonBusiness";
import { FileResult, ReturnFile } from '../../../Model/CommonModel';
import { ProformaApi } from "../../../Business/ProformaBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from "../NotificationAction";
import { rootStore } from "../../Store/rootStore";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { ProformaViewModelQuery } from "../../../Model/ProformaModels";


export async function DownloadProformaItem(id: number) {

    setLoader("ADD", "DownloadProformaItem");
    let res: ReturnFile | undefined;
    let api = new ProformaApi();

    res = await ApiCallWithErrorHandlingFile<Promise<ReturnFile>>(() =>
       api.ProformaDownload(id)
    );

    if(res?.FileName === null){
      rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning  }));
      setLoader("REMOVE", "DownloadProformaItem");
    }else{

      let fileNameBase = res?.FileName?.split(";")[1] ?? "";
      var index = fileNameBase?.indexOf('"') + 1;
      var lastIndex = fileNameBase?.indexOf('"', index);
      let fileName = fileNameBase?.substring(index, lastIndex);
      let result = res?.File.then((x) => {
          setLoader("REMOVE", "DownloadProformaItem");
          return { file: x, fileName: fileName } as FileResult;
      });
      setLoader("REMOVE", "DownloadProformaItem");
      return result;
    }
}

export async function DownloadProformaExcell(id: string) {

    setLoader("ADD", "DownloadProformaItem");
    let res: ReturnFile | undefined;
    let api = new ProformaApi();

    res = await ApiCallWithErrorHandlingFile<Promise<ReturnFile>>(() =>
       api.ProformaDownloadExcell(id)
    );

    if(res?.FileName === null){
      rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning  }));
      setLoader("REMOVE", "DownloadProformaItem");
    }else{

      let fileNameBase = res?.FileName?.split(";")[1] ?? "";
      var index = fileNameBase?.indexOf('"') + 1;
      var lastIndex = fileNameBase?.indexOf('"', index);
      let fileName = fileNameBase?.substring(index, lastIndex);
      let result = res?.File.then((x) => {
          setLoader("REMOVE", "DownloadProformaItem");
          return { file: x, fileName: fileName } as FileResult;
      });
      setLoader("REMOVE", "DownloadProformaItem");
      return result;
    }
}
export async function DownloadProformaFile(filePath: string) {

    setLoader("ADD", "DownloadProformaFile");
    let res: ReturnFile | undefined;
    let api = new ProformaApi();

    res = await ApiCallWithErrorHandlingFile<Promise<ReturnFile>>(() =>
       api.ProformaDownloadPdf(filePath)
    );

    if(res?.FileName === null){
      rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning  }));
      setLoader("REMOVE", "DownloadProformaFile");
    }else{

      let fileNameBase = res?.FileName?.split(";")[1] ?? "";
      var index = fileNameBase?.indexOf('"') + 1;
      var lastIndex = fileNameBase?.indexOf('"', index);
      let fileName = fileNameBase?.substring(index, lastIndex);
      let result = res?.File.then((x) => {
          setLoader("REMOVE", "DownloadProformaFile");
          return { file: x, fileName: fileName } as FileResult;
      });
      setLoader("REMOVE", "DownloadProformaFile");
      return result;
    }
}
export async function GetProformaReport(queryFilter?:  ProformaViewModelQuery) {
	let res: ReturnFile | undefined;
	let api = new ProformaApi();

	setLoader("ADD", "GetProformaReport");

		res = await ApiCallWithErrorHandling<Promise<ReturnFile>>(() =>
			api.proformaExportReport(
				queryFilter || {} 
			)
		);
	
	let fileNameBase = res?.FileName.split(";")[1] ?? "";
	var index = fileNameBase?.indexOf('"') + 1;
	var lastIndex = fileNameBase?.indexOf('"', index);
	let fileName = fileNameBase?.substring(index, lastIndex);
	let result = res?.File.then((x) => {
		setLoader("REMOVE", "GetProformaReport");

		return { file: x, fileName: fileName } as FileResult;
	});
	setLoader("REMOVE", "GetProformaReport");

	return result;
}
