import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import FiltersMenuIconButton from '../../Components/FormAndFilters/FiltersMenuIconButton';
import Pagination from '../../Components/PaginationComponentHard';
import SetupColumns from '../../Components/SetupColums/SetupColumns';
import ModalConfirm from '../../Components/Various/ModalConfirm';
import '../../Css/AziendaList.css';
import '../../Css/Index.css';
import { useOperationTableCrud } from '../../Hook/Grid/useOperationTableCrud';
import { useResourceTableCrud } from '../../Hook/Grid/useResourceTableCrud';
import { DataModalConfirm, stateConfirm } from '../../Model/CommonModel';
import { UserModelQuery, UserModelResource } from '../../Model/UserModels';
import setLoader from '../../Redux/Action/LoaderAction';
import { GetUserCreateResource } from '../../Redux/Action/UserList/UserListCreateAction';
import { GetAddResultToGrid, GetFilterColumnUserModel, GetUserModel } from '../../Redux/Action/UserList/UserListGridAction';
import { RootState } from '../../Redux/Store/rootStore';
import ListaUtentiGrid from '../ListaUtenti/ListaUtentiGrid';
import ListaUtentiModal from '../ListaUtenti/ListaUtentiModal';

interface Props {
  isOnline: boolean
}

const ListaUtentiContainer: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  let initialState: UserModelQuery = {

    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 10,
    claimsType:[],
    claimsValue:[],
    email:[],
    firstName:[],
    id:undefined,
    lang:undefined,
    lastName:[],
    phone:[],
    role:[],
    userName:[]
   }

  useEffect(() => {
    if (props.isOnline === false)
      setQuery(query, true)
  }, [props.isOnline])

  //UTILITY
  const [confirmCustom, setConfirmCustom] = useState<DataModalConfirm>(stateConfirm)

  //REDUCERS
  const GridData = (state: RootState) => state.UserListGridReducer.gridResult;
  let GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  let layout = useSelector(LayoutData);

  const downloadedData = (state: RootState) => state.UserListDownloadReducer.dtoDownload;
  let downloadedList = useSelector(downloadedData);

  //DETAIL
  const [isDetailOpened, setIsDetailOpened] = useState<boolean>(false)
  const [isVisibleModalSetup, setIsVisibleModalSetup] = useState<boolean>(false);


// useEffect(() => {

// }, [GridDto])


   //FILTERS
  const { query, setQuery, next, back, goToPage } = useResourceTableCrud(initialState, GetUserModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);

  const { New, Edit, isVisibleModal, edit, confirm, setConfirm, closeModal, Delete, localStateHistory, setLocalState } = useOperationTableCrud<UserModelResource,UserModelResource>(
    GetUserCreateResource,
    GetUserCreateResource,
  );

  const closeModalSetup = async (changed: boolean) => {
    setIsVisibleModalSetup(false)

  };
  const closeModalForm = async (changed: boolean) => {
    closeModal(changed);
    await GetUserModel(initialState).then(() => {
      setLoader("REMOVE", "GetUserModel")
    });
  };


  return (
    <div className={` mt-3  m-3 mx-0 relative h-100 pageContainer justify-content-center ${darkTheme ? "dark" : ""}`}>
    <ModalConfirm data={confirm} />

    <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
      <Modal.Header className="d-flex justify-content-center">
        <div className="  px-0 mb-2">
          <div className="  mt-3">
            <h4>{edit ? "Modifica Utente" : "Aggiungi Utente"}</h4>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ListaUtentiModal action={{ closeModal: closeModalForm }} edit={edit} isVisibleModal={isVisibleModal} />
      </Modal.Body>
    </Modal>

    <div className="d-flex align-items-center justify-content-between ">
      <h2 className="mb-0">Lista Utente</h2>
      {/* <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" type="button" onClick={() => InvocheDownload()}>
          <img alt="Download" width="26" style={{ marginBottom: "2px" }} src={require("../../img/excel.png")} />
        </button> */}
      <button className='btn btnOutline' type='button' onClick={() => New()}>Aggiungi Utente</button>
    </div>
    <div className='  pt-3'>
      <div className={` pb-5 pt-4 ${layout.isMobile ? "" : "tableContainer"}`}>
        {layout.isMobile ?
          <div className="d-flex align-items-center justify-content-between ">
            <FiltersMenuIconButton containerClass={""} initialStateQuery={initialState} query={query} propertyToNotOverride={"projectCode"} action={{ applyFilters: setQuery, getFilterColumn: GetFilterColumnUserModel }} renderGrid={GridDto?.gridRender?.render} />
          </div>
          :
          <div className='  pb-2 d-flex justify-content-end relative'>
            <button type='button' className='btn px-0 btnColonne d-flex align-items-center justify-content-center' onClick={() => setIsVisibleModalSetup(!isVisibleModalSetup)}>
              <img alt="colonne" src={require('../../svg/colonne.png')}></img>
              <span className='ml-2 mt-1'>Modifica Colonne</span>
            </button>
            {isVisibleModalSetup &&
              <div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
                <div className="orderContainer absolute">
                  <SetupColumns renderGrid={GridDto?.gridRender} action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
                </div>
              </div>
            }
          </div>}
        <div className='col-12 h-100 paginationScrollable' id='paginationScrollable'>
          <div id="scrollableDiv" style={{ height: layout.isMobile ? 550 : "100%", overflow: "auto" }}>
            <Pagination isMobile={layout.isMobile ?? false} pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems ?? 0} itemsLength={GridDto?.items?.length ?? 0} actions={{ next, back, goToPage }} >
              <ListaUtentiGrid  isMobile={layout.isMobile ?? false} action={{ setConfirm, setQuery, Edit, onDelete: Delete }} data={GridDto?.items ?? []} downloadedList={downloadedList} query={query} renderGrid={GridDto?.gridRender?.render} />
            </Pagination>
          </div></div>
      </div>
    </div>
  </div>
    // <div className={`col-12 mt-3 row mx-0 relative h-100 pageContainer justify-content-center ${darkTheme ? "dark" : ""}`}>
    //   <ModalConfirm data={confirm} />

    //   <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
    //     <Modal.Header className="d-flex justify-content-center">
    //       <div className="col-12 px-0 mb-2">
    //         <div className="col-12 mt-3">
    //           <h4>{edit ? "Modifica Utente" : "Aggiungi Utente"}</h4>
    //         </div>
    //       </div>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <ListaUtentiModal action={{ closeModal : closeModalForm }} edit={edit} isVisibleModal={isVisibleModal} />
    //     </Modal.Body>
    //   </Modal>

    //   <div className="col-12">
    //     <div className="d-flex align-items-center justify-content-between col-12">
    //       <h2 className="mb-0">Lista Utenti</h2>
    //       <button className='btn btnOutline' type='button' onClick={() => New()}>Aggiungi Utente</button>
    //     </div>
    //     <div className='col-12 pt-3'>
    //       <div className={`col-12 pb-5 pt-4 tableContainer`}>
    //         <div className='col-12 pb-2 d-flex justify-content-end relative'>
    //           <button type='button' className='btn px-0 btnColonne d-flex align-items-center justify-content-center' onClick={() => setIsVisibleModalSetup(!isVisibleModalSetup)}>
    //             <img alt="colonne" src={require('../../svg/colonne.png')}></img>
    //             <span className='ml-2 mt-1'>Modifica Colonne</span>
    //           </button>
    //           {isVisibleModalSetup &&
    //             <div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
    //               <div className="orderContainer absolute">
    //                 <SetupColumns renderGrid={GridDto?.gridRender} action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
    //               </div>
    //             </div>
    //           }
    //         </div>
    //         <ListaUtentiGrid action={{  setConfirm, setQuery, Edit,onDelete:Delete }} data={GridDto?.items} downloadedList={downloadedList} query={query} renderGrid={GridDto?.gridRender?.render} />
    //         <Online>
    //           <Pagination isMobile={layout.isMobile ?? false} pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems ?? 0} itemsLength={GridDto?.items?.length ?? 0} actions={{ next, back, goToPage }} />
    //         </Online>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
export default ListaUtentiContainer
