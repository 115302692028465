import React, { useEffect, useState } from 'react';
import { GetHeader, basePath } from '../../../Business/Common/configuration';
import { Position } from '../../../Model/VehicleModels';
// Assicurati di includere le altre importazioni necessarie

 

// Struttura dei dati da inviare
interface GpsData {
  posizioni: Position[];
}

interface LocationSenderProps {
  id: string; // ID passato come prop
}
 
const LocationSender: React.FC<LocationSenderProps> = ({ id }) => {
  const [status, setStatus] = useState<string>('In attesa di posizione...');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      position => {
        setStatus('Invio posizione...');
        const { latitude, longitude, speed } = position.coords;
        const posizione: Position = {
          lat: latitude,
          lng: longitude,
          speed: speed || 0,
        };
        sendLocationToServer([posizione]);
      },
      err => {
        setError(err.message);
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  const sendLocationToServer = async (posizioni: Position[]) => {
    try {
      const response = await fetch(basePath + `Vehicle/SavePosition/${id}`, {
        method: 'POST',
        headers:await GetHeader(),
        body: JSON.stringify({ posizioni }),
      });

      if (!response.ok) {
        throw new Error(`Errore HTTP: ${response.status}`);
      }

       setStatus('Posizione inviata con successo!');
  } catch (error) {
    
    console.log(error);
    // Assicurati di convertire l'oggetto Error in una stringa
    // setError(`Errore: ${error?.message}`);
    setStatus('Errore nell\'invio della posizione.');
  }
  };

  return (
    <div>
    <p>{status}</p>
    {/* Assicurati che error sia una stringa */}
    {error && <p>{error}</p>}
  </div>
  );
};

export default LocationSender;
