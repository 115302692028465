import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ProductSizeApi } from "../../../Business/ProductSizeBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { DELETE_ProductSize } from "../../../Model/ProductSizeModel";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function deleteProductSize(id: string) {
	setLoader("ADD", "deleteProductSize");
	let api = new ProductSizeApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProductSizeDelete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_ProductSize, payload: rtn });
	setLoader("REMOVE", "deleteProductSize");
	return rtn;
}

// export async function DeleteDeepProductSize(id: number) {
// 	let api = new ProductSizeApi();
// 	setLoader("ADD", "DeleteDeepProductSize");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.productsizeDeleteDeep(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: DELETE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "DeleteDeepProductSize");
// 	return rtn;
// }

// export async function GetRelatedRecordsProductSize(id: number) {
// 	let api = new ProductSizeApi();
// 	setLoader("ADD", "GetRelatedRecordsProductSize");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.productsizeGetRelatedRecords(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	if (result?.warning) rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: NotifyType.error }));
// 	setLoader("REMOVE", "GetRelatedRecordsProductSize");
// 	return rtn;
// }

// export async function RestoreProductSize(id: number) {
// 	setLoader("ADD", "RestoreProductSize");
// 	let api = new ProductSizeApi();
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.productsizeRestore(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: RESTORE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "RestoreProductSize");
// 	return rtn;
// }
