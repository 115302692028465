import React, { useEffect, useRef, useState, forwardRef, Ref, useImperativeHandle } from "react";
import { PdfToImage, PdfToImageFile } from "../Business/image/PdfUtils";
import { GetHeader, basePath } from "../Business/Common/configuration";
import { ProcessImageRequestSave } from "../Model/InvoiceAreasModel";
import { AddInvoicesArea, GetInvoicesArea, DeleteInvoicesArea } from "../Redux/Action/InvoiceAreas/InvoicesAreaAction";
import SelectComponent from "./FormAndFilters/SelectComponent";
import InputTextComponent from "./FormAndFilters/InputTextComponent";
import { GetProdottoViewModel } from "../Model/ProdottoModels";
import { dispatch, json } from "d3";
import { rootStore } from "../Redux/Store/rootStore";
import { setNotification } from "../Redux/Action/NotificationAction";
import { OcrMovimentazione } from "../Redux/Action/Movimentazione/MovimentazioneCreateAction";
import { NotifyType } from "../Redux/Reducer/NotificationReducer";
import setLoader from "../Redux/Action/LoaderAction";

interface Props {
  AddFromCanvas: (x: GetProdottoViewModel[], azienda: any, aziendaSeller: any, aziendaBuyer: any, date: Date, file: File,  numeroFattura:string | null    ) => void
  operation: "In" | "Out" | number;
}
export interface CanvasComponentMethods {
  externalMethod: () => void;
}
const ImageFromInput = forwardRef<CanvasComponentMethods, Props>((props, ref) => {

  const [height, setHeight] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const [imageData, setImageData] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    if (imageData && imageCanvasRef.current) {
      const canvas = imageCanvasRef.current;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(imageData, 0, 0, canvas.width, canvas.height);
      }
    }
  }, [imageData]);


  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let localFile: File | null = null;
    if (event.target.files && event.target.files[0]) {
      setLoader("ADD", "imageChange")
      if (event.target.files[0] && event.target.files[0].type === 'application/pdf') {

        let fileFromPdf = await PdfToImageFile(event.target.files[0]);
        setFile(fileFromPdf);
        localFile = fileFromPdf;
      } else {
        setFile(event.target.files[0]);
        localFile = event.target.files[0];
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result as string;
        img.onload = () => setImageData(img);
      };
      reader.readAsDataURL(localFile);
      // await sendImageAutoTuning(localFile);
      setLoader("REMOVE", "imageChange")

    }
  };
  useEffect(() => {
    if (imageData && containerRef.current?.clientWidth) {
      const ctx = imageCanvasRef?.current?.getContext("2d");
      let rapportoImage = (imageData.height / imageData.width) * 100;
      let h = (containerRef.current.clientWidth / 100) * rapportoImage;
      // const ratio = (containerRef.current.clientWidth / imageData.width) / (height / imageData.height); // calcola il rapporto di dimensioni tra le due immagini

      setHeight(h);
      if (ctx) {
        ctx.drawImage(
          imageData,
          0,
          0,
          imageCanvasRef?.current?.width ?? 0,
          imageCanvasRef?.current?.height ?? 0
        );
      }
    }
  }, [imageData]);
  useEffect(() => {
    if (height !== 0 && imageData) {
      const ctx = imageCanvasRef?.current?.getContext("2d");
      if (ctx) {
        ctx.drawImage(
          imageData,
          0,
          0,
          imageCanvasRef?.current?.width ?? 0,
          imageCanvasRef?.current?.height ?? 0
        );
      }
    }
  }, [height]);

  const sendSelections = async (fast:boolean) => {
    setLoader("ADD", "sendSelections")

    const formData = new FormData();
    if (file) {
      formData.append("Invoice", file, file?.name);
      formData.append("Operation", props.operation.toString());
      formData.append("Fast", fast.toString());


      let response = await fetch(basePath + "Movimentazione/UseOpenAI", {
        method: "POST",
        body: formData,
        headers: await GetHeader(true)

      });
      setLoader("REMOVE", "sendSelections")

      await returnByOcr(response, file);
    }


  };
  async function returnByOcr(response: Response, localFile: File) {
    let resultResponce = await response.json();
    let result:OcrInvoiceResult =  resultResponce.data;    
    props.AddFromCanvas(result.movimentazioni?.map(x => {
      return {
        name: x.productName,
        size: x.size,
        sellValue: x.productVariant?.sellValue,
        buyValue: x.productVariant?.buyValue,
        quantita: x.quantity,
        prodottoId: x.productId,
        variantId: x.productVariantId,
        invoicePrice: x.invoicePrice,
        invoiceQuantity: x.invoiceQuantity,
        productName: x.productName,
        sizeId: x.sizeId,
        inWarehouseViewModel: [],
        inWarehouseViewModelDb: x.productVariant?.inWarehouseViewModel
          ? JSON.parse(JSON.stringify(x.productVariant.inWarehouseViewModel))
          : [],
        inWarehouseViewModelDbHistory: x.productVariant?.inWarehouseViewModel
          ? JSON.parse(JSON.stringify(x.productVariant.inWarehouseViewModel))
          : [],
        azienda: x.productVariant?.azienda,
        aziendaId: x.productVariant?.aziendaId,
        code: x.productVariant?.code,
        id: x.id,
        image: null,
        type: null,
        creaInDatabase: false,
        prodottiXCollo: x.productVariant?.prodottiXCollo ?? 0
      } as GetProdottoViewModel;
    }), result.azienda, result.aziendaSeller, result.aziendaBuyer, result.date as Date, localFile as File,result.numeroFattura);
  }



  return (
    <div className="container text-center position-relative">
      <div className="position-relative">
        <input
          type="file"
          name="loadImage"
          onInput={handleImageChange}
        />

        <hr />
      </div>

      <div ref={containerRef} className="m-0 p-0 position-relative">
        {imageData && (
          <>

            <canvas
              width={containerRef.current?.clientWidth}
              height={height}
              ref={imageCanvasRef}
            />
          </>
        )}
      </div>
      <button
          type='button'
          onClick={()=>sendSelections(false)}
          className="btn btn-success mx-2"
          disabled={imageData == null}
        >
          Invia all'IA precisa
        </button>
      <button
          type='button'
          onClick={()=>sendSelections(true)}
          className="btn btn-success mx-2"
          disabled={imageData == null}

        >
          Invia all'IA veloce
        </button>
    </div>
  );
});

export default ImageFromInput;



export interface OcrInvoiceResult {
  aziendaSeller: any
  aziendaBuyer: any
  azienda: any
  movimentazioni: Movimentazioni[]
  date: Date;
  numeroFattura:string | null 
}

export interface Movimentazioni {
  id: any
  movimentazioneId: any
  productVariantId: any
  productId: any
  productName: string
  quantity: number
  productVariant: any
  productVariantName: string
  price: number
  invoiceQuantity: number
  invoicePrice: number
  size?: string | null
  sizeId?: string | null
}

