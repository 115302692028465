import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MultiValue } from "react-select";
import { DarkTheme } from '../../App';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';
import '../../Css/AziendaList.css';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { UserModelResource } from '../../Model/UserModels';
import { CreateUser } from '../../Redux/Action/UserList/UserListCreateAction';
import { UpdateUser } from '../../Redux/Action/UserList/UserListEditAction';
import { RootState } from '../../Redux/Store/rootStore';

interface Props {
  action: {
    closeModal(changed: boolean): any
  }
  isVisibleModal: boolean
  edit: boolean
  id?: string
}

const ListaUtentiModal: React.FC<Props> = (props) => {


  const validazioneClient = (formData: UserModelResource | null | undefined, edit: boolean, validazioneClient: any)=>  {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    if (stringIsNullOrEmpty(formData?.email))
      addInvalidProperty("email");


    if (stringIsNullOrEmpty(formData?.userName))
      addInvalidProperty("userName");

    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
   };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<UserModelResource>(
    CreateUser,
    UpdateUser
  );

  const dtoEditResourceState = (state: RootState) => state.UserListCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    setFormData(editResource);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editResource])


  function setMultipleRole(e: MultiValue<{ value: string; label: string; }>): void {
    var copy = { ...formData } as UserModelResource;
    copy.role = e.map(x => { return x.value })
    setFormData(copy);
  }

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <form id="form" className='w-100' onChange={() => setChanged(true)}>
        <div className="row mx-0 col-12 px-0">
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.email} name={'email'} title={'Email'} placeholder={'example@example.com'} type={'email'} validation={validation} validationMessage={'Email obbligatoria'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.userName} name={'userName'} title={'User Name'} type={'text'} validation={validation} validationMessage={'User Name obbligatorio'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.firstName} name={'firstName'} title={'Nome'} placeholder={''} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.lastName} name={'lastName'} title={'Cognome'} placeholder={''} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.phone} name={'phone'} title={'Telefono'} placeholder={''} type={'text'}  />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.password} name={'password'} title={'Password'} placeholder={''} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <SelectComponent
              value={formData?.role?.map(x => { return { value: x ?? "", label: x ?? "" }; }) ?? []}
              options={formData?.roleResource?.map(x => { return { value: x, label: x } })}
              onChange={(e) => setMultipleRole(e)}
              name={'role'}
              title={'Role'}
              isMulti={true}
            />
          </div>
        </div>
        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
          <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
            ANNULLA
          </button>
          <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, refresh)} type="button">
            SALVA
          </button>
        </div>
      </form>


    </div>
  );
}
export default ListaUtentiModal





