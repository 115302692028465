import React, { useContext } from 'react';
import '../../Css/AziendaList.css'
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { RootState } from '../../Redux/Store/rootStore';
import { useEffect } from 'react';
import { DarkTheme } from '../../App';
import { useSelector } from 'react-redux';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { RoleModelResource } from '../../Model/RoleModels';
import { CreateRole } from '../../Redux/Action/Role/RoleCreateAction';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import { MultiValue } from "react-select";
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import { UpdateRole } from '../../Redux/Action/Role/RoleEditAction';

interface Props {
  action: {
    closeModal(changed: boolean): any
  }
  isVisibleModal: boolean
  edit: boolean
  id?: string
}

const RoleModal: React.FC<Props> = (props) => {


  const validazioneClient = (formData: RoleModelResource | null | undefined, edit: boolean, validazioneClient: any)=>  {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    if (stringIsNullOrEmpty(formData?.role))
      addInvalidProperty("role");


    // if (stringIsNullOrEmpty(formData?.role))
    //   addInvalidProperty("roleName");

    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
   };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<RoleModelResource>(
    CreateRole,
    UpdateRole
  );

  const dtoEditResourceState = (state: RootState) => state.RoleCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    setFormData(editResource);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editResource])


  function setMultipleRole(e: MultiValue<{ value: string; label: string; }>): void {
    var copy = { ...formData } as RoleModelResource;
    copy.claim = e.map(x => { return {type:x.value,value:x.label}  })
    setFormData(copy);
  }

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <form id="form" className='w-100' onChange={() => setChanged(true)}>
        <div className="row mx-0 col-12 px-0">
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.role} name={'role'} title={'role'}  type={'text'} validation={validation} validationMessage={'Name obbligatorio'} />
          </div>

          <div className="col-12 col-md-6">
            <SelectComponent
              value={formData?.claims?.map(x => { return { value: x ?? "", label: x ?? "" }; }) ?? []}
              options={formData?.claims?.map(x => { return { value: x, label: x } })}
              onChange={(e) => setMultipleRole(e)}
              name={'role'}
              title={'Role'}
              isMulti={true}
            />
          </div>
        </div>
        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
          <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
            ANNULLA
          </button>
          <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, refresh)} type="button">
            SALVA
          </button>
        </div>
      </form>


    </div>
  );
}
export default RoleModal





