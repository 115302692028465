import { combineReducers, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AuthReducer } from "../Reducer/AuthReducer";
import { AziendaCreateReducer } from "../Reducer/Azienda/AziendaCreateReducer";
import { AziendaDetailReducer } from "../Reducer/Azienda/AziendaDetailReducer";
import { AziendaDownloadReducer } from "../Reducer/Azienda/AziendaDownloadReducer";
import { AziendaEditReducer } from "../Reducer/Azienda/AziendaEditReducer";
import { AziendaGridReducer } from "../Reducer/Azienda/AziendaGridReducer";
import { LayoutReducer } from '../Reducer/LayoutReducer';
import { LoaderReducer } from '../Reducer/LoaderReducer';
import { MovimentazioneCreateReducer } from "../Reducer/Movimentazione/MovimentazioneCreateReducer";
import { MovimentazioneDetailReducer } from "../Reducer/Movimentazione/MovimentazioneDetailReducer";
import { MovimentazioneDownloadReducer } from "../Reducer/Movimentazione/MovimentazioneDownloadReducer";
import { MovimentazioneEditReducer } from "../Reducer/Movimentazione/MovimentazioneEditReducer";
import { MovimentazioneGridReducer } from "../Reducer/Movimentazione/MovimentazioneGridReducer";
import { errorReducer } from '../Reducer/NotificationReducer';
import { ProdottoCreateReducer } from "../Reducer/Prodotto/ProdottoCreateReducer";
import { ProdottoDetailReducer } from "../Reducer/Prodotto/ProdottoDetailReducer";
import { ProdottoDownloadReducer } from "../Reducer/Prodotto/ProdottoDownloadReducer";
import { ProdottoEditReducer } from "../Reducer/Prodotto/ProdottoEditReducer";
import { ProdottoGridReducer } from "../Reducer/Prodotto/ProdottoGridReducer";
import { ProductSizeCreateReducer } from "../Reducer/ProductSize/ProductSizeCreateReducer";
import { ProductSizeDetailReducer } from "../Reducer/ProductSize/ProductSizeDetailReducer";
import { ProductSizeDownloadReducer } from "../Reducer/ProductSize/ProductSizeDownloadReducer";
import { ProductSizeEditReducer } from "../Reducer/ProductSize/ProductSizeEditReducer";
import { ProductSizeGridReducer } from "../Reducer/ProductSize/ProductSizeGridReducer";
import { ProformaCreateReducer } from "../Reducer/Proforma/ProformaCreateReducer";
import { ProformaDetailReducer } from "../Reducer/Proforma/ProformaDetailReducer";
import { ProformaDownloadReducer } from "../Reducer/Proforma/ProformaDownloadReducer";
import { ProformaEditReducer } from "../Reducer/Proforma/ProformaEditReducer";
import { ProformaGridReducer } from "../Reducer/Proforma/ProformaGridReducer";
import { RoleCreateReducer } from "../Reducer/Role/RoleCreateReducer";
import { RoleDetailReducer } from "../Reducer/Role/RoleDetailReducer";
import { RoleDownloadReducer } from "../Reducer/Role/RoleDownloadReducer";
import { RoleGridReducer } from "../Reducer/Role/RoleGridReducer";
import { TripCreateReducer } from "../Reducer/Trip/TripCreateReducer";
import { TripDetailReducer } from "../Reducer/Trip/TripDetailReducer";
import { TripDownloadReducer } from "../Reducer/Trip/TripDownloadReducer";
import { TripEditReducer } from "../Reducer/Trip/TripEditReducer";
import { TripGridReducer } from "../Reducer/Trip/TripGridReducer";
import { UserListCreateReducer } from "../Reducer/UserList/UserListCreateReducer";
import { UserListDetailReducer } from "../Reducer/UserList/UserListDetailReducer";
import { UserListDownloadReducer } from "../Reducer/UserList/UserListDownloadReducer";
import { UserListGridReducer } from "../Reducer/UserList/UserListGridReducer";
import { VehicleCreateReducer } from "../Reducer/Vehicle/VehicleCreateReducer";
import { VehicleDetailReducer } from "../Reducer/Vehicle/VehicleDetailReducer";
import { VehicleDownloadReducer } from "../Reducer/Vehicle/VehicleDownloadReducer";
import { VehicleEditReducer } from "../Reducer/Vehicle/VehicleEditReducer";
import { VehicleGridReducer } from "../Reducer/Vehicle/VehicleGridReducer";
import { WarehouseCreateReducer } from "../Reducer/Warehouse/WarehouseCreateReducer";
import { WarehouseDetailReducer } from "../Reducer/Warehouse/WarehouseDetailReducer";
import { WarehouseDownloadReducer } from "../Reducer/Warehouse/WarehouseDownloadReducer";
import { WarehouseEditReducer } from "../Reducer/Warehouse/WarehouseEditReducer";
import { WarehouseGridReducer } from "../Reducer/Warehouse/WarehouseGridReducer";

const persistConfig = {
	key: "root",
	storage
};
const persistedReducer = persistReducer(persistConfig, AuthReducer);

const logger = (rootStore) => (next) => (action) => {
	if (action.type !== "ADD" && action.type !== "REMOVE") {
		console.log("dispatching", action);
	}

	let result = next(action);

	if (action.type !== "ADD" && action.type !== "REMOVE") {
		console.log("next state", rootStore.getState());
	}

	return result;
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
	loaderReducer: LoaderReducer,
	errorReducer: errorReducer,
	layoutReducer: LayoutReducer,
	AuthReducer: persistedReducer,
	UserListGridReducer: UserListGridReducer,
	UserListDetailReducer: UserListDetailReducer,
	UserListDownloadReducer: UserListDownloadReducer,
	UserListCreateReducer: UserListCreateReducer,
	RoleGridReducer: RoleGridReducer,
	RoleDetailReducer: RoleDetailReducer,
	RoleDownloadReducer: RoleDownloadReducer,
	RoleCreateReducer: RoleCreateReducer,
	ProdottoDetailReducer: ProdottoDetailReducer,
	ProdottoDownloadReducer: ProdottoDownloadReducer,
	ProdottoGridReducer: ProdottoGridReducer,
	ProdottoCreateReducer: ProdottoCreateReducer,
	ProdottoEditReducer: ProdottoEditReducer,
	AziendaDetailReducer: AziendaDetailReducer,
	AziendaDownloadReducer: AziendaDownloadReducer,
	AziendaGridReducer: AziendaGridReducer,
	AziendaCreateReducer: AziendaCreateReducer,
	AziendaEditReducer: AziendaEditReducer,
	ProductSizeDetailReducer: ProductSizeDetailReducer,
	ProductSizeDownloadReducer: ProductSizeDownloadReducer,
	ProductSizeGridReducer: ProductSizeGridReducer,
	ProductSizeCreateReducer: ProductSizeCreateReducer,
	ProductSizeEditReducer: ProductSizeEditReducer,
	MovimentazioneDetailReducer: MovimentazioneDetailReducer,
	MovimentazioneDownloadReducer: MovimentazioneDownloadReducer,
	MovimentazioneGridReducer: MovimentazioneGridReducer,
	MovimentazioneCreateReducer: MovimentazioneCreateReducer,
	MovimentazioneEditReducer: MovimentazioneEditReducer,
	WarehouseDetailReducer: WarehouseDetailReducer,
	WarehouseDownloadReducer: WarehouseDownloadReducer,
	WarehouseGridReducer: WarehouseGridReducer,
	WarehouseCreateReducer: WarehouseCreateReducer,
	WarehouseEditReducer: WarehouseEditReducer,
	VehicleDetailReducer: VehicleDetailReducer,
	VehicleDownloadReducer: VehicleDownloadReducer,
	VehicleGridReducer: VehicleGridReducer,
	VehicleCreateReducer: VehicleCreateReducer,
	VehicleEditReducer: VehicleEditReducer,
	TripDetailReducer: TripDetailReducer,
	TripDownloadReducer: TripDownloadReducer,
	TripGridReducer: TripGridReducer,
	TripCreateReducer: TripCreateReducer,
	TripEditReducer: TripEditReducer,
	ProformaDetailReducer: ProformaDetailReducer,
	ProformaDownloadReducer: ProformaDownloadReducer,
	ProformaGridReducer: ProformaGridReducer,
	ProformaCreateReducer: ProformaCreateReducer,
	ProformaEditReducer: ProformaEditReducer,
});


// export const rootStore = createStore(rootReducer, composeEnhancers(applyMiddleware(logger)));
export const rootStore = createStore(rootReducer, composeEnhancers());

persistStore(rootStore);
export type RootState = ReturnType<typeof rootReducer>;

