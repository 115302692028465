import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { TripApi } from "../../../Business/TripBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Trip, GET_CREATE_Trip, TripCreate, TripViewModel } from "../../../Model/TripModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetTripCreateResource(id?: string) {
	setLoader("ADD", "GetTripCreateResource");
	let api = new TripApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<TripViewModel>>>(() => api.TripGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as TripCreate;
	rootStore.dispatch({ type: GET_CREATE_Trip, payload: rtn });
	setLoader("REMOVE", "GetTripCreateResource");
	return rtn.dto;
}


export async function CreateTrip(data: TripViewModel) {
	setLoader("ADD", "CreateTrip");
	let api = new TripApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.TripCreate(data));
	let rtn = { dto: null, resultDto: result } as TripCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Trip, payload: rtn });
	setLoader("REMOVE", "CreateTrip");
	return rtn;
}
