import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatPriceWithLocale } from '../../../Business/Common/CommonBusiness';
import { GetHeader, basePath } from '../../../Business/Common/configuration';
import SelectComponent from '../../../Components/FormAndFilters/SelectComponent';
import ActionButtonIcon from '../../../Components/GridSystem/ActionButtonIcon';
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon';
import { FatturaViewModel } from '../../../Model/MovimentazioneModel';
import { GetAziendaViewModel } from '../../../Redux/Action/Azienda/AziendaGridAction';
import { RootState } from '../../../Redux/Store/rootStore';

interface FatturaProps {
    fattura: FatturaViewModel;
    returnFattura?: (x: FatturaViewModel) => void;
}

export const calcolaRiepilogoIVA = (fatturaCorrente) => {
    let imponibileTotale = 0;
    let impostaTotale = 0;
    let totaleDocumento = 0;

    fatturaCorrente.prodottiEServizi.forEach(prodotto => {
    
        const colli = Math.round(prodotto.quantita / (prodotto.prodottoXColli || 1));
        prodotto.colli = colli;
        const netto = prodotto.quantita * prodotto.prezzoUnitario;
        const imposta = netto * 0.22;
        const importo = netto + imposta;
        imponibileTotale += netto;
        impostaTotale += imposta;
    });

    totaleDocumento = imponibileTotale + impostaTotale;
    const nettoAPagare = totaleDocumento;

    return {
        ...fatturaCorrente,
        riepilogoIVA: {
            ...fatturaCorrente.riepilogoIVA,
            imponibile: imponibileTotale,
            imposta: impostaTotale
        },
        calcoloFattura: {
            ...fatturaCorrente.calcoloFattura,
            imponibileProdotti: imponibileTotale,
            totaleIVA: impostaTotale,
            totaleDocumento: totaleDocumento,
            nettoAPagare: nettoAPagare
        }
    };
};

const FatturaComponent = ({ fattura: initialFattura, returnFattura }) => {
    const [fattura, setFattura] = useState(calcolaRiepilogoIVA(initialFattura));

    useEffect(() => {
        returnFattura && returnFattura(calcolaRiepilogoIVA(fattura))
    }, [fattura]);

    useEffect(() => {
        GetAziendaViewModel({});
    }, []);

    const Aziende = (state: RootState) => state.AziendaGridReducer.gridResult;
    let AziendeResource = useSelector(Aziende);

    const handleInputChange = (e, section, field, index = null) => {
        let newFattura = { ...fattura };

        if (index !== null) {
            // Modifica un oggetto all'interno di un array (prodotti e servizi)
            let item = { ...newFattura[section][index] };
            item[field] = e.target.value;

            // Calcola l'importo e l'IVA se quantità o prezzo unitario cambiano
            if (field === 'quantita' || field === 'prezzoUnitario') {
                const netto = item.quantita * item.prezzoUnitario;
                const iva = netto * 0.22;
                item.importo = parseFloat((netto  ).toFixed(2));
            }

            newFattura[section] = newFattura[section].map((currentItem, idx) =>
                idx === index ? item : currentItem
            );
        } else {
            // Modifica un oggetto non all'interno di un array
            newFattura[section] = {
                ...newFattura[section],
                [field]: e.target.value
            };
        }

        const fatturaAggiornata = calcolaRiepilogoIVA(newFattura);
        setFattura(fatturaAggiornata);
    };

    // Funzione per inviare i dati al server e generare un PDF
    const generatePDF = async () => {
        try {
            const response = await fetch(basePath + 'Movimentazione/pdf', {
                method: 'POST',
                headers: await GetHeader(),
                body: JSON.stringify(fattura),
            });
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'fattura?.pdf';
                link.click();
                window.URL.revokeObjectURL(downloadUrl);
            } else {
                console.error('Server response was not ok.');
            }
        } catch (error) {
            console.error('There was an error sending the request:', error);
        }
    };

    const removeProduct = (index) => {
        const updatedProducts = fattura?.prodottiEServizi.filter((_, idx) => idx !== index);
        const fatturaAggiornata = calcolaRiepilogoIVA({
            ...fattura,
            prodottiEServizi: updatedProducts
        });

        setFattura(fatturaAggiornata);
    };

    const addProduct = () => {
        const nuovoProdotto = {
            descrizione: '',
            quantita: 0,
            prezzoUnitario: 0,
            importo: 0,
            aliquotaIVA: 22,
            colli:0,
            prodottoXColli:1,
        };
        fattura.prodottiEServizi ??= [];
        setFattura({
            ...fattura,
            prodottiEServizi: fattura?.prodottiEServizi.concat(nuovoProdotto)
        });
    };

    function GetAziendaValue(id: string): any | undefined {
        let data = AziendeResource?.items?.filter(x => x.id === id);
        if (data != null && data?.length > 0) {
            return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
        }
        return undefined;
    }

    function onChangeSelect(arg0: string, e: any): void {
        let copy = { ...fattura };
        let data = AziendeResource?.items?.filter(x => x.id === e.value);
        if (data != null && data?.length > 0) {
            let first = data[0];
            if (arg0 === "fornitore") {
                copy.fornitore.aziendaId = e.value;
                copy.fornitore.nome = first.name;
                copy.fornitore.pIVA = first.piva;
                copy.fornitore.indirizzo = first.location;
                copy.fornitore.telefono = first.phone;
                copy.fornitore.email = first.email;
            } else {
                copy.cliente.aziendaId = e.value;
                copy.cliente.nome = first.name;
                copy.cliente.pIVA = first.piva;
                copy.cliente.indirizzo = first.location;
                copy.cliente.telefono = first.phone;
                copy.cliente.email = first.email;
            }
            setFattura(copy);
        }
    }

    return (
        <div className="container-fluid mt-5">
            <div className='float-right'>
                <ButtonActionIcon icon={require("../../../img/pdflogo.png")} action={generatePDF} />
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <h5 className="card-title">Fornitore</h5>
                                    <div className="form-group">
                                        <SelectComponent
                                            value={GetAziendaValue(fattura.fornitore.aziendaId)}
                                            options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" } })}
                                            onChange={(e) => onChangeSelect("fornitore", e)}
                                            name={'aziendaId'}
                                            title={'Azienda'}
                                            isMulti={false}
                                            modal={{ modalType: "Azienda" }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="fornitoreNome">Nome:</label>
                                        <input type="text" className="form-control" id="fornitoreNome" value={fattura?.fornitore?.nome} onChange={(e) => handleInputChange(e, 'fornitore', 'nome')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fornitorePIVA">Partita IVA:</label>
                                        <input type="text" className="form-control" id="fornitorePIVA" value={fattura?.fornitore?.pIVA} onChange={(e) => handleInputChange(e, 'fornitore', 'pIVA')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fornitoreCF">Codice Fiscale:</label>
                                        <input type="text" className="form-control" id="fornitoreCF" value={fattura?.fornitore?.cF} onChange={(e) => handleInputChange(e, 'fornitore', 'cF')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fornitoreIndirizzo">Indirizzo:</label>
                                        <input type="text" className="form-control" id="fornitoreIndirizzo" value={fattura?.fornitore?.indirizzo} onChange={(e) => handleInputChange(e, 'fornitore', 'indirizzo')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fornitoreTelefono">Telefono:</label>
                                        <input type="text" className="form-control" id="fornitoreTelefono" value={fattura?.fornitore?.telefono} onChange={(e) => handleInputChange(e, 'fornitore', 'telefono')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fornitoreEmail">Email:</label>
                                        <input type="text" className="form-control" id="fornitoreEmail" value={fattura?.fornitore?.email} onChange={(e) => handleInputChange(e, 'fornitore', 'email')} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <h5 className="card-title">Cliente</h5>
                                    <div className="form-group">
                                        <SelectComponent
                                            value={GetAziendaValue(fattura?.cliente.aziendaId)}
                                            options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" } })}
                                            onChange={(e) => onChangeSelect("cliente", e)}
                                            name={'aziendaId'}
                                            title={'Azienda'}
                                            isMulti={false}
                                            modal={{ modalType: "Azienda" }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="clienteNome">Nome:</label>
                                        <input type="text" className="form-control" id="clienteNome" value={fattura?.cliente?.nome} onChange={(e) => handleInputChange(e, 'cliente', 'nome')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="clientePIVA">Partita IVA:</label>
                                        <input type="text" className="form-control" id="clientePIVA" value={fattura?.cliente?.pIVA} onChange={(e) => handleInputChange(e, 'cliente', 'pIVA')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="clienteCF">Codice Fiscale:</label>
                                        <input type="text" className="form-control" id="clienteCF" value={fattura?.cliente?.cF} onChange={(e) => handleInputChange(e, 'cliente', 'cF')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="clienteIndirizzo">Indirizzo:</label>
                                        <input type="text" className="form-control" id="clienteIndirizzo" value={fattura?.cliente?.indirizzo} onChange={(e) => handleInputChange(e, 'cliente', 'indirizzo')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fornitoreTelefono">Telefono:</label>
                                        <input type="text" className="form-control" id="fornitoreTelefono" value={fattura?.cliente?.telefono} onChange={(e) => handleInputChange(e, 'cliente', 'telefono')} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="fornitoreEmail">Email:</label>
                                        <input type="text" className="form-control" id="fornitoreEmail" value={fattura?.cliente?.email} onChange={(e) => handleInputChange(e, 'cliente', 'email')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <h5 className="card-title">Prodotti e Servizi</h5>
                            <table className=" table table-striped table-bordered table-bordered" style={{ border: "none" }}>
                                <thead>
                                    <tr>
                                        <th>Descrizione</th>
                                        <th>Quantità</th>
                                        <th>Prod X Colli</th>
                                        <th>Colli</th>
                                        <th>Prezzo</th>
                                        <th>Importo</th>
                                        <th>IVA</th>
                                        <th><ActionButtonIcon icon={require("../../../img/add.png")} action={() => addProduct()} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fattura?.prodottiEServizi.map((prodotto, index) => (
                                        <tr key={index}>
                                            <td><input type="text" className='form-control' value={prodotto.descrizione} onChange={(e) => handleInputChange(e, 'prodottiEServizi', 'descrizione', index)} /></td>
                                            <td><input type="number" className='form-control' value={prodotto.quantita} onChange={(e) => handleInputChange(e, 'prodottiEServizi', 'quantita', index)} /></td>
                                            <td><input type="number" min={1} className='form-control' value={prodotto.prodottoXColli} onChange={(e) => handleInputChange(e, 'prodottiEServizi', 'prodottoXColli', index)} /></td>
                                            <td><input type="number" className='form-control' value={prodotto.colli} onChange={(e) => handleInputChange(e, 'prodottiEServizi', 'colli', index)} /></td>

                                            <td>{formatPriceWithLocale(prodotto.importo)}</td>
                                            <td> 22% </td>
                                            <td><ActionButtonIcon icon={require("../../../img/delete.png")} action={() => removeProduct(index)} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6">
                            <h5 className="card-title">Riepilogo IVA</h5>
                            <table className="table table-sm table-responsive" style={{ border: "none" }}>
                                <thead>
                                    <tr>
                                        <th>Aliquota IVA</th>
                                        <th>Imponibile</th>
                                        <th>Imposta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>22%</td>
                                        <td>{formatPriceWithLocale(fattura?.riepilogoIVA.imponibile)}</td>
                                        <td>{formatPriceWithLocale(fattura?.riepilogoIVA.imposta)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="col-6">
                            <h5 className="card-title">Calcolo Fattura</h5>
                            <div>
                                <p>Imponibile prodotti e servizi: {formatPriceWithLocale(fattura?.calcoloFattura?.imponibileProdotti)}</p>
                                <p>Totale IVA: {formatPriceWithLocale(fattura?.calcoloFattura?.totaleIVA)}</p>
                                <p>Totale documento: {formatPriceWithLocale(fattura?.calcoloFattura?.totaleDocumento)}</p>
                                <p className="font-weight-bold">Netto a pagare: {formatPriceWithLocale(fattura?.calcoloFattura?.nettoAPagare)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default FatturaComponent;
