import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { MovimentazioneViewModel, Movimentazione_LOCAL, Movimentazione_FILTERS_LOCAL } from "../../../Model/MovimentazioneModel"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreMovimentazione = async (Movimentazionedata: MovimentazioneViewModel, fileName: string) => {
  let Movimentazione: MovimentazioneViewModel[] = await localforage.getItem(Movimentazione_LOCAL) ?? [];
  // Movimentazione.fileName = fileName;
  // Movimentazione.on = formatDateWithTime(new Date())?.toString() ?? "";
  Movimentazione.push(Movimentazionedata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Movimentazione.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Movimentazione_LOCAL, arrayUniqueByKey);
}

export const StoreMovimentazioneFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Movimentazione_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Movimentazione_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


