import {  CREATE_Proforma, ProformaCreate,EDIT_Proforma, GET_EDIT_Proforma } from "../../../Model/ProformaModels"

const initState: ProformaCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const ProformaEditReducer = (state = initState, action: { type: string, payload: ProformaCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case EDIT_Proforma:
            {

                return { ...state, resultDto }
            }
        case GET_EDIT_Proforma:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
