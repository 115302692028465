import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { RoleModel, Role_LOCAL, Role_FILTERS_LOCAL } from "../../../Model/RoleModels"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreRole = async (Roledata: RoleModel, fileName: string) => {
  let Role: RoleModel[] = await localforage.getItem(Role_LOCAL) ?? [];
  // Role.fileName = fileName;
  // Role.on = formatDateWithTime(new Date())?.toString() ?? "";
  Role.push(Roledata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Role.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Role_LOCAL, arrayUniqueByKey);
}

export const StoreRoleFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Role_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Role_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


