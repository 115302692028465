import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { MovimentazioneApi } from "../../../Business/MovimentazioneBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Movimentazione, MovimentazioneViewModel, EDIT_Movimentazione, MovimentazioneCreate,  GET_CREATE_Movimentazione } from "../../../Model/MovimentazioneModel";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetMovimentazioneCreateResource(id?: string) {
	setLoader("ADD", "GetMovimentazioneCreateResource");
	let api = new MovimentazioneApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<MovimentazioneViewModel>>>(() => api.MovimentazioneGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as MovimentazioneCreate;
	rootStore.dispatch({ type: GET_CREATE_Movimentazione, payload: rtn });
	setLoader("REMOVE", "GetMovimentazioneCreateResource");
	return rtn.dto;
}


export async function CreateMovimentazione(data: MovimentazioneViewModel) {
	setLoader("ADD", "CreateMovimentazione");
 	let api = new MovimentazioneApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.MovimentazioneCreate(data));
	let rtn = { dto: null, resultDto: result } as MovimentazioneCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Movimentazione, payload: rtn });
	setLoader("REMOVE", "CreateMovimentazione");
	return rtn;
}

export async function OcrMovimentazione(localFile: any, name: any,operation:string) {
	// setLoader("ADD", "OcrMovimentazione");
 	let api = new MovimentazioneApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.MovimentazioneOcr(localFile,name,operation));
 
	// setLoader("REMOVE", "OcrMovimentazione");
	return result;
}
