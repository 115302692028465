import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { CommonValidation } from "../../Business/Common/CommonBusiness";
import { ErrorType } from '../../Components/ErrorNotification';
import InputNumberComponent from "../../Components/FormAndFilters/InputNumberComponent";
import InputTextComponent from "../../Components/FormAndFilters/InputTextComponent";
import SelectComponent from "../../Components/FormAndFilters/SelectComponent";
import Notification from '../../Components/Notification';
import { useFormTableCrud } from "../../Hook/Grid/useFormTableCrud";
import { ProductInWarehouseViewModel } from "../../Model/ProductInWarehouse";
import { VariantViewModel } from "../../Model/VariantViewModel";
import { GetAziendaViewModel } from "../../Redux/Action/Azienda/AziendaGridAction";
import { GetProductSizeViewModel } from "../../Redux/Action/ProductSize/ProductSizeGridAction";
import { NotifyType } from '../../Redux/Reducer/NotificationReducer';
import { RootState } from "../../Redux/Store/rootStore";
import ProductInWarehouseComponent from "../Component/Warehouse/ProductInWarehouseComponent";
import ProductInWarehouse from "./ProductInWarehouse";

interface Props {
  action: {
    returnElement(data: VariantViewModel),
    cancel(canged: boolean): void
  }
  data: VariantViewModel | null,
}

const BarCode: React.FC<Props> = (props) => {

  const [isVisibleModal, setisVisibleModal] = useState(false);
  const [ProductInWarehouseEdit, setInWarehouseEdit] = useState(false);
  const [inWarehouseSelected, setInWarehouseSelected] = useState<ProductInWarehouseViewModel | null>(null);
  const [notifications, setNotifications] = useState<ErrorType[]>([])



  const { formData, setFormData, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<VariantViewModel>(

  );


  const addInWarehouse = (inWarehouse?: ProductInWarehouseViewModel) => {
    if (inWarehouse !== undefined) {
      setInWarehouseSelected(inWarehouse);
      setInWarehouseEdit(true);
    } else {
      setInWarehouseSelected(null);
      setInWarehouseEdit(false);
    }
    setisVisibleModal(true);
  }
  const removeInWarehouse = (inWarehouse: ProductInWarehouseViewModel) => {
    var copy = { ...formData };
    if (copy.inWarehouseViewModel == null) {
      copy.inWarehouseViewModel = [];
    }
    var codeIndex = copy.inWarehouseViewModel.findIndex(x => x.id === inWarehouse.id);
    if (codeIndex > -1)
      copy.inWarehouseViewModel.splice(codeIndex, 1);
    setFormData(copy as VariantViewModel);
  }

  const Aziende = (state: RootState) => state.AziendaGridReducer.gridResult;
  let AziendeResource = useSelector(Aziende);


  const ProductSize = (state: RootState) => state.ProductSizeGridReducer.gridResult;
  let ProductSizeResource = useSelector(ProductSize);


  const validazioneClient = (formData: VariantViewModel | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };
   

    if (formData?.aziendaId)
      addInvalidProperty("aziendaId");

    setValidation(copyValidation);
    return copyValidation;

  }
  const returnElement = () => {
    var messages: ErrorType[] = [...notifications]
 
    var valid = true;
     
    if (formData?.aziendaId === undefined){
      valid = false;
      messages.push({ message: `Azienda mancante`, notifyType: NotifyType.error });

    }
    if (formData?.sizeId === undefined){
      valid = false;
      messages.push({ message: `Formato mancante`, notifyType: NotifyType.error });

    }

    if (valid) {
      formData && props.action.returnElement(formData);
    }
    setNotifications(messages);
  }


  useEffect(() => {
    if (props.data != null) {
      setFormData(props.data);
    } else {
      setFormData({} as VariantViewModel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])


  useEffect(() => {
    GetAziendaViewModel({});
    GetProductSizeViewModel({});

  }, [])




  function GetAziendaValue(): any | undefined {
    let data = AziendeResource?.items?.filter(x => x.id === formData?.aziendaId);
    if (data != null && data?.length > 0) {
      return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
    }
    return undefined;
  }
  function GetSizeValue(): any | undefined {
    let data = ProductSizeResource?.items?.filter(x => x.id === formData?.sizeId);
    if (data != null && data?.length > 0) {
      return ({ value: data[0].id ?? "", label: data[0].name ?? "" })
    }
    return undefined;
  }

  function SetSizeSelect(e: any): void {
    var copy = { ...formData } as VariantViewModel;
    copy.sizeId = e.value;
    copy.size = e.label;
    setFormData(copy);
  }
  function SetAziendaSelect(e: any): void {
    var copy = { ...formData } as VariantViewModel;
    copy.aziendaId = e.value;
    copy.azienda = e.label;
    setFormData(copy);
  }
  const closeModal = () => {
    setisVisibleModal(false);
  }
  const returnElementProductInWarehouse = (data: ProductInWarehouseViewModel) => {
    var copy = { ...formData as VariantViewModel };

    copy.sellValue= copy.sellValue ?? 0
    copy.buyValue= copy.buyValue ?? 0

    if (copy.inWarehouseViewModel == null) {
      copy.inWarehouseViewModel = [];
    }
    if (ProductInWarehouseEdit) {
      var codeIndex = copy.inWarehouseViewModel.findIndex(x => x.id === data.id);
      if (codeIndex > -1)
        copy.inWarehouseViewModel.splice(codeIndex, 1);
    }
    copy.inWarehouseViewModel.push(data);

    setFormData(copy);
    setisVisibleModal(false);
  }
  const cancelProductInWarehouse = (data) => {
    setisVisibleModal(false);

  }
  return (
    <form id="form" className='w-100' onChange={() => setChanged(true)}>
          <div className='row' style={{
                position: "fixed",
                right: "0",
                top: "15px",
                minHeight: "100px",
                zIndex: 10000
            }}> {notifications && notifications.map((x, i) =>
                <div className='col-12 offset-lg-8 col-md-4' key={i + "containerNotification"} >
                    <Notification message={x.message} notifyType={x.notifyType} key={i + "not"} isOpen={true} OnModal={false} />
                </div>
            )}
            </div>
      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>ProductInWarehouse</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {isVisibleModal ?
            <ProductInWarehouse action={{ returnElement: returnElementProductInWarehouse, cancel: cancelProductInWarehouse }} data={inWarehouseSelected} />
            : <></>}</Modal.Body>
      </Modal>



      <div className="row mx-0 col-12 px-0">
        <div className="col-12 col-md-6">
          <InputTextComponent onChange={onChange} value={formData?.code} name={'code'} title={'codice a barre'} type={'text'} />
        </div>
        {/* <div className="col-12 col-md-6">
          <InputNumberComponent disabled={true} readOnly={true} onChange={onChange} value={formData?.quantita} name={'quantita'} title={'quantita'} />
        </div> */}
        <div className="col-12 col-md-6">
          <InputNumberComponent onChange={onChange} value={formData?.prodottiXCollo ?? 1} name={'prodottiXCollo'} title={'prodottiXCollo'} />
        </div>


        <div className="col-12 col-md-6">
          <InputNumberComponent onChange={onChange} value={formData?.sellValue ?? 0} name={'sellValue'} title={'Prezzo vendita'} step=".01" validation={validation} validationMessage={'Prezzo vendita obbligatorio'} />

        </div>
        <div className="col-12 col-md-6">
          <InputNumberComponent onChange={onChange} value={formData?.buyValue ?? 0} name={'buyValue'} title={'Prezzo Acquisto'} step=".01" validation={validation} validationMessage={'Prezzo Acquisto obbligatorio'} />
        </div>



        <div className="col-12 col-md-6">
          <SelectComponent
            value={GetAziendaValue()}
            options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" } })}
            onChange={(e) => SetAziendaSelect(e)}
            name={'aziendaId'}
            title={'Azienda'}
            isMulti={false}
            modal={{ modalType: "Azienda" }}
            validation={validation}
            validationMessage={'Azienda obbligatorio'}
          />
        </div>

        <div className="col-12 col-md-6">
          <SelectComponent
            value={GetSizeValue()}
            options={ProductSizeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" } })}
            onChange={(e) => SetSizeSelect(e)}
            name={'sizeId'}
            title={'Formato'}
            isMulti={false}
            modal={{ modalType: "ProductSize" }}

          />
        </div>
      </div>
      <hr />
      <div className=' mx-0 col-12 p-0 d-flex justify-content-center row w-100'>
        <h4>Magazzino <button className='btn btnOutline' type='button' onClick={() => addInWarehouse()}>Add</button></h4>
        <div className='col-12 row'>
          {formData?.inWarehouseViewModel?.map((x, i) => (
            <ProductInWarehouseComponent key={"variant" + i} model={x} action={{
              addInWarehouse, removeInWarehouse
            }} />

          ))}
        </div>
      </div>
      <hr />
      <div className="col-12 justify-content-end mt-4 d-flex footerModal">
        <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.cancel && props.action.cancel(changed)} type="button">
          ANNULLA
        </button>
        <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => returnElement()} type="button">
        Aggiungi
        </button>
      </div>
    </form>
  );
}

export default BarCode;