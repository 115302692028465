import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_ProductSize_GRID, ProductSizeViewModelGrid, ProductSizeViewModelQuery, ProductSizeViewModel, ProductSize_GRIDRENDER, ADD_ProductSize_GRID, GET_ProductSize_FILTERS, ProductSizeQueryResultDto } from '../../../Model/ProductSizeModel';
import { FilterValueDto, QueryResultDto, ResultDto } from '../../../Model/CommonModel';
import { ProductSizeApi, ProductSizeApiOffline } from "../../../Business/ProductSizeBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreProductSizeFilters } from './ProductSizeStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetProductSizeViewModel = async (tableQuery: ProductSizeViewModelQuery) => {
    setLoader("ADD", "GetProductSizeViewModel");
    let api = navigator.onLine ? new ProductSizeApi():new ProductSizeApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.ProductSizeGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,ProductSize_GRIDRENDER);
    rootStore.dispatch({ type: GET_ProductSize_GRID, payload: { gridResult: result?.data, filter: null } as ProductSizeViewModelGrid });
    setLoader("REMOVE", "GetProductSizeViewModel");

}
export const GetAddResultToGrid = async (tableQuery: ProductSizeViewModelQuery, dataToConcat: ProductSizeViewModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new ProductSizeApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.ProductSizeGetGrid(tableQuery));
    if (data && !arrayIsNullOrEmpty(data.data.items) && data.data.items !== undefined) {
        let arr = dataToConcat?.concat(data.data.items as any[])
        // data.totalItems = data.totalItems;
        data.data.items = arr;
    }
    rootStore.dispatch({ type: ADD_ProductSize_GRID, payload: { gridResult: data?.data, filter: null } as ProductSizeViewModelGrid });
    return data?.data.items
}


export async function GetFilterColumnProductSizeViewModel(columName: string, columValue: string, queryFilter?: ProductSizeViewModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new ProductSizeApi(): new ProductSizeApiOffline();
    // setLoader("ADD", "GetFilterColumnProductSizeViewModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.ProductSizeGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.ProductSizeGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_ProductSize_FILTERS, payload: { gridResult: null, filter: result?.data } as ProductSizeViewModelGrid });

    result && StoreProductSizeFilters(result.data ?? [],columName);
    return result
}


