import { EDIT_Vehicle, GET_Vehicle_DETAIL, VehicleModelDetail } from '../../../Model/VehicleModels';

const initState: VehicleModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const VehicleDetailReducer = (state = initState, action: { type: string, payload: VehicleModelDetail }) => {
    switch (action.type) {
        case GET_Vehicle_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Vehicle:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
