import React from 'react';
import FiltersSelect from '../../Components/FormAndFilters/FiltersAutogen/FiltersSelect';
import { QueryObject } from '../../Model/CommonModel';
import { FilterResource } from './useFilterCustom';
import FiltersBooleanDoubleRadio from '../../Components/FormAndFilters/FiltersAutogen/FiltersBooleanDoubleRadio';


export function SelectFilterType(type: number, selectAction: any, booleanRadioFilterAction: any, propertyName: string, propertyText: string, filtersSelected: QueryObject | undefined, filterResource: FilterResource[]) {
    switch (type) {
        case 1 :
             let resourceSelect = filterResource.find(x => x.propertyName === propertyName)?.filterList;
             let selectedRow = filtersSelected && filtersSelected[propertyName] as (string | number)[] | undefined
            return (
                <FiltersSelect
                    key={`${propertyName}`}
                    action={selectAction}
                    data={{ property: propertyName, selected: selectedRow, options: resourceSelect ?? [] }}
                    settings={{ label: propertyText }}>
                </FiltersSelect>
            )
        case 7:
            let selectedValueBoolean = filtersSelected && filtersSelected[propertyName] as boolean | undefined
            return (
                <FiltersBooleanDoubleRadio
                    key={`${propertyName}`}
                    action={booleanRadioFilterAction}
                    data={{ property: propertyName, value: selectedValueBoolean }}
                    settings={{
                        label: propertyText,
                        overrideLabels: propertyName === "offline" ? ["Offline", "Online"] : undefined,
                        hiddenFilter: propertyName === "offline" ? !navigator.onLine : false
                    }}>
                </FiltersBooleanDoubleRadio>
            )
        default:
            return
    }
}
