import React, { SetStateAction } from 'react';
import '../../Css/Table.css'

interface Props {
    propertyName: string,
    spanClassName?: string,
    overridePropertyName?: string,
    hideFilter?: boolean,
    hideHeader?: boolean,
    thAction: {
        orderBy(property: string, isAscending: boolean): any,
    }
    isAscending: boolean | undefined
    propertyInOrder: string | undefined
}

const TableCrudTh: React.FC<Props> = (props) => {

    const propertyName = props.overridePropertyName ?? props.propertyName;

    return (
        <th className="gridHead w-100 px-0" key={props.propertyName}>
            <div className="row mx-0 d-flex align-content-start">
                <div className="px-2 w-100 d-flex align-items-start flex-row justify-content-between labelTh align-content-start">
                    {props.hideHeader == false ?
                        <div className="d-flex align-content-start flex-row headerTable text-nowrap">
                            <label className={props.spanClassName}>{propertyName}</label>
                            <button type="button" className={`btnIcon btnOrder ${props.propertyInOrder == props.propertyName ? "active" : ""}`} onClick={() => props.thAction.orderBy(props.propertyName, props.propertyInOrder == props.propertyName ? !props.isAscending : true)}><img src={require(`../../svg/arrow_${props.propertyName && props.isAscending && props.propertyInOrder == props.propertyName ? "up" : "down"}_Gray.png`)}></img></button>
                        </div>
                        : null}

                </div>
                <div className="w-100 filterSpace px-1">
                    {props.hideFilter ? null : props.children}
                </div>

            </div>
        </th>
    )
}
export default TableCrudTh;