
import { CustomGridRender, FilterValueDto, QueryResultDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { Azienda_GRIDRENDER, Azienda_LOCAL, AziendaViewModelResource, AziendaViewModelQuery, Azienda_FILTERS_LOCAL } from '../Model/Azienda';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class AziendaApi {

  aziendaExportReport = async (model: AziendaViewModelQuery): Promise<ReturnFile> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Azienda/Export";
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  AziendaGetGrid = async (tableQuery: AziendaViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "Azienda/Get", options);
    var res = await response.json();
    return res;
  }
  AziendaCreate = async (model: AziendaViewModelResource): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(model)
    };
    let url = basePath + "Azienda";
    let response = await fetch(url, options);
    return await response.json();
  }

  AziendaGetResource = async (id?: string): Promise<ResultDto<AziendaViewModelResource>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Azienda/Resource";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  AziendaDelete = async (id?: string): Promise<ResultDto> => {
    const options = {
      method: "DELETE",
      headers: await GetHeader(),
    };
    let url = basePath + "Azienda";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  AziendaEdit = async (model: AziendaViewModelResource): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
      body: JSON.stringify(model)
    };
    let url = basePath + "Azienda";
    let response = await fetch(url, options);
    return await response.json();
  }


  AziendaDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Azienda/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }


  AziendaGetFilters = async (columName: string, columValue?: string, queryFilter?: AziendaViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "Azienda/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json()) as ResultDto<FilterValueDto[]>;
  }
}


export class AziendaApiOffline {
  AziendaGetGrid = async (tableQuery: AziendaViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    let items: AziendaViewModelResource[] | null = await localforage.getItem(Azienda_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(Azienda_GRIDRENDER);
    //todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.AziendaCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.AziendaType?.forEach(s => {
    //   items = items?.filter(x => x.AziendaType === s) ?? []
    // });
    let res: ResultDto<QueryResultDto> = { data: { gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length } }
    return res;
  }

  AziendaDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Azienda/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  AziendaGetFilters = async (columName: string, columValue?: string, queryFilter?: AziendaViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    let items: ResultDto<FilterValueDto[]> = { data: await localforage.getItem(Azienda_FILTERS_LOCAL + columName) ?? [] };
    return items;
  }
}

