import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";  // Assicurati di importare lo stile se necessario

interface DatePickerProps {
  value: Date | null | undefined;
  onChange: (date: string, event: any) => void;
  name: string;
  title: string;
  validation?: any;
  validationMessage?: string;
}
function convertToDate(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return null;
  return date;
}
const DatePickerComponent: React.FC<DatePickerProps> = (props) => {
  const handleChange = (date) => {

    console.log(date);

    // Creare un oggetto evento fittizio
    const fakeEvent = {
      currentTarget: {
        type: "date",
        value: date,
      },
    };
    // Chiamare props.onChange con l'oggetto evento fittizio
    props.onChange(props.name, fakeEvent);
  };
  return (
    <div className="form-group col-12 pl-0">
      <label className="labelForm voda-bold text-uppercase w-100">
        {props.title ?? props.name ?? ""}{props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? "*" : ""}
        <DatePicker
          selected={convertToDate(props.value)}
          onChange={handleChange}
          className="inputForm w-100 form-control"
          minDate={new Date(1980, 0, 1)}
          maxDate={new Date(2999, 0, 1)}
          dateFormat="dd/MM/yyyy"
          placeholderText={"NOT SPECIFIED"}
        />
      </label>
      {props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? (
        <label className="validation">{props.validationMessage}</label>
      ) : null}
    </div>
  );
};

export default DatePickerComponent;
