import React, { useEffect, useRef } from 'react';

interface MappaConMarkerTrascinabileProps {
  indirizzoIniziale?: string;
  onIndirizzoCambiato?: (nuovoIndirizzo: string) => void;
}

const MappaConMarkerTrascinabile: React.FC<MappaConMarkerTrascinabileProps> = ({ indirizzoIniziale, onIndirizzoCambiato }) => {
  const mapContainerRef = useRef(null); // Aggiungo un ref per l'elemento contenitore della mappa

  useEffect(() => {
    if (!mapContainerRef.current) return; // Assicurati che il contenitore della mappa sia montato

    const geocoder = new google.maps.Geocoder();
    const googleMap = new google.maps.Map(mapContainerRef.current, {
      center: new google.maps.LatLng(41.9028, 12.4964),
      zoom: 14,
    });

    const mapMarker = new google.maps.Marker({
      position: googleMap.getCenter(),
      map: googleMap,
      draggable: true,
    });

    mapMarker.addListener('dragend', function(event) {
      const newPos = event.latLng;
      geocoder.geocode({ 'location': newPos }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          const nuovoIndirizzo = results[0].formatted_address;
           onIndirizzoCambiato?.(nuovoIndirizzo);
        }
      });
    });

    if (indirizzoIniziale) {
      geocoder.geocode({ 'address': indirizzoIniziale }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          const posizione = results[0].geometry.location;
          googleMap.setCenter(posizione);
          mapMarker.setPosition(posizione);
        }
      });
    }
  }, [indirizzoIniziale, onIndirizzoCambiato]);

  return (
    <div ref={mapContainerRef} id="map" style={{ width: '100%', height: '400px' }} />
  );
};

export default MappaConMarkerTrascinabile;
