import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_Vehicle_GRID, VehicleViewModelGrid, VehicleViewModelQuery, VehicleViewModel, Vehicle_GRIDRENDER, ADD_Vehicle_GRID, GET_Vehicle_FILTERS, VehicleQueryResultDto } from '../../../Model/VehicleModels';
import { FilterValueDto, QueryResultDto, ResultDto } from '../../../Model/CommonModel';
import { VehicleApi, VehicleApiOffline } from "../../../Business/VehicleBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreVehicleFilters } from './VehicleStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetVehicleViewModel = async (tableQuery: VehicleViewModelQuery) => {
    setLoader("ADD", "GetVehicleViewModel");
    let api = navigator.onLine ? new VehicleApi():new VehicleApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.VehicleGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,Vehicle_GRIDRENDER);
    rootStore.dispatch({ type: GET_Vehicle_GRID, payload: { gridResult: result?.data, filter: null } as VehicleViewModelGrid });
    setLoader("REMOVE", "GetVehicleViewModel");

}
export const GetAddResultToGrid = async (tableQuery: VehicleViewModelQuery, dataToConcat: VehicleViewModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new VehicleApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.VehicleGetGrid(tableQuery));

    rootStore.dispatch({ type: ADD_Vehicle_GRID, payload: { gridResult: data?.data, filter: null } as VehicleViewModelGrid });
    return data?.data.items
}


export async function GetFilterColumnVehicleViewModel(columName: string, columValue: string, queryFilter?: VehicleViewModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new VehicleApi(): new VehicleApiOffline();
    // setLoader("ADD", "GetFilterColumnVehicleViewModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.VehicleGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.VehicleGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_Vehicle_FILTERS, payload: { gridResult: null, filter: result?.data } as VehicleViewModelGrid });

    result && StoreVehicleFilters(result.data ?? [],columName);
    return result
}


