import React, { useState } from 'react'
import { formatPriceWithLocale } from '../../../Business/Common/CommonBusiness'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import Icon from '../../../Components/Various/Icon'
import { MovimentazioneProductVariantViewModel } from '../../../Model/MovimentazioneModel'
import ProductInWarehouseComponent from '../Warehouse/ProductInWarehouseComponent'

interface Props {
  action?: {
    removeVariant?(data: MovimentazioneProductVariantViewModel): any | void,
    addProduct?(data: any): any | void,
    editProduct?(data: any): any | void,

  }
  model: MovimentazioneProductVariantViewModel,
  productName?: string,
  actionGridOverride?: (x?: any) => any;
  showWarehouse?: boolean
}
const MovimentazioneVariant: React.FC<Props> = (props) => {

  const [showWarehouse, setShowWarehouse] = useState(false)
  const toggleWarehouseView = () => {
    setShowWarehouse(!showWarehouse);
  };

  return (
    <div className='col-12   mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left  '><Icon icon={require("../../../img/bottle-wine.png")} height={30} /><label style={{ fontSize: "20pt" }}>{props.productName}</label>            </div>
          <div className='float-right '>
            {props.action && props.action.removeVariant && <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeVariant && props.action.removeVariant(props.model)} />}
            {props.action && props.action.addProduct && <ButtonActionIcon icon={require("../../../img/add.png")} height={15} action={() => props.action && props.action.addProduct && props.action.addProduct(props.model)} />}
            {props.action && props.action.editProduct && <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.editProduct && props.action.editProduct(props.model)} />}

          </div>

        </div>
        <div className='card-body'>
          <div className='row'>

            <div className='col-12 col-md-6'>{props.model.productVariant.code ? <label ><Icon icon={require("../../../img/barcode.png")} /> {props.model.productVariant.code}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.productVariant.quantita ? <label ><Icon icon={require("../../../img/inventory_black.png")} /> {props.model.productVariant.quantita}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.productVariant.sellValue ? <label ><Icon icon={require("../../../img/euro.png")} />prezzo vendita: {formatPriceWithLocale(props.model.productVariant.sellValue)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.productVariant.buyValue ? <label ><Icon icon={require("../../../img/euro.png")} />prezzo acquisto: {formatPriceWithLocale(props.model.productVariant.buyValue)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.productVariant.size ? <label ><Icon icon={require("../../../img/outline_grade_black_24dp.png")} />  {props.model.productVariant.size}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.productVariant.azienda ? <label ><Icon icon={require("../../../img/store.png")} /> {props.model.productVariant.azienda} </label> : null} </div>
            {props.children}

            {props.model.productVariant && props.model.productVariant.inWarehouseViewModelDbHistory && props.model.productVariant && props.model.productVariant.inWarehouseViewModelDbHistory.length > 0 &&
              <>
                <div className='d-flex justify-content-end col-12 mt-2'>
                  <ButtonActionIconGoogleIcon action={toggleWarehouseView} icon={showWarehouse ? 'visibility' : 'visibility_off'} >
                    <label>{showWarehouse ? "Nascondi" : "Mostra"} Magazzino</label>
                  </ButtonActionIconGoogleIcon>
                </div>


                {showWarehouse && props.showWarehouse === true ? <div className='row'>
                  {props.model.productVariant && props.model.productVariant.inWarehouseViewModelDbHistory?.map(x =>
                    <div className='col-12' key={x.id}>
                      <ProductInWarehouseComponent model={x}></ProductInWarehouseComponent>
                    </div>
                  )}
                </div> : <></>}</>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MovimentazioneVariant