import { CustomGridRender, DateFilter, FilterValueDto, QueryObject, RenderDetail, ResultDto } from "./CommonModel";

export interface WarehouseViewModelQuery extends QueryObject {
    name: string[];
    location: string[];
    description: string[];
    isActive: boolean | null;
    dateOpened: DateFilter | null;
    dateClosed: DateFilter | null;
}
export interface WarehouseViewModelResource extends WarehouseViewModel {

}
export interface WarehouseViewModel {
    id: string;
    name: string;
    location: string;
    description: string;
    isActive: boolean;
    dateOpened: Date | null;
    dateClosed: Date | null;
}
/**
 * 
 * @export
 * @interface WarehouseViewModelCustomGridRender
 */
export interface WarehouseViewModelCustomGridRender {
    /**
     * 
     * @type {Array<RenderDetail>}
     * @memberof WarehouseViewModelCustomGridRender
     */
    render?: Array<RenderDetail>;
}

/**
 * 
 * @export
 * @interface WarehouseViewModelQueryResultResponse
 */
export interface WarehouseViewModelQueryResultResponse {
    /**
     * 
     * @type {number}
     * @memberof WarehouseViewModelQueryResultResponse
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<WarehouseViewModel>}
     * @memberof WarehouseViewModelQueryResultResponse
     */
    items?: Array<WarehouseViewModel>;
    /**
     * 
     * @type {WarehouseViewModelCustomGridRender}
     * @memberof WarehouseViewModelQueryResultResponse
     */
    gridRender?: WarehouseViewModelCustomGridRender;
}
/**
 * 
 * @export
 * @interface WarehouseViewModelQueryResultResponseResponseResult
 */
export interface WarehouseViewModelQueryResultResponseResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseViewModelQueryResultResponseResponseResult
     */
    warning?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WarehouseViewModelQueryResultResponseResponseResult
     */
    info?: string;
    /**
     * 
     * @type {WarehouseViewModelQueryResultResponse}
     * @memberof WarehouseViewModelQueryResultResponseResponseResult
     */
    data?: WarehouseViewModelQueryResultResponse;
}

/**
 * 
 * @export
 * @interface WarehouseViewModelResourceResponseResult
 */
export interface WarehouseViewModelResourceResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseViewModelResourceResponseResult
     */
    warning?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WarehouseViewModelResourceResponseResult
     */
    info?: string;
    /**
     * 
     * @type {WarehouseViewModelResource}
     * @memberof WarehouseViewModelResourceResponseResult
     */
    data?: WarehouseViewModelResource;
}


export interface WarehouseViewModelGrid {
    gridResult: WarehouseListQueryResultDto | null
    filter: FilterValueDto[] | null
}


export interface WarehouseModelDetail {
    dtoEdit: WarehouseViewModelResource | null,
    resultDto: ResultDto<null> | null,
}

export interface WarehouseCreate {
    dto: WarehouseViewModelResource | null,
    resultDto: ResultDto<null> | null,
}
export interface WarehouseEdit extends WarehouseCreate {

}
export interface WarehouseModelDownloaded {
    dtoDownload: number[],
}

export interface WarehouseListQueryResultDto {
    /**
     *
     * @type {number}
     * @memberof WarehouseListQueryResultDto
     */
    totalItems?: number;
    /**
     *
     * @type {Array<WarehouseList>}
     * @memberof WarehouseListQueryResultDto
     */
    items?: Array<WarehouseViewModel>;
    /**
     *
     * @type {CustomGridRender}
     * @memberof WarehouseListQueryResultDto
     */
    gridRender?: CustomGridRender;
}
export interface WarehouseQueryResultDto {
    /**
     * 
     * @type {number}
     * @memberof WarehouseViewModelQueryResultResponse
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<WarehouseViewModel>}
     * @memberof WarehouseViewModelQueryResultResponse
     */
    items?: Array<WarehouseViewModel>;
    /**
     * 
     * @type {WarehouseViewModelCustomGridRender}
     * @memberof WarehouseViewModelQueryResultResponse
     */
    gridRender?: RenderDetail[];
}


export const GET_Warehouse_GRID = "GET_Warehouse_GRID"
export const ADD_Warehouse_GRID = "ADD_Warehouse_GRID"
export const GET_Warehouse_FILTERS = "GET_Warehouse_FILTERS"
export const GET_Warehouse_DETAIL = "GET_Warehouse_DETAIL"
export const GET_EDIT_Warehouse = "GET_EDIT_Warehouse"
export const EDIT_Warehouse = "EDIT_Warehouse"
export const CREATE_Warehouse = "CREATE_Warehouse"
export const GET_CREATE_Warehouse = "GET_CREATE_Warehouse"
export const DELETE_Warehouse = "DELETE_Warehouse"


//#region offline definition

export const Warehouse_LOCAL = "Warehouse_LOCAL"
export const Warehouse_FILTERS_LOCAL = "Warehouse_FILTERS_LOCAL"

export const Warehouse_GRIDRENDER = "Warehouse_GRIDRENDER"

//#endregion
