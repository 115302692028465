import { DataLayout, GET_DATA_LAYOUT, WindowSizeData } from '../../Model/LayoutModel';
import { rootStore } from '../../Redux/Store/rootStore';


export const onResize = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let breakpoint = ""
    let isMobile = false;

    let w = width

    if (w < 576) {
        breakpoint = "xs"
        isMobile = true;

    } else if (w >= 576 && w < 768) {
        breakpoint = "sm"
        isMobile = true;

    } else if (w >= 768 && w < 992) {
        breakpoint = "md"
        isMobile = true;

    } else if (w >= 992 && w < 1200) {
        breakpoint = "lg"
        isMobile = true;

    } else if (w >= 1200 && w < 1500) {
        breakpoint = "xl"
        isMobile = true;

    } else if (w >= 1500) {
        breakpoint = "xxl"
        isMobile = false;
    }
    let rtn = { windowSize: { height: height, width: width } as WindowSizeData, breakpoint: breakpoint, isMobile: isMobile } as DataLayout
    rootStore.dispatch({ type: GET_DATA_LAYOUT, payload: rtn });
}