import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ProductSizeApi } from "../../../Business/ProductSizeBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_ProductSize, ProductSizeViewModel, EDIT_ProductSize, ProductSizeCreate,  GET_CREATE_ProductSize } from "../../../Model/ProductSizeModel";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetProductSizeCreateResource(id?: string) {
	setLoader("ADD", "GetProductSizeCreateResource");
	let api = new ProductSizeApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<ProductSizeViewModel>>>(() => api.ProductSizeGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as ProductSizeCreate;
	rootStore.dispatch({ type: GET_CREATE_ProductSize, payload: rtn });
	setLoader("REMOVE", "GetProductSizeCreateResource");
	return rtn.dto;
}


export async function CreateProductSize(data: ProductSizeViewModel) {
	setLoader("ADD", "CreateProductSize");
	let api = new ProductSizeApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProductSizeCreate(data));
	let rtn = { dto: null, resultDto: result } as ProductSizeCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_ProductSize, payload: rtn });
	setLoader("REMOVE", "CreateProductSize");
	return rtn;
}
