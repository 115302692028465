import { EDIT_Proforma, GET_Proforma_DETAIL, ProformaModelDetail } from '../../../Model/ProformaModels';

const initState: ProformaModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const ProformaDetailReducer = (state = initState, action: { type: string, payload: ProformaModelDetail }) => {
    switch (action.type) {
        case GET_Proforma_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Proforma:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
