import React, { useState } from 'react';
import { GetHeader, basePath } from '../../../Business/Common/configuration';
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon';
import '../../../Css/OrdineDiCarico.css';
import { DettaglioOrdine, IntestazioneOrdine, OrdineDiCaricoViewModel } from '../../../Model/TripModels';

const OrdineDiCarico: React.FC<{ model: OrdineDiCaricoViewModel }> = ({ model }) => {
    // Stati locali per l'intestazione e i dettagli
    const [intestazione, setIntestazione] = useState(model.intestazione);
    const [dettaglistate, setDettagli] = useState(model.dettagli);

    // Gestione dei cambiamenti nell'intestazione
    const handleHeaderChange = (field: keyof IntestazioneOrdine, value: string | boolean) => {
        setIntestazione({ ...intestazione, [field]: value });
    };

    // Gestione dei cambiamenti nei dettagli
    const handleDetailChange = (index: number, field: keyof DettaglioOrdine, value: string | number) => {
        const updatedDetails = [...dettaglistate];
        const updatedDetail = { ...updatedDetails[index], [field]: value };
        updatedDetails[index] = updatedDetail;
        setDettagli(updatedDetails);
    };
    // Funzione per inviare i dati al server e generare un Excel
    const generateExcel = async () => {
        try {
            const parsedDettagli = dettaglistate.map(dettaglio => ({
                ...dettaglio,
                colli: parseInt(dettaglio.colli.toString()) || 0
            }));
    
            const response = await fetch(basePath + 'trip/excel', {
                method: 'POST',
                headers: await GetHeader(),
                body: JSON.stringify({ intestazione, dettagli: parsedDettagli }),
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'OrdineDiCarico.xlsx';
                link.click();
                window.URL.revokeObjectURL(downloadUrl);
            } else {
                console.error('Server response was not ok.');
            }
        } catch (error) {
            console.error('There was an error sending the request:', error);
        }
    };

    // Funzione per inviare i dati al server e generare un PDF
    const generatePDF = async () => {
        try {
            const dettagli = dettaglistate.map((x: DettaglioOrdine) => {
                return {
                    quantita: x.quantita,
                    tipo: x.tipo,
                    colli: Math.round(x.colli ?? 1),
                } as DettaglioOrdine
            })

            const response = await fetch(basePath + 'trip/pdf', {
                method: 'POST',
                headers: await GetHeader(),
                body: JSON.stringify({ intestazione, dettagli }),
            });
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'OrdineDiCarico.pdf';
                link.click();
                window.URL.revokeObjectURL(downloadUrl);
            } else {
                console.error('Server response was not ok.');
            }
        } catch (error) {
            console.error('There was an error sending the request:', error);
        }
    };
    return (
        <div className="ordine-di-carico-container">
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Fornitore</th>
                            <th>Carico</th>
                            <th>Destinazone</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    type="text" className='form-control'

                                    value={intestazione.fornitore}
                                    onChange={(e) => handleHeaderChange('fornitore', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text" className='form-control'

                                    value={intestazione.carico}
                                    onChange={(e) => handleHeaderChange('carico', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text" className='form-control'

                                    value={intestazione.destinazione}
                                    onChange={(e) => handleHeaderChange('destinazione', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="date" className='form-control'

                                    value={intestazione.data}
                                    onChange={(e) => handleHeaderChange('data', e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>Targa</th>
                            <th>Trasportatore</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    type="text" className='form-control'

                                    value={intestazione.targa}
                                    onChange={(e) => handleHeaderChange('targa', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text" className='form-control'

                                    value={intestazione.trasportatore}
                                    onChange={(e) => handleHeaderChange('trasportatore', e.target.value)}
                                />
                            </td>

                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>Quantità</th>
                            <th>Tipo</th>
                            <th>Colli</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dettaglistate.map((dettaglio, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="number" className='form-control'

                                        value={dettaglio.quantita}
                                        onChange={(e) => handleDetailChange(index, 'quantita', parseInt(e.target.value) || 0)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text" className='form-control'

                                        value={dettaglio.tipo}
                                        onChange={(e) => handleDetailChange(index, 'tipo', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number" className='form-control'

                                        value={Math.round(dettaglio.colli)}
                                        onChange={(e) => handleDetailChange(index, 'colli', parseInt(e.target.value) || 0)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="footer">
                <div className='row'>
                    <div className='col-6'>
                        Totale Colli: {Math.round(dettaglistate.reduce((sum, current) => sum + current.colli, 0))}
                    </div>
                    <div className='col-2'>
                        <ButtonActionIcon icon={require("../../../img/excel_black.png")} action={generateExcel} />
                    </div>
                    <div className='col-2'>
                        <ButtonActionIcon icon={require("../../../img/pdflogo.png")} action={generatePDF} />
                    </div>
                    <div className='col-2'>
                        <input
                            type="checkbox"
                            className='form-control'
                            checked={intestazione.ignoraLogo}
                            onChange={(e) => handleHeaderChange('ignoraLogo', e.target.checked)}
                        />
                        Ignora Logo
                    </div>
                </div>


            </div>
        </div>
    );
};

export default OrdineDiCarico;
