import { EDIT_Trip, GET_Trip_DETAIL, TripModelDetail } from '../../../Model/TripModels';

const initState: TripModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const TripDetailReducer = (state = initState, action: { type: string, payload: TripModelDetail }) => {
    switch (action.type) {
        case GET_Trip_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Trip:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
