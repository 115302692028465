import {  CREATE_Movimentazione, MovimentazioneCreate,EDIT_Movimentazione, GET_EDIT_Movimentazione } from "../../../Model/MovimentazioneModel"

const initState: MovimentazioneCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const MovimentazioneEditReducer = (state = initState, action: { type: string, payload: MovimentazioneCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case EDIT_Movimentazione:
            {

                return { ...state, resultDto }
            }
        case GET_EDIT_Movimentazione:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
