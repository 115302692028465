
import { CustomGridRender, FilterValueDto, QueryResultDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { Role_GRIDRENDER, Role_LOCAL,  RoleModel, RoleModelQuery,  Role_FILTERS_LOCAL,RoleModelResource } from '../Model/RoleModels';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class RoleApi {
  RoleGetGrid = async (tableQuery: RoleModelQuery): Promise<ResultDto<QueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "RoleManage/Get", options);
    var res = await response.json();
    return res;
  }
  RoleCreate = async (model: RoleModel): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "RoleManage";
    let response = await fetch(url, options);
    return await response.json();
  }

  RoleGetResource = async (id?:string): Promise<ResultDto<RoleModelResource>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "RoleManage/Resource";
    if(id !== undefined)
    url += "?id="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }


  RoleEdit = async (model: RoleModelResource): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "RoleManage";
    let response = await fetch(url, options);
    return await response.json();
  }


  RoleDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "RoleManage/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }


  RoleGetFilters = async (columName: string, columValue?: string, queryFilter?: RoleModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "RoleManage/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json())  as ResultDto<FilterValueDto[]>;
  }
}


export class RoleApiOffline {
  RoleGetGrid = async (tableQuery: RoleModelQuery): Promise<ResultDto<QueryResultDto>> => {
    let items: RoleModel[] | null = await localforage.getItem(Role_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(Role_GRIDRENDER);
//todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.RoleCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.RoleType?.forEach(s => {
    //   items = items?.filter(x => x.RoleType === s) ?? []
    // });
    let res: ResultDto< QueryResultDto >= {data :{ gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length }}
    return res;
  }

  RoleDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "RoleManage/File?id=" + id;
    let response = await fetch(url, options);
    console.log(response.headers);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  RoleGetFilters = async (columName: string, columValue?: string, queryFilter?: RoleModelQuery): Promise<ResultDto<FilterValueDto[]>  > => {
    let items: ResultDto<FilterValueDto[] >    = {data: await localforage.getItem(Role_FILTERS_LOCAL + columName) ?? []};
    return items;
  }
}

