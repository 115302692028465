import { EDIT_User, GET_User_DETAIL, UserModelDetail } from '../../../Model/UserModels';

const initState: UserModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const UserListDetailReducer = (state = initState, action: { type: string, payload: UserModelDetail }) => {
    switch (action.type) {
        case GET_User_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_User:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
