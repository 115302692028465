import { AnyAction } from 'redux';
import { AuthStateModel } from '../../Model/AuthModel';

const initialState: AuthStateModel = {
  registry: undefined,
  contacts: undefined,
  token: '',
  validityDate: undefined
};

// Definisci l'interfaccia per le azioni che lavorano con AuthStateModel
interface AuthAction extends AnyAction {
  payload?: AuthStateModel; // Rendi il payload opzionale per permettere azioni senza payload
}

export const AuthReducer = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case "AUTH_INSTANCE": {
      // Assicurati che payload esista prima di tentare di spalmare le sue proprietà
      return action.payload ? { ...state, ...action.payload } : state;
    }
    default:
      return state;
  }
};
