import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { TripApi } from "../../../Business/TripBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { DELETE_Trip } from "../../../Model/TripModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function deleteTrip(id: string) {
	setLoader("ADD", "deleteTrip");
	let api = new TripApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.TripDelete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_Trip, payload: rtn });
	setLoader("REMOVE", "deleteTrip");
	return rtn;
}

// export async function DeleteDeepTrip(id: number) {
// 	let api = new TripApi();
// 	setLoader("ADD", "DeleteDeepTrip");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.TripDeleteDeep(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: DELETE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "DeleteDeepTrip");
// 	return rtn;
// }

// export async function GetRelatedRecordsTrip(id: number) {
// 	let api = new TripApi();
// 	setLoader("ADD", "GetRelatedRecordsTrip");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.TripGetRelatedRecords(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	if (result?.warning) rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: NotifyType.error }));
// 	setLoader("REMOVE", "GetRelatedRecordsTrip");
// 	return rtn;
// }

// export async function RestoreTrip(id: number) {
// 	setLoader("ADD", "RestoreTrip");
// 	let api = new TripApi();
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.TripRestore(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: RESTORE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "RestoreTrip");
// 	return rtn;
// }
