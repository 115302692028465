import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {  VehicleViewModel, EDIT_Vehicle, VehicleEdit,   } from '../../../Model/VehicleModels';
import { VehicleApi } from "../../../Business/VehicleBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import { ResultDto } from '../../../Model/CommonModel';
import { GET_EDIT_Vehicle } from '../../../Model/VehicleModels';

export async function UpdateVehicle(data: VehicleViewModel) {
	setLoader("ADD", "EditVehicle");
	let api = new VehicleApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.VehicleEdit(data));
	let rtn = { dto: null, resultDto: result } as VehicleEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Vehicle, payload: rtn });
	setLoader("REMOVE", "EditVehicle");
	return rtn;
}
export async function GetVehicleCreateResource(id?: string) {
	setLoader("ADD", "GetVehicleEditResource");
	let api = new VehicleApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<VehicleViewModel>>>(() => api.VehicleGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as VehicleEdit;
	rootStore.dispatch({ type: GET_EDIT_Vehicle, payload: rtn });
	setLoader("REMOVE", "GetVehicleEditResource");
	return rtn.dto;
}

// export const ChangeStatusVehicleViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusVehicleViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new VehicleApi();
//     let result = await ApiCallWithErrorHandling(() => api.VehicleItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Vehicle_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as VehicleViewModelDetail });

//         let list = await localforage.getItem(Vehicle_LOCAL) as VehicleViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Vehicle_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusVehicleViewModel");
//     return
// }

// export const ChangeStatusVehicleRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusVehicleRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new VehicleApi();
//     let result = await ApiCallWithErrorHandling(() => api.VehicleItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Vehicle_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as VehicleViewModelDetail });

//         let list = await localforage.getItem(Vehicle_LOCAL) as VehicleViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Vehicle_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusVehicleRejected");
//     return
// }

// export const RejectVehicleViewModel = async (id: number) => {
//     setLoader("ADD", "RejectVehicleViewModel");
//     let api = new VehicleApi();
//     let result = await ApiCallWithErrorHandling(() => api.VehicleItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Vehicle_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as VehicleViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectVehicleViewModel");
//     return
// }


