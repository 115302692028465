import React, { SetStateAction, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import ButtonActionIcon from '../../Components/Various/ButtonActionIcon';
import '../../Css/AziendaList.css';
import { formatTime } from '../../Hook/Common/TimeAndDate';
import { arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import { SelectFilterTypeGrid, SelectGridType } from '../../Hook/Grid/GridRender';
import { useFilterTableCrud } from '../../Hook/Grid/useFilterTableCrud';
import { DataModalConfirm, QueryObject, RenderDetail } from '../../Model/CommonModel';
import { OrdineDiCaricoViewModel, TripViewModel } from '../../Model/TripModels';
import { DownloadTripExcell } from '../../Redux/Action/Trip/TripDownloadAction';
import { GetFilterColumnTripViewModel } from '../../Redux/Action/Trip/TripGridAction';
import { RootState } from '../../Redux/Store/rootStore';
import OrdineCarico from '../Component/Trip/OrdineCarico';
import TripComponent from '../Component/Trip/TripComponent';

interface Props {
  action: {
    setConfirm(data: DataModalConfirm): any
    setQuery(query: SetStateAction<QueryObject> | undefined): any
    Edit(id: string | undefined): any;
    onDelete(id: string | undefined): any;

  }
  isMobile: boolean;
  renderGrid: RenderDetail[] | undefined;
  data: TripViewModel[]
  query: QueryObject
  downloadedList: number[]
}

const TripGrid: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  const getFiltersData = (state: RootState) => state.TripGridReducer.filter;
  let filterData = useSelector(getFiltersData);

  const {
    filtriAttivi,
    resetFilter,
    closeAll,
    setDateToChildren,
    orderBy,
    resetFilterDate,
    getFilters,
    updateCount,
    getFiltriAttivi,
    count,
    checkFilterinValue,
    checkFilterDateinValue,
    isVisibleFiltriString,
    setIsVisibleFiltriString,
    getFiltriAttiviSearch
  } = useFilterTableCrud<QueryObject>(props.action.setQuery, GetFilterColumnTripViewModel, props.query);
  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const closeModal = () => { setIsVisibleModal(false); };
  const [OrdineDiCaricoViewModel, setOrdineDiCaricoViewModel] = useState<OrdineDiCaricoViewModel | null>(null)

  const thAction = { orderBy };
  const actionFilterCheckbox = { closeAll, updateCount, getFiltriAttivi, orderBy, getFilters, checkFilter: checkFilterinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilter };
  const actionFilterSearch = { closeAll, updateCount, getFiltriAttiviSearch, orderBy };
  const actionFilterDate = { setDateToChildren, checkFilter: checkFilterDateinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilterDate };
  const thActionDate = { orderBy };


  const InvocheDownload = async (id: string) => {
    let result = await DownloadTripExcell(id);
    if (result !== undefined) {
      let url = window.URL.createObjectURL(result.file);
      let a = document.createElement("a");
      a.href = url;
      a.download = result.fileName;
      a.click();
    }
  };
  const showOrdineDiCaricoModal = (item: TripViewModel) => {
     let ordineDiCarico = {
      dettagli: item.productInTripViewModels?.map(x => { return { colli: x.quantity / (x.colliXProdotto ?? 1), quantita: x.quantity, tipo: x.productName } }),
      intestazione: { carico: item.origin, data: formatTime(item.startTime ?? new Date()), fornitore: item.aziendaDestinazione, destinazione : item.destination,origine :item.origin , targa:item.targa,trasportatore:item.nomeTrasportatore,ignoraLogo : false }
    } as OrdineDiCaricoViewModel
    setOrdineDiCaricoViewModel(ordineDiCarico);
    setIsVisibleModal(true);
  }

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static"  keyboard={false} size="lg">
      <Modal.Header className="d-flex justify-content-center">
        <div className="col-12 px-0 mb-2">  
            <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                <h1 className="header">ORDINE DI CARICO</h1>
                <ButtonActionIcon action={closeModal} icon={require("../../img/close.png")} />
                
            </div>
        </div>
        </Modal.Header>
        <Modal.Body>
          <OrdineCarico model={OrdineDiCaricoViewModel ?? {} as OrdineDiCaricoViewModel} />
        </Modal.Body>
      </Modal>
      <div className="col-12 mx-0 table-responsive">

        {props.isMobile ? <div className='row'>
          {props.data?.map((item, i) => <TripComponent key={item.id ?? "vabbe" + i + "asdasdas"} model={item as TripViewModel} action={{
            addTrip: function (data: TripViewModel) {
              props.action.Edit(item.id);

            }, removeTrip: () => props.action.onDelete(item.id),
            showOrdineDiCaricoModal


          }} />)}
        </div> :
          <table className="vma-table table   table-responsive table-striped w-100 minHeight">
          <thead>
              <tr className="intestazione border-top">
                {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((item, i) =>
                  SelectFilterTypeGrid(
                    item.propertyName,
                    item.propertyText,
                    item.type,
                    props.query?.isSortAscending,
                    filtriAttivi,
                    actionFilterDate,
                    props.query?.sortBy,
                    filterData,
                    count,
                    actionFilterCheckbox,
                    thAction,
                    thActionDate,
                    isVisibleFiltriString,
                    actionFilterSearch
                  )
                )}
                <th className="gridHead px-0"  >
                  <div className="row mx-0 d-flex align-content-start">
                    <div className="px-2 w-100 d-flex align-items-start flex-row justify-content-between labelTh align-content-start">
                      <div className="relative">
                        <div className="filterSearch d-flex flex-row justify-content-between w-100">
                          Action
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!arrayIsNullOrEmpty(props.data) ? props.data?.map((item, i) => (
                <tr className={`dati`} key={i}>
                  {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((td, index) =>
                    td.propertyName === "Prodotti" ?
                      <td key={td.propertyName + index + "asd" + i} className={`dati ${td.colorHeader}`} >
                        <div className="cell row">
                          {item["productInTripViewModels"]?.map(x =>
                            <div className='col-12' key={x.productVariantId}>
                              <b>{x.productName}</b> quantità:<b>{x.quantity}</b>colli:<b>{Math.round(x.quantity / (x.colliXProdotto ?? 1))}</b>
                              <hr />
                            </div>
                          )}
                        </div>
                      </td>
                      :
                      SelectGridType(item[td.propertyName], td.propertyName, td.type))}
                  <td className={`dati`}>
                    <div className="cell">
                      <div className="d-flex flex-row">
                        <button type="button" className="btn btn-link" onClick={() => props.action.Edit(item.id)}>
                          <img className="btnEdit" src={require("../../img/edit.png")} alt="edit" />
                        </button>
                        <button type="button" className="btn btn-link" onClick={() => showOrdineDiCaricoModal(item)}>
                          <img className="btnEdit" src={require("../../img/local_shipping.png")} alt="download" />
                        </button>
                        <button type="button" className="btn btn-link" onClick={() => props.action.onDelete(item.id)}>
                          <img className="btnEdit" src={require("../../img/delete.png")} alt="delete" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>

              )) :
                <tr>
                  <div className="w-100 my-2">
                    <h5>La ricerca non ha prodotto risultati</h5>
                  </div></tr>
              }
            </tbody>
          </table>
        }
      </div>
    </div>
  );
}
export default TripGrid
