import React, { useState, useEffect, useRef, useCallback } from 'react';
 
interface MappaConPercorsoProps {
  origin: string;
  destination: string;
  onOriginChanged: (newOrigin: string) => any ;
  onDestinationChanged: (newDestination: string) => any ;
}

const MappaConPercorso: React.FC<MappaConPercorsoProps> = ({ origin, destination, onOriginChanged, onDestinationChanged }) => {
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [polyline, setPolyline] = useState<google.maps.Polyline>();
  const [originMarker, setOriginMarker] = useState<google.maps.Marker>();
  const [destinationMarker, setDestinationMarker] = useState<google.maps.Marker>();
  const [percorsoInfo, setPercorsoInfo] = useState<{ distanza: string, durata: string } | null>(null);

  // Crea una versione memorizzata di onOriginChanged che si aggiorna solo se formData cambia
  const handleOriginChanged = useCallback((newOrigin) => {
    onOriginChanged(newOrigin);
  }, [onOriginChanged]);

  // Crea una versione memorizzata di onDestinationChanged che si aggiorna solo se formData cambia
  const handleDestinationChanged = useCallback((newDestination) => {
    onDestinationChanged(newDestination);
  }, [onDestinationChanged]);
  useEffect(() => {
    if (!mapContainerRef.current) return;

    const map = new google.maps.Map(mapContainerRef.current, {
      center: new google.maps.LatLng(41.9028, 12.4964),
      zoom: 14,
    });
    setGoogleMap(map);

    const origMarker = new google.maps.Marker({
      map: map,
      draggable: true,
      icon: {
        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" // Icona blu per il marker di origine
      }
    });
    setOriginMarker(origMarker);

    const destMarker = new google.maps.Marker({
      map: map,
      draggable: true,
      icon: {
        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" // Icona verde per il marker di destinazione
      }
    });
    setDestinationMarker(destMarker);

    origMarker.addListener('dragend', (e) => aggiornaIndirizzo(e.latLng, true));
    destMarker.addListener('dragend', (e) => aggiornaIndirizzo(e.latLng, false));

  }, []);

  useEffect(() => {
    if (originMarker && destinationMarker) {
      aggiornaPosizioneMarker(origin, true);
      aggiornaPosizioneMarker(destination, false);
    }
  }, [origin, destination, originMarker, destinationMarker]);

  const aggiornaPosizioneMarker = (address, isOrigin) => {
    if (!googleMap || !address) return;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === google.maps
        .GeocoderStatus.OK && results[0]) {
        const posizione = results[0].geometry.location;
        if (isOrigin) {
          originMarker && originMarker.setPosition(posizione);
          googleMap.panTo(posizione);
        } else {
          destinationMarker && destinationMarker.setPosition(posizione);
          googleMap.panTo(posizione);
        }
      }
    });
  };

  const calcolaPercorso = () => {
     if (!googleMap || !origin || !destination) return;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          if (polyline) {
            polyline.setMap(null); // Rimuovi la polyline esistente
          }
          const newPolyline = new google.maps.Polyline({
            path: result.routes[0].overview_path,
            geodesic: true,
            strokeColor: '#0000FF',
            strokeOpacity: 0.8,
            strokeWeight: 2,
          });
          newPolyline.setMap(googleMap);
          setPolyline(newPolyline);
          googleMap.fitBounds(result.routes[0].bounds);

          // Estrai informazioni su distanza e durata
          const distanza = result.routes[0].legs[0].distance.text;
          const durata = result.routes[0].legs[0].duration.text;
          setPercorsoInfo({ distanza, durata });
        } else {
          console.error('Errore nel calcolo del percorso:', status);
        }
      }
    );
  };
  const aggiornaIndirizzo = (latLng, isOrigin) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const newAddress = results[0].formatted_address;
        if (isOrigin) {
         onOriginChanged(newAddress);
          aggiornaPosizioneMarker(newAddress, true);
        } else {
         onDestinationChanged(newAddress);
          aggiornaPosizioneMarker(newAddress, false);
        }
      }
    });
  };

  return (
    <div>
      <div ref={mapContainerRef} id="map" style={{ width: '100%', height: '400px' }}></div>

      <div className='row'>
        <div className='col-2'>  <button onClick={calcolaPercorso} className='btn btn-info' type='button' style={{ marginTop: '10px' }}>
          Calcola Percorso
        </button></div>
        <div className='col-10'>{percorsoInfo &&
          (
            <div>
              <div><strong>Distanza:</strong> {percorsoInfo.distanza}</div>
              <div><strong>Durata:</strong> {percorsoInfo.durata}</div>
            </div>
          )}
        </div>
      </div>


    </div>
  );
};

export default MappaConPercorso;