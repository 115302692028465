import React, { useContext } from "react";
import { DarkTheme } from "../../App";
import '../../Css/Filters.css'
import '../../Css/Index.css'
import '../../Css/AziendaList.css';


interface Props {
    icon: string,
    height?: number,

}

const Icon: React.FC<Props> = (props) => {

    const darkTheme = useContext(DarkTheme)

    return (

        <img className={darkTheme ? "invert" : ""} height={props.height ?? 20} alt={"actions"} src={props.icon}></img>

    )
}
export default Icon
