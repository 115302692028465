import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {  Movimentazione_LOCAL, MovimentazioneViewModel, EDIT_Movimentazione, MovimentazioneEdit,   } from '../../../Model/MovimentazioneModel';
import { MovimentazioneApi } from "../../../Business/MovimentazioneBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';
import { ResultDto } from '../../../Model/CommonModel';
import { GET_EDIT_Movimentazione } from '../../../Model/MovimentazioneModel';

export async function UpdateMovimentazione(data: MovimentazioneViewModel) {
	setLoader("ADD", "EditMovimentazione");
	let api = new MovimentazioneApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.MovimentazioneEdit(data));
	let rtn = { dto: null, resultDto: result } as MovimentazioneEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Movimentazione, payload: rtn });
	setLoader("REMOVE", "EditMovimentazione");
	return rtn;
}
export async function GetMovimentazioneCreateResource(id?: string) {
	setLoader("ADD", "GetMovimentazioneEditResource");
	let api = new MovimentazioneApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<MovimentazioneViewModel>>>(() => api.MovimentazioneGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as MovimentazioneEdit;
	rootStore.dispatch({ type: GET_EDIT_Movimentazione, payload: rtn });
	setLoader("REMOVE", "GetMovimentazioneEditResource");
	return rtn.dto;
}

export async function MovimentazionePaga(id?: string) {
	setLoader("ADD", "MovimentazionePaga");
	let api = new MovimentazioneApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Paga(id));
  	setLoader("REMOVE", "MovimentazionePaga");
	return EditResource;
}
export async function MovimentazioneAnnulla(id?: string) {
	setLoader("ADD", "MovimentazioneAnnulla");
	let api = new MovimentazioneApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Annulla(id));
  	setLoader("REMOVE", "MovimentazioneAnnulla");
	return EditResource;
}
// export const ChangeStatusMovimentazioneViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusMovimentazioneViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new MovimentazioneApi();
//     let result = await ApiCallWithErrorHandling(() => api.MovimentazioneItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Movimentazione_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as MovimentazioneViewModelDetail });

//         let list = await localforage.getItem(Movimentazione_LOCAL) as MovimentazioneViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Movimentazione_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusMovimentazioneViewModel");
//     return
// }

// export const ChangeStatusMovimentazioneRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusMovimentazioneRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new MovimentazioneApi();
//     let result = await ApiCallWithErrorHandling(() => api.MovimentazioneItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Movimentazione_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as MovimentazioneViewModelDetail });

//         let list = await localforage.getItem(Movimentazione_LOCAL) as MovimentazioneViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Movimentazione_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusMovimentazioneRejected");
//     return
// }

// export const RejectMovimentazioneViewModel = async (id: number) => {
//     setLoader("ADD", "RejectMovimentazioneViewModel");
//     let api = new MovimentazioneApi();
//     let result = await ApiCallWithErrorHandling(() => api.MovimentazioneItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Movimentazione_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as MovimentazioneViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectMovimentazioneViewModel");
//     return
// }


