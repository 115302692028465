
import { CustomGridRender, FilterValueDto, QueryResultDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { Warehouse_GRIDRENDER, Warehouse_LOCAL, WarehouseViewModelResource, WarehouseViewModelQuery, Warehouse_FILTERS_LOCAL } from '../Model/Warehouse';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class WarehouseApi {
  WarehouseGetGrid = async (tableQuery: WarehouseViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "Warehouse/Get", options);
    var res = await response.json();
    return res;
  }
  WarehouseCreate = async (model: WarehouseViewModelResource): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(model)
    };
    let url = basePath + "Warehouse";
    let response = await fetch(url, options);
    return await response.json();
  }

  WarehouseGetResource = async (id?: string): Promise<ResultDto<WarehouseViewModelResource>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Warehouse/Resource";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  WarehouseDelete = async (id?: string): Promise<ResultDto> => {
    const options = {
      method: "DELETE",
      headers: await GetHeader(),
    };
    let url = basePath + "Warehouse";
    if (id !== undefined)
      url += "?id=" + id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  WarehouseEdit = async (model: WarehouseViewModelResource): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
      body: JSON.stringify(model)
    };
    let url = basePath + "Warehouse";
    let response = await fetch(url, options);
    return await response.json();
  }


  WarehouseDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Warehouse/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }


  WarehouseGetFilters = async (columName: string, columValue?: string, queryFilter?: WarehouseViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "Warehouse/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json()) as ResultDto<FilterValueDto[]>;
  }
}


export class WarehouseApiOffline {
  WarehouseGetGrid = async (tableQuery: WarehouseViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    let items: WarehouseViewModelResource[] | null = await localforage.getItem(Warehouse_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(Warehouse_GRIDRENDER);
    //todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.WarehouseCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.WarehouseType?.forEach(s => {
    //   items = items?.filter(x => x.WarehouseType === s) ?? []
    // });
    let res: ResultDto<QueryResultDto> = { data: { gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length } }
    return res;
  }

  WarehouseDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Warehouse/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  WarehouseGetFilters = async (columName: string, columValue?: string, queryFilter?: WarehouseViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    let items: ResultDto<FilterValueDto[]> = { data: await localforage.getItem(Warehouse_FILTERS_LOCAL + columName) ?? [] };
    return items;
  }
}

