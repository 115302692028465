// tslint:disable

import { getAccessToken } from "../../Redux/Action/AuthenticationAction";

export const basePath =process.env.REACT_APP_API_URL;
 
export const GetHeader = async ( baypassContentType?: boolean,contentType?: string) => {
  const headers = new Headers();
  const bearer = await getAccessToken(); //`Bearer ${auth}`;
  headers.append("Authorization", bearer);

  if (baypassContentType !== true) {
    if (contentType) {
      headers.append("Content-Type", contentType);
    } else {
      headers.append("Content-Type", "application/json");
    }
  }


  // headers.append("accept", "application/json");


  return headers;
}
export interface ConfigurationParameters {
  apiKey?: string | ((name: string) => string);
  username?: string;
  password?: string;
  accessToken?: string | ((name: string, scopes?: string[]) => string);
  basePath?: string;
}


/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};
