import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { AziendaApi } from "../../../Business/AziendaBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Azienda, AziendaViewModel, EDIT_Azienda, AziendaCreate, AziendaViewModelResource, GET_CREATE_Azienda } from "../../../Model/Azienda";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetAziendaCreateResource(id?: string) {
	setLoader("ADD", "GetAziendaCreateResource");
	let api = new AziendaApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<AziendaViewModelResource>>>(() => api.AziendaGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as AziendaCreate;
	rootStore.dispatch({ type: GET_CREATE_Azienda, payload: rtn });
	setLoader("REMOVE", "GetAziendaCreateResource");
	return rtn.dto;
}


export async function CreateAzienda(data: AziendaViewModel) {
	setLoader("ADD", "CreateAzienda");
	let api = new AziendaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.AziendaCreate(data));
	let rtn = { dto: null, resultDto: result } as AziendaCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Azienda, payload: rtn });
	setLoader("REMOVE", "CreateAzienda");
	return rtn;
}
