import React, { useState, useEffect, SetStateAction } from 'react'
import { formatTime} from '../../Hook/Common/TimeAndDate';
import { cancelDelayClose, delayClose,  lowerFirstLetter, toggleStateString } from '../../Hook/Common/Utility'
import { DateFilter } from '../../Model/CommonModel';


interface Props {

    property: string,
    orderAscending?: boolean,
    propertyInOrder?: string,
    filter: DateFilter | undefined
    isVisibleFiltriString: string,
    actionFilters: {
        checkFilter: (value: string) => boolean,
        resetFilter: (value: string) => void,
        settingVisibility: (value: SetStateAction<string>) => void,
        setDateToChildren(data: Date | undefined, property: string, target: string): any,
    }
}


const FilterMenuDateRange: React.FC<Props> = (props) => {

    const [from, setFrom] = useState<string>()
    const [to, setTo] = useState<string>()

    const visibilityAction = () => props.actionFilters.settingVisibility(toggleStateString(props.property, props.isVisibleFiltriString));

    const [showListFixed, setShowListFixed] = useState<boolean>(false)

    useEffect(() => {
        if (props.filter) {
            setFrom(formatTime(props.filter.startDate) ?? "");
            setTo(formatTime(props.filter.endDate) ?? "");
        }
    }, [])

    useEffect(() => {
        if (props.filter?.startDate) {
            setFrom(formatTime(props.filter.startDate) ?? "");
        }else{
            setFrom(undefined)
        }
        if (props.filter?.endDate) {
            setTo(formatTime(props.filter.endDate) ?? "");
        }else{
            setTo(undefined)
        }
    }, [props.filter])


    const reset = () =>{
        props.actionFilters.resetFilter(props.property)
        // setFrom("")
        // setTo("")
    }


    const changeHandler = (e: any, target: string) => {
        let date = new Date(e.target.value)
        props.actionFilters.setDateToChildren(date, props.property, target)
    }

    const openClose = (show: boolean) => {
        if (show) {
            props.actionFilters.settingVisibility(props.property)
        } else {
            props.actionFilters.settingVisibility("")
        }
        if (show) {
            if (props.filter) {
                setFrom(formatTime(props.filter.startDate) ?? "");
                setTo(formatTime(props.filter.endDate) ?? "");
            }
        }
    }

    return (
        <div className="relative">
            <div className="filterSearch d-flex flex-row w-100 ">
                <button type="button" className="filterSearch d-flex flex-row w-100" onClick={() => openClose(true)} onBlur={() => !showListFixed ? props.actionFilters.settingVisibility("") : null}>
                    <img className="mr-1" src={require(`../../svg/Calendar_Gray.png`)}></img>
                    <span className="pr-4">Search</span>
                </button>
                {props.filter != undefined ?
                    <button type="button" className="btn btnTransaparent px-0" onClick={() => reset()}><img src={require('../../svg/close.png')} height="13"></img></button>
                    : null}
            </div>
            {props.isVisibleFiltriString == props.property ?
                <div className="col-12 row mx-0 px-2 mt-2 filterMenu absolute" onMouseEnter={() => setShowListFixed(true)} onMouseLeave={() => delayClose(() => { setShowListFixed(false); visibilityAction() }, 500)}>
                    <div className="col-12 row mx-0 px-2 mt-2">
                        <label className="w-100 bold mb-1">From</label>
                        <input type="date" className="w-100 form-control" value={from} max={to} onChange={(e) => changeHandler(e, "from")}></input>
                    </div>
                    <div className="col-12 row mx-0 px-2 mt-3">
                        <label className="w-100 bold mb-1">Up To</label>
                        <input type="date" className="w-100 form-control" value={to} min={from} onChange={(e) => changeHandler(e, "to")}></input>
                    </div>
                </div> : null}

        </div>
    )
}

export default FilterMenuDateRange


