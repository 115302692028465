import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import { Online } from '../../Components/OnlineOffline';
import Pagination from '../../Components/PaginationComponentHard';
import SetupColumns from '../../Components/SetupColums/SetupColumns';
import ModalConfirm from '../../Components/Various/ModalConfirm';
import '../../Css/AziendaList.css';
import '../../Css/Index.css';
import { useOperationTableCrud } from '../../Hook/Grid/useOperationTableCrud';
import { useResourceTableCrud } from '../../Hook/Grid/useResourceTableCrud';
import { RoleModelQuery, RoleModelResource } from '../../Model/RoleModels';
import setLoader from '../../Redux/Action/LoaderAction';
import { GetRoleCreateResource } from '../../Redux/Action/Role/RoleCreateAction';
import { GetAddResultToGrid, GetRoleModel } from '../../Redux/Action/Role/RoleGridAction';
import { RootState } from '../../Redux/Store/rootStore';
import RoleGrid from '../Role/RoleGrid';
import RoleModal from '../Role/RoleModal';

interface Props {
  isOnline: boolean
}

const RoleContainer: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  let initialState: RoleModelQuery = {

    sortBy: "",
    isSortAscending: false,
    page: 1,
    pageSize: 10,
    claimsType: [],
    claimsValue: [],
    role: [],
  }

  useEffect(() => {
    if (props.isOnline === false)
      setQuery(query, true)
  }, [props.isOnline])

  //UTILITY
 
  //REDUCERS
  const GridData = (state: RootState) => state.RoleGridReducer.gridResult;
  let GridDto = useSelector(GridData);

  const LayoutData = (state: RootState) => state.layoutReducer;
  let layout = useSelector(LayoutData);

  const downloadedData = (state: RootState) => state.RoleDownloadReducer.dtoDownload;
  let downloadedList = useSelector(downloadedData);

  //DETAIL
  const [isVisibleModalSetup, setIsVisibleModalSetup] = useState<boolean>(false);




  //FILTERS
  const { query, setQuery, next, back, goToPage } = useResourceTableCrud(initialState, GetRoleModel, layout.isMobile ?? true, GetAddResultToGrid, GridDto?.items);

  const { New, Edit, isVisibleModal, edit, confirm, setConfirm, closeModal, Delete } = useOperationTableCrud<RoleModelResource, RoleModelResource>(
    GetRoleCreateResource,
    GetRoleCreateResource,
  );

  const closeModalSetup = async () => {
    setIsVisibleModalSetup(false)
    await GetRoleModel(initialState).then(() => {
      setLoader("REMOVE", "GetRoleModel")
    });
  };
  const closeModalForm = async (changed: boolean) => {
    closeModal(changed);
    await GetRoleModel(initialState).then(() => {
      setLoader("REMOVE", "GetProdottoViewModel")
    });
  };

  return (
    <div className={`col-12 mt-3 row mx-0 relative h-100 pageContainer justify-content-center ${darkTheme ? "dark" : ""}`}>
      <ModalConfirm data={confirm} />

      <Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>{edit ? "Modifica Utente" : "Aggiungi Utente"}</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <RoleModal action={{ closeModal: closeModalForm }} edit={edit} isVisibleModal={isVisibleModal} />
        </Modal.Body>
      </Modal>

      <div className="col-12">
        <div className="d-flex align-items-center justify-content-between col-12">
          <h2 className="mb-0">Lista Ruoli</h2>
          <button className='btn btnOutline' type='button' onClick={() => New()}>Aggiungi Ruolo</button>
        </div>
        <div className='col-12 pt-3'>
          <div className={`col-12 pb-5 pt-4 tableContainer`}>
            <div className='col-12 pb-2 d-flex justify-content-end relative'>
              <button type='button' className='btn px-0 btnColonne d-flex align-items-center justify-content-center' onClick={() => setIsVisibleModalSetup(!isVisibleModalSetup)}>
                <img alt="colonne" src={require('../../svg/colonne.png')}></img>
                <span className='ml-2 mt-1'>Modifica Colonne</span>
              </button>
              {isVisibleModalSetup &&
                <div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
                  <div className="orderContainer absolute">
                    <SetupColumns renderGrid={GridDto?.gridRender} action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
                  </div>
                </div>
              }
            </div>
            <RoleGrid action={{ setConfirm, setQuery, Edit, onDelete: Delete }} data={GridDto?.items} downloadedList={downloadedList} query={query} renderGrid={GridDto?.gridRender?.render} />
            <Online>
              <Pagination isMobile={layout.isMobile ?? false} pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems ?? 0} itemsLength={GridDto?.items?.length ?? 0} actions={{ next, back, goToPage }} />
            </Online>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RoleContainer


