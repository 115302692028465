import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import Icon from '../../../Components/Various/Icon'
import { formatTime } from '../../../Hook/Common/TimeAndDate'
import { TripViewModel } from '../../../Model/TripModels'
import ProductInTripComponent from './ProductInTripComponent'

interface Props {
  action?: {
    addTrip?(data: TripViewModel): any | void,
    removeTrip?(data: TripViewModel): any | void,
    chartBar?(data: TripViewModel): any | void,
    showOrdineDiCaricoModal?(data: any): any | void,

  }
  model: TripViewModel,
}
const TripComponent: React.FC<Props> = (props) => {
  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><Icon icon={require("../../../img/local_shipping.png")} height={30} /><label>{props.model.origin}-{props.model.destination} </label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar && <div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)} /></div>}
            {props.action && props.action.addTrip && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addTrip && props.action.addTrip(props.model)} /></div>}
            {props.action && props.action.showOrdineDiCaricoModal && <div className='col mr-0'>   <button type="button" className="btn btn-link" onClick={() => props.action && props.action.showOrdineDiCaricoModal && props.action.showOrdineDiCaricoModal(props.model)}>
              <img className="btnEdit" src={require("../../../img/local_shipping.png")} alt="download" />
            </button>
            </div>}
            {props.action && props.action.removeTrip && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeTrip && props.action.removeTrip(props.model)} /></div>}
            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-md-6'>{props.model.status ? <label ><MaterialIcon icon="description" /> {props.model.status}</label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.startTime ? <label ><MaterialIcon icon="calendar_month" />partenza: {formatTime(props.model.startTime)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.endTime ? <label ><MaterialIcon icon="calendar_month" />arrivo: {formatTime(props.model.endTime)}   </label> : null} </div>
            <div className='col-12 col-md-6'>{props.model.vehicle ? <label><MaterialIcon icon="local_shipping" /> {props.model.vehicle}</label> : null} </div>
          </div>
          {props.model.productInTripViewModels?.map(x => <ProductInTripComponent model={x} key={"asd" + x.productVariantId} />)}
        </div>
      </div>
    </div>
  )
}
export default TripComponent