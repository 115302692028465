import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_User_GRID, UserModelGrid, UserModelQuery, UserModel, User_GRIDRENDER, ADD_User_GRID, GET_User_FILTERS, UserListQueryResultDto } from '../../../Model/UserModels';
import { FilterValueDto, ResultDto } from '../../../Model/CommonModel';
import { UserListApi, UserListApiOffline } from "../../../Business/UserListBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreUserFilters } from './UserStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetUserModel = async (tableQuery: UserModelQuery) => {
    setLoader("ADD", "GetUserModel");
    let api = navigator.onLine ? new UserListApi():new UserListApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<UserListQueryResultDto>>>(() => api.UserListGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,User_GRIDRENDER);
    rootStore.dispatch({ type: GET_User_GRID, payload: { gridResult: result?.data, filter: null } as UserModelGrid });
    setLoader("REMOVE", "GetUserModel");

}
export const GetAddResultToGrid = async (tableQuery: UserModelQuery, dataToConcat: UserModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new UserListApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<UserListQueryResultDto>>>(() => api.UserListGetGrid(tableQuery));
    // if (data?.data && !arrayIsNullOrEmpty(data?.data.items) && data?.data.items !== undefined) {
    //     let arr = data?.dataToConcat?.concat(data?.data.items)
    //     // data?.data.totalItems = data?.data.totalItems;
    //     data?.data.items = arr;
    // }
    rootStore.dispatch({ type: ADD_User_GRID, payload: { gridResult: data?.data, filter: null } as UserModelGrid });
    return data?.data.items
}


export async function GetFilterColumnUserModel(columName: string, columValue: string, queryFilter?: UserModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new UserListApi(): new UserListApiOffline();
    // setLoader("ADD", "GetFilterColumnUserModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.UserListGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.UserListGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_User_FILTERS, payload: { gridResult: null, filter: result?.data } as UserModelGrid });

    result && StoreUserFilters(result.data ?? [],columName);
    return result
}


