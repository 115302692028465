import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_User_DETAIL, UserModelDetail,  User_LOCAL, UserModel, EDIT_User, UserCreate, UserModelResource } from '../../../Model/UserModels';
import { UserListApi } from "../../../Business/UserListBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';
import { ResultDto } from '../../../Model/CommonModel';

export async function UpdateUser(data: UserModelResource) {
	setLoader("ADD", "CreateUser");
	let api = new UserListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.UserListEdit(data));
	let rtn = { dto: null, resultDto: result } as UserCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_User, payload: rtn });
	setLoader("REMOVE", "CreateUser");
	return rtn;
}

// export const ChangeStatusUserModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusUserModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new UserListApi();
//     let result = await ApiCallWithErrorHandling(() => api.UserItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_User_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as UserModelDetail });

//         let list = await localforage.getItem(User_LOCAL) as UserModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(User_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusUserModel");
//     return
// }

// export const ChangeStatusUserRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusUserRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new UserListApi();
//     let result = await ApiCallWithErrorHandling(() => api.UserItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_User_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as UserModelDetail });

//         let list = await localforage.getItem(User_LOCAL) as UserModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(User_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusUserRejected");
//     return
// }

// export const RejectUserModel = async (id: number) => {
//     setLoader("ADD", "RejectUserModel");
//     let api = new UserListApi();
//     let result = await ApiCallWithErrorHandling(() => api.UserItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_User_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as UserModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectUserModel");
//     return
// }


