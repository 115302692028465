import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ProdottoApi } from "../../../Business/ProdottoBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Prodotto, ProdottoViewModel, EDIT_Prodotto, ProdottoCreate, ProdottoViewModelResource, GET_CREATE_Prodotto, MovimentazioneChart } from "../../../Model/ProdottoModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetGraficoMovimentazione(id?: string) :Promise<MovimentazioneChart[]> {
	setLoader("ADD", "GetGraficoMovimentazione");
	let api = new ProdottoApi();

	let data = await ApiCallWithErrorHandling<Promise<MovimentazioneChart[]>>(() => api.GetMovimentazioneChart(id));
 
	setLoader("REMOVE", "GetGraficoMovimentazione");
	return data;
}

 