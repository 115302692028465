import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { WarehouseApi } from "../../../Business/WarehouseBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { DELETE_Warehouse } from "../../../Model/Warehouse";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function deleteWarehouse(id: string) {
	setLoader("ADD", "deleteWarehouse");
	let api = new WarehouseApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.WarehouseDelete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_Warehouse, payload: rtn });
	setLoader("REMOVE", "deleteWarehouse");
	return rtn;
}

// export async function DeleteDeepWarehouse(id: number) {
// 	let api = new WarehouseApi();
// 	setLoader("ADD", "DeleteDeepWarehouse");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.aziendaDeleteDeep(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: DELETE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "DeleteDeepWarehouse");
// 	return rtn;
// }

// export async function GetRelatedRecordsWarehouse(id: number) {
// 	let api = new WarehouseApi();
// 	setLoader("ADD", "GetRelatedRecordsWarehouse");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.aziendaGetRelatedRecords(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	if (result?.warning) rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: NotifyType.error }));
// 	setLoader("REMOVE", "GetRelatedRecordsWarehouse");
// 	return rtn;
// }

// export async function RestoreWarehouse(id: number) {
// 	setLoader("ADD", "RestoreWarehouse");
// 	let api = new WarehouseApi();
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.aziendaRestore(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: RESTORE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "RestoreWarehouse");
// 	return rtn;
// }
