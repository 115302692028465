import localforage from "localforage";
import { FilterValueDto } from "../../../Model/CommonModel";
import { TripViewModel, Trip_FILTERS_LOCAL, Trip_LOCAL } from "../../../Model/TripModels";

export const StoreTrip = async (Tripdata: TripViewModel, fileName: string) => {
  let Trip: TripViewModel[] = await localforage.getItem(Trip_LOCAL) ?? [];
  // Trip.fileName = fileName;
  // Trip.on = formatDateWithTime(new Date())?.toString() ?? "";
  Trip.push(Tripdata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Trip.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Trip_LOCAL, arrayUniqueByKey);
}

export const StoreTripFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Trip_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Trip_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


