import {  CREATE_Azienda, AziendaCreate,GET_CREATE_Azienda } from "../../../Model/Azienda"

const initState: AziendaCreate = {
    dto: {
        id: '',
        name: '',
        description: '',
        image: '',
        imagePath: '',
        location: '',
        phone: '',
        email: '',
        website: '',
        piva: '',
    },
    resultDto:  null,
}

export const AziendaCreateReducer = (state = initState, action: { type: string, payload: AziendaCreate }) => {
    if (action.payload != null) {
        const { dto, resultDto } = action.payload;

        switch (action.type) {
            case CREATE_Azienda:
                return { ...state, resultDto }
            case GET_CREATE_Azienda:
                return { ...state, dto }
            default:
                return state;
        }
    }
    return state;
}
