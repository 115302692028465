import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_Prodotto_GRID, ProdottoViewModelGrid, ProdottoViewModelQuery, ProdottoViewModel, Prodotto_GRIDRENDER, ADD_Prodotto_GRID, GET_Prodotto_FILTERS, ProdottoQueryResultDto } from '../../../Model/ProdottoModels';
import { FilterValueDto, QueryResultDto, ResultDto } from '../../../Model/CommonModel';
import { ProdottoApi, ProdottoApiOffline } from "../../../Business/ProdottoBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreProdottoFilters } from './ProdottoStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetProdotto = async (tableQuery: ProdottoViewModelQuery) => {
    setLoader("ADD", "GetProdottoViewModel");
    let api = navigator.onLine ? new ProdottoApi():new ProdottoApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.ProdottoGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,Prodotto_GRIDRENDER);
    rootStore.dispatch({ type: GET_Prodotto_GRID, payload: { gridResult: result?.data, filter: null } as ProdottoViewModelGrid });
    setLoader("REMOVE", "GetProdottoViewModel");

}
export const GetAddResultToGrid = async (tableQuery: ProdottoViewModelQuery, dataToConcat: ProdottoViewModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new ProdottoApi();
    let data : ResultDto<QueryResultDto> = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.ProdottoGetGrid(tableQuery));
    if (data && !arrayIsNullOrEmpty(data.data.items) && data.data.items !== undefined) {
        let arr = dataToConcat?.concat(data.data.items as any[])
        // data.totalItems = data.totalItems;
        data.data.items = arr;
    }
    rootStore.dispatch({ type: ADD_Prodotto_GRID, payload: { gridResult: data.data, filter: null } as ProdottoViewModelGrid });
    return data?.data.items
}

 

export async function GetFilterColumnProdottoViewModel(columName: string, columValue: string, queryFilter?: ProdottoViewModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new ProdottoApi(): new ProdottoApiOffline();
    // setLoader("ADD", "GetFilterColumnProdottoViewModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.ProdottoGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.ProdottoGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_Prodotto_FILTERS, payload: { gridResult: null, filter: result?.data } as ProdottoViewModelGrid });

    result && StoreProdottoFilters(result.data ?? [],columName);
    return result
}


