import React, { HTMLAttributes, useContext } from "react";
import { DarkTheme } from "../../App";
import '../../Css/Filters.css'
import '../../Css/Index.css'
import '../../Css/AziendaList.css';
import Icon from "./Icon";
import MaterialIcon from 'react-google-material-icons'


interface Props  extends HTMLAttributes<HTMLDivElement>{
    action?(): any
    icon: string
    size?:number
}

const ButtonActionIconGoogleIcon: React.FC<Props> = (props) => {
    const { action, icon, size, children, ...divProps } = props;

    return (
        <div className="row mx-0" {...divProps}>
            <div className="pointer-event cursoricon " style={{paddingTop:"6px"}} onClick={() => props.action && props.action()}>
            <MaterialIcon icon={props.icon} size={props.size} />
            {props.children}
            </div>
        </div>
    )
}
export default ButtonActionIconGoogleIcon
