import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_Role_GRID, RoleModelGrid, RoleModelQuery, RoleModel, Role_GRIDRENDER, ADD_Role_GRID, GET_Role_FILTERS, RoleQueryResultDto } from '../../../Model/RoleModels';
import { FilterValueDto, ResultDto } from '../../../Model/CommonModel';
import { RoleApi, RoleApiOffline } from "../../../Business/RoleBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreRoleFilters } from './RoleStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetRoleModel = async (tableQuery: RoleModelQuery) => {
    setLoader("ADD", "GetRoleModel");
    let api = navigator.onLine ? new RoleApi():new RoleApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<RoleQueryResultDto>>>(() => api.RoleGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,Role_GRIDRENDER);
    rootStore.dispatch({ type: GET_Role_GRID, payload: { gridResult: result?.data, filter: null } as RoleModelGrid });
    setLoader("REMOVE", "GetRoleModel");

}
export const GetAddResultToGrid = async (tableQuery: RoleModelQuery, dataToConcat: RoleModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new RoleApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<RoleQueryResultDto>>>(() => api.RoleGetGrid(tableQuery));
    if (data && !arrayIsNullOrEmpty(data.data.items) && data.data.items !== undefined) {
        let arr = dataToConcat?.concat(data.data.items as any[])
        // data.totalItems = data.totalItems;
        data.data.items = arr;
    }
    rootStore.dispatch({ type: ADD_Role_GRID, payload: { gridResult: data?.data, filter: null } as RoleModelGrid });
    return data?.data.items
}


export async function GetFilterColumnRoleModel(columName: string, columValue: string, queryFilter?: RoleModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new RoleApi(): new RoleApiOffline();
    // setLoader("ADD", "GetFilterColumnRoleModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.RoleGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.RoleGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_Role_FILTERS, payload: { gridResult: null, filter: result?.data } as RoleModelGrid });

    result && StoreRoleFilters(result.data ?? [],columName);
    return result
}


