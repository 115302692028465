import React, { useContext, useState, useEffect } from 'react';
import { DarkTheme } from '../App';
import { AuthAPi } from '../Business/Auth';
import { basePath } from '../Business/Common/configuration';

interface LoginProps {
  code?: string;
  userId?: string;
}

const Login: React.FC<LoginProps> = ({ code, userId }) => {
  const darkTheme = useContext(DarkTheme);
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const authApi = new AuthAPi();

  useEffect(() => {
    if (code && userId) {
      setShowResetPassword(true);
    }
  }, [code, userId]);

  const handleLoginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    authApi.Login({ username: user, password: pass });
  };

  const handleForgotPasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    fetch(`${basePath}Authenticate/ForgotPassword?email=${email}`)
      .then(response => response.json())
      .then(data => {
        setMessage('Un link per il reset è stato inviato alla tua email.');
      })
      .catch(error => {
        setMessage('Si è verificato un errore. Per favore riprova.');
      });
  };

  const handleResetPasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
    if (!passwordRegex.test(newPassword)) {
      setMessage('La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage('Le password non corrispondono.');
      return;
    }

    fetch(`${basePath}Authenticate/ForgotPassword?code=${encodeURIComponent(code ?? "")}&email=${encodeURIComponent(userId ?? "")}&password=${encodeURIComponent(newPassword)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setMessage('Password reimpostata con successo. Ora puoi accedere con la tua nuova password.');
        setShowResetPassword(false);
      })
      .catch(error => {
        setMessage('Si è verificato un errore. Per favore riprova.');
      });
  };

  return (
    <div className={`container mt-5 ${darkTheme ? "dark" : ""}`}>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <h1 className='text-center mb-4'>Magazzino</h1>
          {message && <p className="text-center">{message}</p>}
          {!showForgotPassword && !showResetPassword ? (
            <form onSubmit={handleLoginSubmit}>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">Utente</label>
                <input 
                  type="text" 
                  className="form-control" 
                  id="username" 
                  placeholder="Nome utente" 
                  value={user} 
                  onChange={(e) => setUser(e.target.value)} 
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">Password</label>
                <input 
                  type="password" 
                  className="form-control" 
                  id="password" 
                  placeholder="Password" 
                  value={pass} 
                  onChange={(e) => setPass(e.target.value)} 
                />
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-info">
                  ACCEDI
                </button>
                <button 
                  type="button" 
                  className="btn btn-secondary ml-1" 
                  onClick={() => setShowForgotPassword(true)}
                >
                  Password dimenticata?
                </button>
              </div>
            </form>
          ) : showForgotPassword ? (
            <form onSubmit={handleForgotPasswordSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input 
                  type="email" 
                  className="form-control" 
                  id="email" 
                  placeholder="Email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-info">
                  Reset Password
                </button>
                <button 
                  type="button" 
                  className="btn btn-secondary ml-1" 
                  onClick={() => setShowForgotPassword(false)}
                >
                  Torna alla Login
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleResetPasswordSubmit}>
              <div className="mb-3">
                <label htmlFor="newPassword" className="form-label">Nuova Password</label>
                <input 
                  type="password" 
                  className="form-control" 
                  id="newPassword" 
                  placeholder="Nuova Password" 
                  value={newPassword} 
                  onChange={(e) => setNewPassword(e.target.value)} 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">Conferma Nuova Password</label>
                <input 
                  type="password" 
                  className="form-control" 
                  id="confirmPassword" 
                  placeholder="Conferma Nuova Password" 
                  value={confirmPassword} 
                  onChange={(e) => setConfirmPassword(e.target.value)} 
                />
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-info">
                  Cambia Password
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
