import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ProdottoApi } from "../../../Business/ProdottoBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Prodotto, ProdottoViewModel, EDIT_Prodotto, ProdottoCreate, ProdottoViewModelResource, GET_CREATE_Prodotto } from "../../../Model/ProdottoModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetProdottoCreateResource(id?: string) {
	setLoader("ADD", "GetProdottoCreateResource");
	let api = new ProdottoApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<ProdottoViewModelResource>>>(() => api.ProdottoGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as ProdottoCreate;
	rootStore.dispatch({ type: GET_CREATE_Prodotto, payload: rtn });
	setLoader("REMOVE", "GetProdottoCreateResource");
	return rtn.dto;
}


export async function CreateProdotto(data: ProdottoViewModel) {
	setLoader("ADD", "CreateProdotto");
	let api = new ProdottoApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProdottoCreate(data));
	let rtn = { dto: null, resultDto: result } as ProdottoCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Prodotto, payload: rtn });
	setLoader("REMOVE", "CreateProdotto");
	return rtn;
}
