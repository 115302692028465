 import { ADD_Prodotto_GRID, GET_Prodotto_FILTERS, GET_Prodotto_GRID, ProdottoViewModelGrid } from '../../../Model/ProdottoModels';

const initState: ProdottoViewModelGrid = {
    gridResult: null,
    filter: null,
}

export const ProdottoGridReducer = (state = initState, action: { type: string, payload: ProdottoViewModelGrid }) => {
  if(action.payload != null && action.payload ){
  const {gridResult,filter}  = action.payload;
  switch (action.type) {
        case GET_Prodotto_GRID:
        case ADD_Prodotto_GRID:
            {
                return { ...state,gridResult }
            }
        case GET_Prodotto_FILTERS:
             return { ...state, filter }
        default:
            return state;
    }
}
return state;}

