import { CustomGridRender, DateFilter, FilterValueDto, QueryObject, RenderDetail, ResultDto } from "./CommonModel";

/**
 * 
 * @export
 * @interface TripViewModel
 */

export interface TripViewModel {
  id: string;
  vehicleId: string;
  movimentazioneId?: string | null;
  vehicle: string;
  status: string;
  origin: string;
  destination: string;
  nomeTrasportatore: string;
  targa: string;
  startTime: Date | null;
  endTime: Date | null;
  productInTripViewModels: ProductInTripViewModel[] | null;
  aziendaOrigineId? :string;
  aziendaDestinazioneId? :string;
  aziendaOrigine? :string;
  aziendaDestinazione? :string;
}

export interface ProductInTripViewModel {
  tripId: string;
  productVariantId: string;
  productName: string;
  azienda: string;
  quantity: number;
  colliXProdotto: number | null;
}
/**
 * 
 * @export
 * @interface TripViewModelCustomGridRender
 */
export interface TripViewModelCustomGridRender {
  /**
   * 
   * @type {Array<RenderDetail>}
   * @memberof TripViewModelCustomGridRender
   */
  render?: Array<RenderDetail>;
}export interface TripViewModelQuery extends QueryObject {
  id: string | null;
  status: string[] | null;
  vehicle: string[] | null;
  origin: string[] | null;
  destination: string[] | null;
  startTime: DateFilter | null;
  endTime: DateFilter | null;
  aziendaOrigine: string[] | null;
  aziendaDestinazione: string[] | null;
}
export interface IntestazioneOrdine {
  fornitore: string;
  origine:string;  
  destinazione:string;
  carico: string;
  data: string;
  targa: string;
  trasportatore: string;
  ignoraLogo: boolean;
}

export interface DettaglioOrdine {
  quantita: number;
  tipo: string;
  colli: number;
}

export interface OrdineDiCaricoViewModel {
  intestazione: IntestazioneOrdine;
  dettagli: DettaglioOrdine[];
}


/**
 * 
 * @export
 * @interface TripViewModelQueryResultResponse
 */
export interface TripViewModelQueryResultResponse {
  /**
   * 
   * @type {number}
   * @memberof TripViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<TripViewModel>}
   * @memberof TripViewModelQueryResultResponse
   */
  items?: Array<TripViewModel>;
  /**
   * 
   * @type {TripViewModelCustomGridRender}
   * @memberof TripViewModelQueryResultResponse
   */
  gridRender?: TripViewModelCustomGridRender;
}
/**
 * 
 * @export
 * @interface TripViewModelQueryResultResponseResponseResult
 */
export interface TripViewModelQueryResultResponseResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof TripViewModelQueryResultResponseResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof TripViewModelQueryResultResponseResponseResult
   */
  info?: string;
  /**
   * 
   * @type {TripViewModelQueryResultResponse}
   * @memberof TripViewModelQueryResultResponseResponseResult
   */
  data?: TripViewModelQueryResultResponse;
}
/**
 * 
 * @export
 * @interface TripViewModelResource
 */
export interface TripViewModelResource extends TripViewModel {

}
/**
 * 
 * @export
 * @interface TripViewModelResourceResponseResult
 */
export interface TripViewModelResourceResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof TripViewModelResourceResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof TripViewModelResourceResponseResult
   */
  info?: string;
  /**
   * 
   * @type {TripViewModelResource}
   * @memberof TripViewModelResourceResponseResult
   */
  data?: TripViewModelResource;
}


export interface TripViewModelGrid {
  gridResult: TripListQueryResultDto | null
  filter: FilterValueDto[] | null
}


export interface TripModelDetail {
  dtoEdit: TripViewModelResource | null,
  resultDto: ResultDto<null> | null,
}

export interface TripCreate {
  dto: TripViewModelResource | null,
  resultDto: ResultDto<null> | null,
}
export interface TripEdit extends TripCreate {

}
export interface TripModelDownloaded {
  dtoDownload: number[],
}

export interface TripListQueryResultDto {
  /**
   *
   * @type {number}
   * @memberof TripListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<TripList>}
   * @memberof TripListQueryResultDto
   */
  items?: Array<TripViewModel>;
  /**
   *
   * @type {CustomGridRender}
   * @memberof TripListQueryResultDto
   */
  gridRender?: CustomGridRender;
}
export interface TripQueryResultDto {
  /**
   * 
   * @type {number}
   * @memberof TripViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<TripViewModel>}
   * @memberof TripViewModelQueryResultResponse
   */
  items?: Array<TripViewModel>;
  /**
   * 
   * @type {TripViewModelCustomGridRender}
   * @memberof TripViewModelQueryResultResponse
   */
  gridRender?: RenderDetail[];
}


export const GET_Trip_GRID = "GET_Trip_GRID"
export const ADD_Trip_GRID = "ADD_Trip_GRID"
export const GET_Trip_FILTERS = "GET_Trip_FILTERS"
export const GET_Trip_DETAIL = "GET_Trip_DETAIL"
export const GET_EDIT_Trip = "GET_EDIT_Trip"
export const EDIT_Trip = "EDIT_Trip"
export const CREATE_Trip = "CREATE_Trip"
export const GET_CREATE_Trip = "GET_CREATE_Trip"
export const DELETE_Trip = "DELETE_Trip"


//#region offline definition

export const Trip_LOCAL = "Trip_LOCAL"
export const Trip_FILTERS_LOCAL = "Trip_FILTERS_LOCAL"

export const Trip_GRIDRENDER = "Trip_GRIDRENDER"

//#endregion
