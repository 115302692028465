import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ProdottoApi } from "../../../Business/ProdottoBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { DELETE_Prodotto } from "../../../Model/ProdottoModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function deleteProdotto(id: string) {
	setLoader("ADD", "deleteProdotto");
	let api = new ProdottoApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProdottoDelete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_Prodotto, payload: rtn });
	setLoader("REMOVE", "deleteProdotto");
	return rtn;
}

// export async function DeleteDeepProdotto(id: number) {
// 	let api = new ProdottoApi();
// 	setLoader("ADD", "DeleteDeepProdotto");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.prodottoDeleteDeep(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: DELETE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "DeleteDeepProdotto");
// 	return rtn;
// }

// export async function GetRelatedRecordsProdotto(id: number) {
// 	let api = new ProdottoApi();
// 	setLoader("ADD", "GetRelatedRecordsProdotto");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.prodottoGetRelatedRecords(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	if (result?.warning) rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: NotifyType.error }));
// 	setLoader("REMOVE", "GetRelatedRecordsProdotto");
// 	return rtn;
// }

// export async function RestoreProdotto(id: number) {
// 	setLoader("ADD", "RestoreProdotto");
// 	let api = new ProdottoApi();
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.prodottoRestore(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: RESTORE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "RestoreProdotto");
// 	return rtn;
// }
