import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { UserModel, User_LOCAL, User_FILTERS_LOCAL } from "../../../Model/UserModels"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreUser = async (User: UserModel, fileName: string) => {
  let UserList: UserModel[] = await localforage.getItem(User_LOCAL) ?? [];
  // User.fileName = fileName;
  // User.on = formatDateWithTime(new Date())?.toString() ?? "";
  UserList.push(User);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(UserList.map(item =>
    [item[key], item])).values()];
  localforage.setItem(User_LOCAL, arrayUniqueByKey);
}

export const StoreUserFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(User_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(User_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


