import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { ProdottoViewModel, Prodotto_LOCAL, Prodotto_FILTERS_LOCAL } from "../../../Model/ProdottoModels"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreProdotto = async (Prodottodata: ProdottoViewModel, fileName: string) => {
  let Prodotto: ProdottoViewModel[] = await localforage.getItem(Prodotto_LOCAL) ?? [];
  // Prodotto.fileName = fileName;
  // Prodotto.on = formatDateWithTime(new Date())?.toString() ?? "";
  Prodotto.push(Prodottodata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Prodotto.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Prodotto_LOCAL, arrayUniqueByKey);
}

export const StoreProdottoFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Prodotto_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Prodotto_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


