
import { CustomGridRender, FilterValueDto, QueryResultDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { Proforma_GRIDRENDER, Proforma_LOCAL,   ProformaViewModelQuery,  Proforma_FILTERS_LOCAL, ProformaViewModel, SendEmailFatturaViewModel } from '../Model/ProformaModels';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class ProformaApi {
  proformaExportReport = async (model: ProformaViewModelQuery): Promise<ReturnFile> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Proforma/Export";
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }
  
  ProformaGetGrid = async (tableQuery: ProformaViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "Proforma/Get", options);
    var res = await response.json();
    return res;
  }
  SendEMail = async (data:SendEmailFatturaViewModel ): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(data),
    };
    let response = await fetch(basePath + "Proforma/inviaEmail", options);
    var res = await response.json();
    return res;
  }
  ReSendEMail = async (id:string ): Promise<ResultDto> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),      
    };
    let response = await fetch(basePath + "Proforma/ReSendEMail/"+id, options);
    var res = await response.json();
    return res;
  }
  ProformaCreate = async (model: ProformaViewModel): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Proforma";
    let response = await fetch(url, options);
    return await response.json();
  }

  ProformaGetResource = async (id?:string): Promise<ResultDto<ProformaViewModel>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Proforma/Resource";
    if(id !== undefined)
    url += "?id="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  ProformaDelete = async (id?:string): Promise<ResultDto> => {
    const options = {
      method: "DELETE",
      headers: await GetHeader(),
    };
    let url = basePath + "Proforma";
    if(id !== undefined)
    url += "?id="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  ProformaEdit = async (model: ProformaViewModel): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Proforma";
    let response = await fetch(url, options);
    return await response.json();
  }


  ProformaDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Proforma/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }
  ProformaDownloadExcell = async (id: string): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Proforma/Excell?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  ProformaDownloadPdf = async (filePath: string): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Proforma/Pdf?filePath=" + filePath;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }


  ProformaGetFilters = async (columName: string, columValue?: string, queryFilter?: ProformaViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "Proforma/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json())  as ResultDto<FilterValueDto[]>;
  }
}


export class ProformaApiOffline {
  ProformaGetGrid = async (tableQuery: ProformaViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    let items: ProformaViewModel[] | null = await localforage.getItem(Proforma_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(Proforma_GRIDRENDER);
//todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.ProformaCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.ProformaType?.forEach(s => {
    //   items = items?.filter(x => x.ProformaType === s) ?? []
    // });
    let res: ResultDto< QueryResultDto >= {data :{ gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length }}
    return res;
  }

  ProformaDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Proforma/File?id=" + id;
    let response = await fetch(url, options);
     return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  ProformaGetFilters = async (columName: string, columValue?: string, queryFilter?: ProformaViewModelQuery): Promise<ResultDto<FilterValueDto[]>  > => {
    let items: ResultDto<FilterValueDto[] >    = {data: await localforage.getItem(Proforma_FILTERS_LOCAL + columName) ?? []};
    return items;
  }
}

