import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { AziendaViewModel, Azienda_LOCAL, Azienda_FILTERS_LOCAL } from "../../../Model/Azienda"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreAzienda = async (Aziendadata: AziendaViewModel, fileName: string) => {
  let Azienda: AziendaViewModel[] = await localforage.getItem(Azienda_LOCAL) ?? [];
  // Azienda.fileName = fileName;
  // Azienda.on = formatDateWithTime(new Date())?.toString() ?? "";
  Azienda.push(Aziendadata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Azienda.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Azienda_LOCAL, arrayUniqueByKey);
}

export const StoreAziendaFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Azienda_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Azienda_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


