import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import Icon from '../../../Components/Various/Icon'
import { formatTime } from '../../../Hook/Common/TimeAndDate'
import { WarehouseViewModel } from '../../../Model/Warehouse'

interface Props {
  action?: {
    addWarehouse?(data: WarehouseViewModel): any | void,
    removeWarehouse?(data: WarehouseViewModel): any | void,
    chartBar?(data: WarehouseViewModel): any | void,
  }
  model: WarehouseViewModel,
}
const WarehouseComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><Icon icon={require("../../../img/store.png")} height={30} /><label>{props.model.name}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar && <div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)} /></div>}
            {props.action && props.action.addWarehouse && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addWarehouse && props.action.addWarehouse(props.model)} /></div>}
            {props.action && props.action.removeWarehouse && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeWarehouse && props.action.removeWarehouse(props.model)} /></div>}
            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-md-6'><label ><MaterialIcon icon="description" /> {props.model.description}</label> <label ><MaterialIcon icon="map" /> {props.model.location}</label> </div>
            <div className='col-12 col-md-6'><label ><MaterialIcon icon="calendar_month" /> {props.model.dateOpened ? formatTime(props.model.dateOpened) : "N/A"}</label>
              <label ><MaterialIcon icon="calendar_month" /> {props.model.dateClosed ? formatTime(props.model.dateClosed) : "N/A"}</label> </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default WarehouseComponent