
 import React from 'react'


interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {

  value: any,
  onChange: any,
  name: string,
  title: string,
  placeholder?: string,
  validation?: any,
  validationMessage?:string

}

const CheckboxComponent: React.FC<Props> = (props) => {

  return(
		<div className="form-group col-12 pl-0">
    <label className="labelForm voda-bold text-uppercase w-100">
      {props.title ?? props.name ?? ""}{props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? "*":""}
      <input
        type={'checkbox'}
        placeholder={props.placeholder}
        checked={props.value}
        onChange={e=>props.onChange(props.name,e)}
        onKeyUp={e=>props.onChange(props.name,e)}
        className="inputForm w-100 form-control"
       />
    </label>
    {props.validation && props.validation.response === false && props.validation.property?.includes(props.name) ? (
      <label className="validation">{props.validationMessage}</label>
    ) : null}
  </div>

  )}
  export default CheckboxComponent
