
import { CustomGridRender, FilterValueDto, QueryResultDto, ResultDto, ReturnFile } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";
import { Prodotto_GRIDRENDER, Prodotto_LOCAL,  ProdottoViewModelResource, ProdottoViewModelQuery,  Prodotto_FILTERS_LOCAL, MovimentazioneChart } from '../Model/ProdottoModels';
import localforage from "localforage";
import { GetGridRender } from "./Store/CommonStore";

export class ProdottoApi {
  prodottoExportReport = async (model: ProdottoViewModelQuery): Promise<ReturnFile> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Products/Export";
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }
  ProdottoGetGrid = async (tableQuery: ProdottoViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(tableQuery),
    };
    let response = await fetch(basePath + "Products/Get", options);
    var res = await response.json();
    console.log(res);
    return res;
  }
  ProdottoCreate = async (model: ProdottoViewModelResource): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Products";
    let response = await fetch(url, options);
    return await response.json();
  }

  ProdottoGetResource = async (id?:string): Promise<ResultDto<ProdottoViewModelResource>> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Products/Resource";
    if(id !== undefined)
    url += "?id="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  ProdottoDelete = async (id?:string): Promise<ResultDto> => {
    const options = {
      method: "DELETE",
      headers: await GetHeader(),
    };
    let url = basePath + "Products";
    if(id !== undefined)
    url += "?id="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }

  ProdottoEdit = async (model: ProdottoViewModelResource): Promise<ResultDto> => {
    const options = {
      method: "PUT",
      headers: await GetHeader(),
      body:JSON.stringify(model)
    };
    let url = basePath + "Products";
    let response = await fetch(url, options);
    return await response.json();
  }


  ProdottoDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Products/File?id=" + id;
    let response = await fetch(url, options);
    return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }


  ProdottoGetFilters = async (columName: string, columValue?: string, queryFilter?: ProdottoViewModelQuery): Promise<ResultDto<FilterValueDto[]>> => {
    const options = {
      method: "POST",
      headers: await GetHeader(),
      body: JSON.stringify(queryFilter)
    };
    let url = basePath + "Products/Filter?propertyName=" + columName;
    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }
    let response = await fetch(url, options);
    return (await response.json())  as ResultDto<FilterValueDto[]>;
  }


  GetMovimentazioneChart = async (id?:string): Promise<MovimentazioneChart[]> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Movimentazione/MovimentazioneChart";
    if(id !== undefined)
    url += "?variantId="+ id;
    let response = await fetch(url, options);
    return (await response.json());
  }


}


export class ProdottoApiOffline {
  ProdottoGetGrid = async (tableQuery: ProdottoViewModelQuery): Promise<ResultDto<QueryResultDto>> => {
    let items: ProdottoViewModelResource[] | null = await localforage.getItem(Prodotto_LOCAL);
    let gridRender: CustomGridRender = await GetGridRender(Prodotto_GRIDRENDER);
//todo offline fileter
    // if (!stringIsNullOrEmpty(tableQuery.fullText)) {
    //   items = items?.filter(x => x.itemCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "") || x.ProdottoCode?.toLowerCase().includes(tableQuery.fullText?.toLowerCase() ?? "")) ?? []
    // } else {
    //   items = items
    // }
    // tableQuery.discipline?.forEach(s => {
    //   items = items?.filter(x => x.discipline === s) ?? []
    // });
    // tableQuery.ProdottoType?.forEach(s => {
    //   items = items?.filter(x => x.ProdottoType === s) ?? []
    // });
    let res: ResultDto< QueryResultDto >= {data :{ gridRender: gridRender ?? {} as CustomGridRender, items: items ?? [], totalItems: items?.length }}
    return res;
  }

  ProdottoDownload = async (id: number): Promise<ReturnFile> => {
    const options = {
      method: "GET",
      headers: await GetHeader(),
    };
    let url = basePath + "Products/File?id=" + id;
    let response = await fetch(url, options);
     return { File: response.blob(), FileName: response.headers.get("content-disposition") } as ReturnFile;
  }

  ProdottoGetFilters = async (columName: string, columValue?: string, queryFilter?: ProdottoViewModelQuery): Promise<ResultDto<FilterValueDto[]>  > => {
    let items: ResultDto<FilterValueDto[] >    = {data: await localforage.getItem(Prodotto_FILTERS_LOCAL + columName) ?? []};
    return items;
  }
}

