import { CustomGridRender, FilterValueDto, QueryObject, ResultDto } from "./CommonModel";
export interface RoleModel {
  role: string;
  id: string;
  claim: Claim[];
}
export interface RoleModelResource extends RoleModel {
  claims: string[];
}
export interface Claim {
  type?: string;
  value?: string;
}
export interface RoleModelQuery extends QueryObject {
  role: string[] | null;
  claimsType: string[] | null;
  claimsValue: string[] | null;
}

export interface RoleModelGrid {
  gridResult: RoleQueryResultDto | null
  filter: FilterValueDto[] | null
}



export interface RoleModelDetail {
  dtoEdit: RoleModel | null,
  resultDto: ResultDto<null> | null,
}

export interface RoleCreate {
  dto: RoleModelResource | null,
  resultDto: ResultDto<null> | null,
}
export interface RoleModelDownloaded {
  dtoDownload: number[],
}

export interface RoleQueryResultDto {
  /**
   *
   * @type {number}
   * @memberof RoleListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<RoleList>}
   * @memberof RoleListQueryResultDto
   */
  items?: Array<RoleModel>;
  /**
   *
   * @type {CustomGridRender}
   * @memberof RoleListQueryResultDto
   */
  gridRender?: CustomGridRender;
}

export const GET_Role_GRID = "GET_Role_GRID"
export const ADD_Role_GRID = "ADD_Role_GRID"
export const GET_Role_FILTERS = "GET_Role_FILTERS"
export const GET_Role_DETAIL = "GET_Role_DETAIL"
export const EDIT_Role = "EDIT_Role"
export const CREATE_Role = "CREATE_Role"
export const GET_CREATE_Role = "GET_CREATE_Role"


//#region offline definition

export const Role_LOCAL = "Role_LOCAL"
export const Role_FILTERS_LOCAL = "Role_FILTERS_LOCAL"
export const Role_GRIDRENDER = "Role_GRIDRENDER"

//#endregion
