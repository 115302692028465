import React, { ChangeEvent } from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import Icon from '../../../Components/Various/Icon'
import { formatTime } from '../../../Hook/Common/TimeAndDate'
import { ProductInWarehouseViewModel } from '../../../Model/ProductInWarehouse'

interface Props {
  action?: {
    addInWarehouse?(data: ProductInWarehouseViewModel): any | void,
    removeInWarehouse?(data: ProductInWarehouseViewModel): any | void,
    chartBar?(data: ProductInWarehouseViewModel): any | void,
    changeQuantity?(value: number,id:string): any | void,

  }
  model: ProductInWarehouseViewModel,
}
const ProductInWarehouseComponentOut : React.FC<Props> = (props) => {


  function changeQuantity(event: ChangeEvent<HTMLInputElement>): void {
     let value = +event.target.value;   
     props.action?.changeQuantity && props.action?.changeQuantity(value, props.model.id);
  }

  return (
    <div className='col-12 mb-3'>
      <h4>   <Icon icon={require("../../../img/store.png")} height={30} />
        {props.model.warehouse}</h4>
      <div className='card shadow-sm'>
        {/* Prima riga per le azioni */}
        <div className='row card-body'>

          {props.action && props.action.chartBar && (
            <div className='col'>
              <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action?.chartBar && props.action.chartBar(props.model)} />
            </div>
          )}
          {props.action && props.action.addInWarehouse && (
            <div className='col'>
              <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action?.addInWarehouse && props.action.addInWarehouse(props.model)} />
            </div>
          )}
          {props.action && props.action.removeInWarehouse && (
            <div className='col'>
              <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action?.removeInWarehouse && props.action.removeInWarehouse(props.model)} />
            </div>
          )}
          <div className='col'>
            {props.children}
          </div>
        </div>
        {/* Seconda riga per le etichette e i valori */}
        <div className='row card-body'>

          {props.model.position && (
            <div className='col'>
              <label><MaterialIcon icon="location_on" /> {props.model.position}</label>
            </div>
          )}
       
            <div className='col'>
              <label><Icon icon={require("../../../img/inventory_black.png")} /> <input type='number' style={{width:"70%"}} onChange={ changeQuantity } value={props.model.quantity} /> {props.model.originalQuantity}</label>
            </div>
      
          {props.model.status && (
            <div className='col'>
              <label><MaterialIcon icon="info" /> {props.model.status}</label>
            </div>
          )}
          {props.model.creationDate && (
            <div className='col'>
              <label><MaterialIcon icon="calendar_today" /> {formatTime(props.model.creationDate)}</label>
            </div>
          )}
        </div>
      </div>
    </div> 
  )
}

export default ProductInWarehouseComponentOut;