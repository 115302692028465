import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate'
import { ProformaEmailViewModel, ProformaViewModel } from '../../../Model/ProformaModels'

interface Props {
  action?: {
    addProforma?(data: ProformaViewModel): any | void,
    removeProforma?(data: ProformaViewModel): any | void,
    chartBar?(data: ProformaViewModel): any | void,
    setProformaId(id: string): any | void,
    setProformaEmail(model: ProformaEmailViewModel, id: string): any | void,
    downloadFile(filePath: string, fileName: string): any | void,
    resendEmail(id: string)
  }
  model: ProformaViewModel,
}
const ProformaComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><MaterialIcon icon="description" height={30} /><label>{props.model.fileName}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar && <div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)} /></div>}
            {props.action && props.action.addProforma && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addProforma && props.action.addProforma(props.model)} /></div>}
            {props.action && props.action.removeProforma && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeProforma && props.action.removeProforma(props.model)} /></div>}
            <ButtonActionIconGoogleIcon icon='outgoing_mail' action={() => { props.action && props.action.setProformaId(props.model.id); }} />
            <ButtonActionIconGoogleIcon icon='picture_as_pdf' action={() => { props.action && props.action.downloadFile(props.model.filePath, props.model.fileName); }} />

            {props.children}
          </div>
        </div>
        <div className='card-body'>          <div className='row'>

          {props.model.email?.map(x =>
            <div className='col-12 '>

              <div className='card shadow-sm'>

                <div className='card-body d-flex flex-column'>

                  {/* Elemento posizionato in alto a destra */}
                  <div className='mt-2 ml-auto'>
                    <div className='float-right'>
                      <div className=' d-flex flex-row'>
                        <ButtonActionIconGoogleIcon className='pl-1' icon='outgoing_mail' action={() => { props.action && props.action.setProformaEmail(x, props.model.id); }} />
                        <ButtonActionIconGoogleIcon icon='send' action={() => { props.action && props.action.resendEmail(x.id); }} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6'>{x.email ? <label ><MaterialIcon icon="email" />Email: {x.email}   </label> : null} </div>
                    <div className='col-12 col-md-6'>{x.startDate ? <label ><MaterialIcon icon="calendar_month" />start: {formatTime(x.startDate)}   </label> : null} </div>
                    <div className='col-12 col-md-6'>{x.endDate ? <label ><MaterialIcon icon="calendar_month" />end: {formatTime(x.endDate)}   </label> : null} </div>
                    <div className='col-12 col-md-6'> <label><MaterialIcon icon="numbers" />download :{x.downloadCount}  </label> </div>
                    <div className='col-12 col-md-6'> <label><MaterialIcon icon="calendar_month" />Aperta il :{ formatDateWithTime(x.firstOpen ?? undefined) }  </label> </div>
                  </div>
                </div>
              </div>
            </div>

          )}

        </div>
        </div>
      </div>
    </div>
  )
}

export default ProformaComponent