import { InvoiceAreasApi } from "../../../Business/InvoicesAreas"
import { ProcessImageRequestSave } from "../../../Model/InvoiceAreasModel";
import setLoader from "../LoaderAction";



export const GetInvoicesArea = async () => {
    setLoader("ADD", "GetInvoiceAreas");
    let api = new InvoiceAreasApi();
    let result = (await api.GetInvoiceAreas()).data;
    setLoader("REMOVE", "GetInvoiceAreas");

    return result;
}


export const AddInvoicesArea = async (data: ProcessImageRequestSave) => {
    setLoader("ADD", "AddInvoicesArea");
    let api = new InvoiceAreasApi();
    await api.AddInvoiceAreas(data);
    setLoader("REMOVE", "AddInvoicesArea");
}



export const DeleteInvoicesArea = async (data: string) => {
    setLoader("ADD", "DeleteInvoicesArea");
    let api = new InvoiceAreasApi();
    await api.DeleteInvoiceAreas(data);
    setLoader("REMOVE", "DeleteInvoicesArea");
}