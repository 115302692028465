import { SetStateAction } from "react"
import { useState } from "react"
import { DateFilter, QueryObject } from "../../Model/CommonModel"
import { lowerFirstLetter, stringIsNullOrEmpty } from '../Common/Utility'




export function useFilterTableCrud<T extends QueryObject>(Filter: (obj: SetStateAction<QueryObject>) => any, functionForFilter: Function, pagination: T | undefined) {
    
    const [isVisibleFiltri, setIsVisibleFiltri] = useState(0)
    const [isVisibleFiltriString, setIsVisibleFiltriString] = useState("")
    const [count, setCount] = useState<string[]>([])
    const [filtriAttivi, setFiltriAttivi] = useState<T | undefined>(pagination)
    const [isFiltriAttivati, setIsFiltriAttivati] = useState<boolean>(false)
    const [FiltriAttivati, setFiltriAttivati] = useState<string[]>([])

    //CHIAMATA GET FILTRI
    const getFilters = (property: string, text: string) => {
        functionForFilter(property, text, filtriAttivi);
    };

    const closeAll = () => {
        setIsVisibleFiltri(0)
        setIsVisibleFiltriString("")
    };

    const setDateToChildren = (data: Date | undefined, property: string, target: string) => {
        let copy = { ...filtriAttivi } as T;
        if (copy[lowerFirstLetter(property)] === undefined) {
            copy[lowerFirstLetter(property)] = { startDate: undefined, endDate: undefined } as DateFilter
        }
        switch (target) {
            case "from":
                copy[lowerFirstLetter(property)].startDate = data
                Filter(copy)
                setFiltriAttivi(copy)
                break;
            case "to":
                copy[lowerFirstLetter(property)].endDate = data
                Filter(copy)
                setFiltriAttivi(copy)
                break;
            default:
                break;
        }
    };

    const getFiltriAttivi = (id: string | boolean | number, checked: boolean, property: string, override?: string) => {
        let copy = { ...filtriAttivi } as T
        if (checked) {
            copy && copy[lowerFirstLetter(override ?? property)].push(id)
            Filter(copy);
            setFiltriAttivi(copy)
        } else {
            const index = copy && copy[lowerFirstLetter(override ?? property)].findIndex(x => x === id)
            if (index !== -1 && index !== undefined) {
                copy && copy[lowerFirstLetter(override ?? property)].splice(index, 1)
                Filter(copy)
                setFiltriAttivi(copy)
            }
        }
    };

    

    const getFiltriAttiviSearch = (id: string | null, property: string, override?: string) => {
        let copy = { ...filtriAttivi } as T
        if (!stringIsNullOrEmpty(id ?? "")) {
            if (copy) {
                copy[lowerFirstLetter(override ?? property)] = id
            }
            updateCount(override ?? property)
            Filter(copy);
            setFiltriAttivi(copy)
        } else {
            if (copy) {
                copy[lowerFirstLetter(override ?? property)] = null
            }
            let copyCount = [...count] as string[]
            let index = copyCount.findIndex(x => override ?? property)
            if (index != -1 && index != undefined) {
                copyCount.splice(index, 1)
            }
            setCount(copyCount)
            Filter(copy);
            setFiltriAttivi(copy)
        }
    };

    const orderBy = (property: string, isAscending: boolean) => {
        let copy = { ...filtriAttivi } as T
        copy.sortBy = lowerFirstLetter(property);
        copy.isSortAscending = isAscending;
        Filter(copy);
        setFiltriAttivi(copy);
    };

    const updateCount = (property: string) => {
        let copy = [...count]
        if (copy.filter(x => x === property).length < 1) {
            copy.push(property)
            setCount(copy)
        }
        return copy;
    };

    const resetFilter = (property: string) => {
        let copy = { ...filtriAttivi } as T
        copy && copy[lowerFirstLetter(property)].splice(0, copy[lowerFirstLetter(property)].length)
        if (copy.sortBy === lowerFirstLetter(property)) {
            copy.sortBy = "";
        }
        let index = FiltriAttivati.findIndex(x => x === property)
        let copyAttivati = [...FiltriAttivati]
        if (index != undefined && index != -1) {
            copyAttivati.splice(index, 1)
            setFiltriAttivati(copyAttivati)

            if (copyAttivati.length > 0) {
                setIsFiltriAttivati(true)
            } else {
                setIsFiltriAttivati(false)
            }
        }
        Filter(copy)
        setFiltriAttivi(copy)
    };

    const checkFilterinValue = (property: string): boolean => {
        let value = filtriAttivi?.[property] || "";
        if (value.length > 0 || filtriAttivi?.sortBy === property) {

            let copyAttivati = [...FiltriAttivati]
            if (!FiltriAttivati.includes(property)) {
                copyAttivati.push(property)
                setFiltriAttivati(copyAttivati)

                if (copyAttivati.length > 0) {
                    setIsFiltriAttivati(true)
                } else {
                    setIsFiltriAttivati(false)
                }
            }
            return true;
        };
        let index = FiltriAttivati.findIndex(x => x === property)
        let copyAttivati = [...FiltriAttivati]
        if (index != undefined && index != -1) {
            copyAttivati.splice(index, 1)
            setFiltriAttivati(copyAttivati)

            if (copyAttivati.length > 0) {
                setIsFiltriAttivati(true)
            } else {
                setIsFiltriAttivati(false)
            }
        }
        return false
    };

    const checkFilterDateinValue = (property: string): boolean => {
        if (filtriAttivi?.[property + "StartDate"] || filtriAttivi?.[property + "EndDate"] && filtriAttivi?.[property + "StartDate"] != undefined || filtriAttivi?.[property + "EndDate"] != undefined || filtriAttivi?.sortBy === property) {
            let copyAttivati = [...FiltriAttivati]
            if (!FiltriAttivati.includes(property)) {
                copyAttivati.push(property)
                setFiltriAttivati(copyAttivati)

                if (copyAttivati.length > 0) {
                    setIsFiltriAttivati(true)
                } else {
                    setIsFiltriAttivati(false)
                }
            }
            return true;
        };

        let index = FiltriAttivati.findIndex(x => x === property)
        let copyAttivati = [...FiltriAttivati]
        if (index != undefined && index != -1) {
            copyAttivati.splice(index, 1)
            setFiltriAttivati(copyAttivati)

            if (copyAttivati.length > 0) {
                setIsFiltriAttivati(true)
            } else {
                setIsFiltriAttivati(false)
            }
        }
        return false
    };

    const resetFilterDate = (property: string) => {
        let copy = { ...filtriAttivi } as T;
        copy[lowerFirstLetter(property)].startDate = undefined;
        copy[lowerFirstLetter(property)].endDate = undefined
        copy[lowerFirstLetter(property)] = undefined
        if (copy.sortBy === lowerFirstLetter(property)) {
            copy.sortBy = "";
        }

        let index = FiltriAttivati.findIndex(x => x === lowerFirstLetter(property))
        let copyAttivati = [...FiltriAttivati]
        if (index != undefined && index != -1) {
            copyAttivati.splice(index, 1)
            setFiltriAttivati(copyAttivati)

            if (copyAttivati.length > 0) {
                setIsFiltriAttivati(true)
            } else {
                setIsFiltriAttivati(false)
            }
        }
        Filter(copy)
        setFiltriAttivi(copy)
    }


    return { filtriAttivi, setFiltriAttivi, isVisibleFiltri, setIsVisibleFiltri, resetFilter, closeAll, setDateToChildren, orderBy, resetFilterDate, getFilters, updateCount, getFiltriAttivi, count, setCount, checkFilterinValue, checkFilterDateinValue, isVisibleFiltriString, setIsVisibleFiltriString, isFiltriAttivati, getFiltriAttiviSearch };
}