import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import Icon from '../../../Components/Various/Icon'
import { UserModel } from '../../../Model/UserModels'

interface Props {
  action?: {
    addUtente?(data: UserModel): any | void,
    removeUtente?(data: UserModel): any | void,
    chartBar?(data: UserModel): any | void,
  }
  model: UserModel,
}
const UtenteComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><Icon icon={require("../../../img/store.png")} height={30} /><label>{props.model.firstName} {props.model.lastName}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar && <div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)} /></div>}
            {props.action && props.action.addUtente && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addUtente && props.action.addUtente(props.model)} /></div>}
            {props.action && props.action.removeUtente && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeUtente && props.action.removeUtente(props.model)} /></div>}
            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
             <div className='col-12 col-md-6'>{props.model.email ? <a href={"mailto:" + props.model.email}><MaterialIcon icon="mail" /> {props.model.email}</a> : null} </div>
             <div className='col-12 col-md-6'>{props.model.phone ? <a href={"tel:" + props.model.phone} ><MaterialIcon icon="call" /> {props.model.phone}   </a> : null} </div>
           </div>
        </div>
      </div>
    </div>
  )
}

export default UtenteComponent