import {  CREATE_User, UserCreate,GET_CREATE_User } from "../../../Model/UserModels"

const initState: UserCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const UserListCreateReducer = (state = initState, action: { type: string, payload: UserCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case CREATE_User:
            {

                return { ...state, resultDto }
            }
        case GET_CREATE_User:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
