import { EDIT_Warehouse, GET_Warehouse_DETAIL, WarehouseModelDetail } from '../../../Model/Warehouse';

const initState: WarehouseModelDetail = {
    dtoEdit: null,
    resultDto: null,
}



export const WarehouseDetailReducer = (state = initState, action: { type: string, payload: WarehouseModelDetail }) => {
    switch (action.type) {
        case GET_Warehouse_DETAIL:
            {
                return { ...state, dtoEdit: action.payload.dtoEdit }
            }
        case EDIT_Warehouse:
            return { ...state, resultDto: action.payload.resultDto }
        default:
            return state;
    }
}
