import { AuthStateModel } from "../../Model/AuthModel";
import { rootStore } from "../Store/rootStore";

export const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
    * User is not signed in. Throw error or wait for user to login.
    * Do not attempt to log a user in outside of the context of MsalProvider
    */
  } else {
    const request = {
      scopes: [(window as any).ENV.REACT_APP_AD_SCOPE_API],
      account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    let token = "Bearer " + authResult.accessToken;
    await localStorage.setItem('token', token);
    rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: authResult.accessToken });
    rootStore.dispatch({ type: "AUTH_INSTANCE", payload: msalInstance });

    return authResult.accessToken
  }

};

export async function getAccessToken() {
  var t = await localStorage.getItem('token');
  return "Bearer " + t;
}


export function removeAccessToken() {
  localStorage.setItem("token", "");
  rootStore.dispatch({ type: "AUTH_INSTANCE", payload: {contacts:undefined,registry:undefined,token:''} as AuthStateModel });
}
