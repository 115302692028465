import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import CheckboxComponent from '../../Components/FormAndFilters/CheckboxComponent';
import InputPlaceComponent from '../../Components/FormAndFilters/InputPlaceComponent';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import MappaConMarkerTrascinabile from '../../Components/MappaMarker';
import '../../Css/AziendaList.css';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { AziendaViewModelResource } from '../../Model/Azienda';
import { CreateAzienda } from '../../Redux/Action/Azienda/AziendaCreateAction';
import { UpdateAzienda } from '../../Redux/Action/Azienda/AziendaEditAction';
import { RootState } from '../../Redux/Store/rootStore';
import SelectComponent from '../../Components/FormAndFilters/SelectComponent';

interface Props {
  action: {
    closeModal(changed: boolean): any | void
    onCreate?: (data: AziendaViewModelResource) => void
  }
  isVisibleModal: boolean
  edit: boolean
  id?: string
}

const AziendaModal: React.FC<Props> = (props) => {


  const validazioneClient = (formData: AziendaViewModelResource | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    // if (stringIsNullOrEmpty(formData?.Azienda))
    //   addInvalidProperty("Azienda");


    if (stringIsNullOrEmpty(formData?.name))
      addInvalidProperty("name");

    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
  };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<AziendaViewModelResource>(
    CreateAzienda,
    UpdateAzienda,
    props.action.onCreate
  );

  const dtoEditResourceState = (state: RootState) => state.AziendaCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    if (editResource) {
      const copy = { ...editResource };
      setFormData(copy);
    }
  }, [editResource]);


  function GetTipoValue() : any | undefined {
    if (formData?.tipo == "Cliente") {
      return { value: "Cliente", label: "Cliente" }
    }
    if (formData?.tipo == "Fornitore") {
      return { value: "Fornitore", label: "Fornitore" }
    }
    return undefined;
  }

  function SetTipoSelect(e: any): void {
    var copy = { ...formData } as AziendaViewModelResource;
    copy.tipo = e.value; 
  setFormData(copy);
  }

  

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <form id="form" className='w-100' onChange={() => setChanged(true)}>
        <div className="row mx-0 col-12 px-0">
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.name} name={'name'} title={'Nome'} type={'text'} validation={validation} validationMessage={'Name obbligatorio'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.description} name={'description'} title={'Descrizione'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.email} name={'email'} title={'Email'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.phone} name={'phone'} title={'Telefono'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.piva} name={'piva'} title={'Partita iva'} type={'text'} />
          </div>
          <div className="col-12 col-md-6">
            <CheckboxComponent name='seller' title='Venditore' onChange={onChange} value={formData?.seller} ></CheckboxComponent>
          </div>
          <div className="col-12 col-md-6">
            <InputPlaceComponent onChange={onChange} value={formData?.location} name={'location'} title={'Location'} type={'text'} validation={validation} validationMessage={'Location obbligatorio'} />
            <MappaConMarkerTrascinabile
              // googleMapsApiKey="AIzaSyBs_XCG7dgJ2zRlyJFxV3oWDx-ijDyKVto"
              indirizzoIniziale={formData?.location}
              onIndirizzoCambiato={(e) => {
                onChange("location", { currentTarget: { value: e } } as unknown as Event)

              }}

            />
          </div>
          <div className="col-12 col-md-6">
            <SelectComponent
              value={GetTipoValue()}
              options={[{ value: "Fornitore", label: "Fornitore" }, { value: "Cliente", label: "Cliente" }]}
              onChange={(e) => SetTipoSelect(e)}
              name={'tipo'}
              title={'tipo'}
              isMulti={false}
            />
          </div>

  
        </div>
        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
          <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
            ANNULLA
          </button>
          <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, (e) => { refresh(false); })} type="button">
            SALVA
          </button>
        </div>
      </form>


    </div>
  );
}
export default AziendaModal





