import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {  Azienda_LOCAL, AziendaViewModel, EDIT_Azienda, AziendaEdit, AziendaViewModelResource } from '../../../Model/Azienda';
import { AziendaApi } from "../../../Business/AziendaBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';
import { ResultDto } from '../../../Model/CommonModel';
import { GET_EDIT_Azienda } from '../../../Model/Azienda';

export async function UpdateAzienda(data: AziendaViewModelResource) {
	setLoader("ADD", "EditAzienda");
	let api = new AziendaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.AziendaEdit(data));
	let rtn = { dto: null, resultDto: result } as AziendaEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Azienda, payload: rtn });
	setLoader("REMOVE", "EditAzienda");
	return rtn;
}
export async function GetAziendaCreateResource(id?: string) {
	setLoader("ADD", "GetAziendaEditResource");
	let api = new AziendaApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<AziendaViewModelResource>>>(() => api.AziendaGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as AziendaEdit;
	rootStore.dispatch({ type: GET_EDIT_Azienda, payload: rtn });
	setLoader("REMOVE", "GetAziendaEditResource");
	return rtn.dto;
}

// export const ChangeStatusAziendaViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusAziendaViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new AziendaApi();
//     let result = await ApiCallWithErrorHandling(() => api.AziendaItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Azienda_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as AziendaViewModelDetail });

//         let list = await localforage.getItem(Azienda_LOCAL) as AziendaViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Azienda_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusAziendaViewModel");
//     return
// }

// export const ChangeStatusAziendaRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusAziendaRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new AziendaApi();
//     let result = await ApiCallWithErrorHandling(() => api.AziendaItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Azienda_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as AziendaViewModelDetail });

//         let list = await localforage.getItem(Azienda_LOCAL) as AziendaViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Azienda_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusAziendaRejected");
//     return
// }

// export const RejectAziendaViewModel = async (id: number) => {
//     setLoader("ADD", "RejectAziendaViewModel");
//     let api = new AziendaApi();
//     let result = await ApiCallWithErrorHandling(() => api.AziendaItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Azienda_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as AziendaViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectAziendaViewModel");
//     return
// }


