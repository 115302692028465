import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { WarehouseApi } from "../../../Business/WarehouseBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Warehouse, WarehouseViewModel, EDIT_Warehouse, WarehouseCreate, WarehouseViewModelResource, GET_CREATE_Warehouse } from "../../../Model/Warehouse";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetWarehouseCreateResource(id?: string) {
	setLoader("ADD", "GetWarehouseCreateResource");
	let api = new WarehouseApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<WarehouseViewModelResource>>>(() => api.WarehouseGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as WarehouseCreate;
	rootStore.dispatch({ type: GET_CREATE_Warehouse, payload: rtn });
	setLoader("REMOVE", "GetWarehouseCreateResource");
	return rtn.dto;
}


export async function CreateWarehouse(data: WarehouseViewModel) {
	setLoader("ADD", "CreateWarehouse");
	let api = new WarehouseApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.WarehouseCreate(data));
	let rtn = { dto: null, resultDto: result } as WarehouseCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Warehouse, payload: rtn });
	setLoader("REMOVE", "CreateWarehouse");
	return rtn;
}
