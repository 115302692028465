import { FilterValueDto } from "../Business/Common/CommonBusiness";
import { CustomGridRender, DateFilter, QueryObject, RenderDetail, ResultDto } from "./CommonModel";
import { GetProdottoViewModel } from "./ProdottoModels";
import { VariantViewModel } from "./VariantViewModel";

export interface MovimentazioneViewModel {
  id: string;
  operation: "In" | "Out" | number;
  movimantazioneDate: Date;
  aziendaId: string | null;
  azienda: string;
  aziendaSellerId: string | null;
  aziendaSeller: string;
  movimentazioneProductVariant: MovimentazioneProductVariantViewModel[];
  movimantazioneDateText?: string,
  operationText?: string,
  file?: File; 
  total?:number;
  pagato?:boolean;
  numeroFattura?:string | null;

}

export interface MovimentazioneProductVariantViewModel {
  id?: string;
  movimentazioneId?: string;
  productVariantId?: string;
  creaInDatabase: boolean;
  productId?: string;
  productName?: string;
  quantity: number;
  productVariant: GetProdottoViewModel ;
  productVariantName?: string | null;
  price: number;

}

export interface MovimentazioneViewModelQuery extends QueryObject {
  azienda?: string[];
  aziendaSeller?: string[];
  operationText?: string[];
  movimantazioneDateText?:DateFilter;
  total?:number | null;
  pagato?: string[];
  movimentazioneProductVariant?: string[];
  numeroFattura?:string| null;

}

/**
* 
* @export
* @interface MovimentazioneViewModelQueryResultResponse
*/
export interface MovimentazioneQueryResultDto {
  /**
   * 
   * @type {number}
   * @memberof MovimentazioneViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<MovimentazioneViewModel>}
   * @memberof MovimentazioneViewModelQueryResultResponse
   */
  items?: Array<MovimentazioneViewModel>;
  /**
   * 
   * @type {MovimentazioneViewModelCustomGridRender}
   * @memberof MovimentazioneViewModelQueryResultResponse
   */
  gridRender?: RenderDetail[];
}
/**
* 
* @export
* @interface MovimentazioneViewModelResponseResult
*/
export interface MovimentazioneViewModelResponseResult {
  /**
   * 
   * @type {boolean}
   * @memberof MovimentazioneViewModelResponseResult
   */
  warning?: boolean;
  /**
   * 
   * @type {string}
   * @memberof MovimentazioneViewModelResponseResult
   */
  info?: string;
  /**
   * 
   * @type {MovimentazioneViewModel}
   * @memberof MovimentazioneViewModelResponseResult
   */
  data?: MovimentazioneViewModel;
}
export interface MovimentazioneViewModelGrid {
  gridResult: MovimentazioneListQueryResultDto | null
  filter: FilterValueDto[] | null
}


export interface MovimentazioneModelDetail {
  dtoEdit: MovimentazioneViewModel | null,
  resultDto: ResultDto<null> | null,
}

export interface MovimentazioneCreate {
  dto: MovimentazioneViewModel | null,
  resultDto: ResultDto<null> | null,
}
export interface MovimentazioneEdit extends MovimentazioneCreate {

}
export interface MovimentazioneModelDownloaded {
  dtoDownload: number[],
}


export interface MovimentazioneListQueryResultDto {
  /**
   *
   * @type {number}
   * @memberof MovimentazioneListQueryResultDto
   */
  totalItems?: number;
  /**
   *
   * @type {Array<MovimentazioneList>}
   * @memberof MovimentazioneListQueryResultDto
   */
  items?: Array<MovimentazioneViewModel>;
  /**
   *
   * @type {CustomGridRender}
   * @memberof MovimentazioneListQueryResultDto
   */
  gridRender?: CustomGridRender;
}


export const GET_Movimentazione_GRID = "GET_Movimentazione_GRID"
export const ADD_Movimentazione_GRID = "ADD_Movimentazione_GRID"
export const GET_Movimentazione_FILTERS = "GET_Movimentazione_FILTERS"
export const GET_Movimentazione_DETAIL = "GET_Movimentazione_DETAIL"
export const GET_EDIT_Movimentazione = "GET_EDIT_Movimentazione"
export const EDIT_Movimentazione = "EDIT_Movimentazione"
export const CREATE_Movimentazione = "CREATE_Movimentazione"
export const GET_CREATE_Movimentazione = "GET_CREATE_Movimentazione"
export const DELETE_Movimentazione = "DELETE_Movimentazione"


//#region offline definition

export const Movimentazione_LOCAL = "Movimentazione_LOCAL"
export const Movimentazione_FILTERS_LOCAL = "Movimentazione_FILTERS_LOCAL"

export const Movimentazione_GRIDRENDER = "Movimentazione_GRIDRENDER"

//#endregion
export interface FatturaViewModel {
  fornitore: InformazioniFornitore;
  cliente: InformazioniCliente;
  prodottiEServizi: ProdottoEServizio[];
  pagamento: MetodoDiPagamento;
  riepilogoIVA: RiepilogoIVAModello;
  numeroFattura: string;
  dataFattura: string;
  totaleFattura: number;
  calcoloFattura: CalcoloFattura;
}

export interface InformazioniFornitore {
  nome: string;
  pIVA: string;
  cF: string;
  indirizzo: string;
  telefono: string;
  email: string;
  aziendaId?: string ;
}

export interface InformazioniCliente {
  nome: string;
  pIVA: string;
  cF: string;
  indirizzo: string;
   telefono: string;
  email: string;
  aziendaId?:string ;
}

export interface ProdottoEServizio {
  descrizione: string;
  quantita: number;
  prezzoUnitario: number;
  importo: number;
  iVA: number;
  aliquotaIVA: number;
  prodottoXColli: number;
  colli:number;
}

export interface MetodoDiPagamento {
  metodo: string;
  banca: string;
  iBAN: string;
  bIC_SWIFT: string;
  dataScadenza: string;
  importoPagamento: number;
}

export interface RiepilogoIVAModello {
  aliquotaIVA: number;
  natura: string;
  normativa: string;
  imponibile: number;
  imposta: number;
}

export interface CalcoloFattura {
  imponibileProdotti: number;
  totaleIVA: number;
  totaleDocumento: number;
  nettoAPagare: number;
}