import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { RoleApi } from "../../../Business/RoleBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Role, GET_CREATE_Role, RoleCreate, RoleModel, RoleModelResource } from "../../../Model/RoleModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetRoleCreateResource( id?:string) {
	setLoader("ADD", "GetRoleCreateResource");
	let api = new RoleApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<RoleModelResource>>>(() => api.RoleGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as RoleCreate;
	rootStore.dispatch({ type: GET_CREATE_Role, payload: rtn });
	setLoader("REMOVE", "GetRoleCreateResource");
	return rtn.dto;
}


export async function CreateRole(data: RoleModel) {
	setLoader("ADD", "CreateRole");
	let api = new RoleApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.RoleCreate(data ));
	let rtn = { dto: null, resultDto: result } as RoleCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Role, payload: rtn });
	setLoader("REMOVE", "CreateRole");
	return rtn;
}
