import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ProformaApi } from "../../../Business/ProformaBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { CREATE_Proforma, ProformaViewModel, EDIT_Proforma, ProformaCreate,  GET_CREATE_Proforma } from "../../../Model/ProformaModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetProformaCreateResource(id?: string) {
	setLoader("ADD", "GetProformaCreateResource");
	let api = new ProformaApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<ProformaViewModel>>>(() => api.ProformaGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as ProformaCreate;
	rootStore.dispatch({ type: GET_CREATE_Proforma, payload: rtn });
	setLoader("REMOVE", "GetProformaCreateResource");
	return rtn.dto;
}


export async function CreateProforma(data: ProformaViewModel) {
	
	setLoader("ADD", "CreateProforma");
	let api = new ProformaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProformaCreate(data));
	let rtn = { dto: null, resultDto: result } as ProformaCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_Proforma, payload: rtn });
	setLoader("REMOVE", "CreateProforma");
	return rtn;
}
