import { ApiCallWithErrorHandling, ApiCallWithErrorHandlingFile } from "../../../Business/Common/CommonBusiness";
import { FileResult, ReturnFile } from '../../../Model/CommonModel';
import { AziendaApi } from "../../../Business/AziendaBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from "../NotificationAction";
import { rootStore } from "../../Store/rootStore";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { AziendaViewModelQuery } from "../../../Model/Azienda";


export async function DownloadAziendaItem(id: number) {

  setLoader("ADD", "DownloadAziendaItem");
  let res: ReturnFile | undefined;
  let api = new AziendaApi();

  res = await ApiCallWithErrorHandlingFile<Promise<ReturnFile>>(() =>
    api.AziendaDownload(id)
  );

  if (res?.FileName === null) {
    rootStore.dispatch(setNotification({ message: "File not found", notifyType: NotifyType.warning }));
    setLoader("REMOVE", "DownloadAziendaItem");
  } else {

    let fileNameBase = res?.FileName?.split(";")[1] ?? "";
    var index = fileNameBase?.indexOf('"') + 1;
    var lastIndex = fileNameBase?.indexOf('"', index);
    let fileName = fileNameBase?.substring(index, lastIndex);
    let result = res?.File.then((x) => {
      setLoader("REMOVE", "DownloadAziendaItem");
      return { file: x, fileName: fileName } as FileResult;
    });
    setLoader("REMOVE", "DownloadAziendaItem");
    return result;
  }


}

export async function GetAziendaReport(queryFilter?: AziendaViewModelQuery) {
  let res: ReturnFile | undefined;
  let api = new AziendaApi();

  setLoader("ADD", "GetAziendaReport");

  res = await ApiCallWithErrorHandling<Promise<ReturnFile>>(() =>
    api.aziendaExportReport(
      queryFilter || {}
    )
  );

  let fileNameBase = res?.FileName.split(";")[1] ?? "";
  var index = fileNameBase?.indexOf('"') + 1;
  var lastIndex = fileNameBase?.indexOf('"', index);
  let fileName = fileNameBase?.substring(index, lastIndex);
  let result = res?.File.then((x) => {
    setLoader("REMOVE", "GetAziendaReport");

    return { file: x, fileName: fileName } as FileResult;
  });
  setLoader("REMOVE", "GetAziendaReport");

  return result;
}
