import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { MovimentazioneApi } from "../../../Business/MovimentazioneBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { DELETE_Movimentazione } from "../../../Model/MovimentazioneModel";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function deleteMovimentazione(id: string) {
	setLoader("ADD", "deleteMovimentazione");
	let api = new MovimentazioneApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.MovimentazioneDelete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_Movimentazione, payload: rtn });
	setLoader("REMOVE", "deleteMovimentazione");
	return rtn;
}

// export async function DeleteDeepMovimentazione(id: number) {
// 	let api = new MovimentazioneApi();
// 	setLoader("ADD", "DeleteDeepMovimentazione");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.MovimentazioneDeleteDeep(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: DELETE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "DeleteDeepMovimentazione");
// 	return rtn;
// }

// export async function GetRelatedRecordsMovimentazione(id: number) {
// 	let api = new MovimentazioneApi();
// 	setLoader("ADD", "GetRelatedRecordsMovimentazione");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.MovimentazioneGetRelatedRecords(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	if (result?.warning) rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: NotifyType.error }));
// 	setLoader("REMOVE", "GetRelatedRecordsMovimentazione");
// 	return rtn;
// }

// export async function RestoreMovimentazione(id: number) {
// 	setLoader("ADD", "RestoreMovimentazione");
// 	let api = new MovimentazioneApi();
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.MovimentazioneRestore(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: RESTORE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "RestoreMovimentazione");
// 	return rtn;
// }
