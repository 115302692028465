import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import NavFooterMobile from './Nav/NavFooterMobile';
import NavHeader from './Nav/NavHeader';
import { onResize } from '../Hook/Layout/LayoutHook';
import { useHistory } from 'react-router';
import { RootState } from '../Redux/Store/rootStore';
import { useSelector } from 'react-redux';
import NavSidebar from './Nav/NavSidebar';

interface Props {
  darkTheme: boolean
}

const Layout: React.FC<Props> = (props) => {

  window.addEventListener("resize", onResize)
  const history = useHistory()

  useEffect(() => {
    onResize();
    history.replace({ pathname: '/' })
  }, []);

  const LayoutData = (state: RootState) => state.layoutReducer;
  let layout = useSelector(LayoutData);

  return (
    <div className="d-flex" style={{ height: '100vh', overflow: 'hidden' }}> 
    <div className="content" style={{ flex: 1, overflowY: 'auto' }}>
      <NavHeader isMobile={layout.isMobile ?? false} />
      <div style={{ overflowX: 'auto' }}>
        {props.children} 
      </div>
    </div>
  </div>
  


    // <div className={"d-flex"} style={{ height: window.innerHeight, width: window.innerWidth, overflowY: 'hidden' }}>
    //   {layout.isMobile ?
    //     null
    //     : <NavSidebar />}
    //   <div className='d-flex row mx-0 w-100'>
    //     <NavHeader isMobile={layout.isMobile ?? false} />
    //     {props.children}
    //   </div>
    //   {/* {layout.isMobile ?
    //     <NavFooterMobile />
    //     : null} */}
    // </div>
  );
}

export default Layout
