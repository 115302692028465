import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { ProformaViewModel, Proforma_LOCAL, Proforma_FILTERS_LOCAL } from "../../../Model/ProformaModels"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreProforma = async (Proformadata: ProformaViewModel, fileName: string) => {
  let Proforma: ProformaViewModel[] = await localforage.getItem(Proforma_LOCAL) ?? [];
  // Proforma.fileName = fileName;
  // Proforma.on = formatDateWithTime(new Date())?.toString() ?? "";
  Proforma.push(Proformadata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Proforma.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Proforma_LOCAL, arrayUniqueByKey);
}

export const StoreProformaFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Proforma_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Proforma_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


