import React from "react";
import '../../../Css/Filters.css'
import '../../../Css/Index.css'
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';

interface Props {
    action: {
        onChange(prop: string, value: boolean): any,
    }
    data: {
        property: string
        value: boolean | undefined
    }
    settings: {
        label?: string
        isDisabled?: boolean
        hiddenFilter?: boolean
        verticalLayout?: boolean
        overrideLabels?: string[]
        containerClass?: string
        labelClass?: string
        inputClass?: string
    }
}


const FiltersBooleanDoubleRadio: React.FC<Props> = (props) => {

    return (
        props.settings.hiddenFilter !== true ?
            <div className={`col-12 col-md-6 col-lg-4 d-flex ${props.settings.containerClass}`}>
                <div className="form-group">
                    <label className="labelForm w-100 text-white text-uppercase">
                        {props.settings.label}
                        <div className={`d-flex ${props.settings.verticalLayout === true ? "flex-column" : "align-items-center"} row mx-0`}>
                            <div className="pr-3 py-1">
                                <label className={`fakeCheckbox ${props.settings.labelClass} d-flex flex-nowrap align-items-center`}>
                                    <input type="checkbox" onChange={() => props.action.onChange(props.data.property, false)} checked={props.data.value === false}></input>
                                    <div className={`fakeCheckbox ${props.settings.inputClass} mr-1 ${props.data.value === false ? "checked" : ""}`}></div>
                                    {arrayIsNullOrEmpty(props.settings.overrideLabels) ? "NO" : props.settings.overrideLabels && props.settings.overrideLabels[1]}
                                </label>
                            </div>
                            <div className="pr-3 py-1">
                                <label className={`fakeCheckbox ${props.settings.labelClass} d-flex flex-nowrap align-items-center`}>
                                    <input type="checkbox" onChange={() => props.action.onChange(props.data.property, true)} checked={props.data.value === true}></input>
                                    <div className={`fakeCheckbox ${props.settings.inputClass} mr-1 ${props.data.value === true ? "checked" : ""}`}></div>
                                    {arrayIsNullOrEmpty(props.settings.overrideLabels) ? "YES" : props.settings.overrideLabels && props.settings.overrideLabels[0]}
                                </label>
                            </div>
                        </div>
                    </label>
                </div>
            </div> : null
    )
}
export default FiltersBooleanDoubleRadio