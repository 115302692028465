import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import { CommonValidation } from '../../Business/Common/CommonBusiness';
import DatePickerComponent from '../../Components/FormAndFilters/DatePickerComponent';
import InputPlaceComponent from '../../Components/FormAndFilters/InputPlaceComponent';
import InputTextComponent from '../../Components/FormAndFilters/InputTextComponent';
import MappaConMarkerTrascinabile from '../../Components/MappaMarker';
import '../../Css/AziendaList.css';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';
import { useFormTableCrud } from '../../Hook/Grid/useFormTableCrud';
import { WarehouseViewModel, WarehouseViewModelResource } from '../../Model/Warehouse';
import { CreateWarehouse } from '../../Redux/Action/Warehouse/WarehouseCreateAction';
import { UpdateWarehouse } from '../../Redux/Action/Warehouse/WarehouseEditAction';
import { RootState } from '../../Redux/Store/rootStore';

interface Props {
  action: {
    closeModal(changed: boolean): any | void
    onCreate?: (data: WarehouseViewModelResource) => void
  }
  isVisibleModal: boolean
  edit: boolean
  id?: string
}

const WarehouseModal: React.FC<Props> = (props) => {

  const [newDate, setNewDate] = useState(new Date())

  const validazioneClient = (formData: WarehouseViewModelResource | null | undefined, edit: boolean, validazioneClient: any) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;
    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    // if (stringIsNullOrEmpty(formData?.Warehouse))
    //   addInvalidProperty("Warehouse");


    if (stringIsNullOrEmpty(formData?.name))
      addInvalidProperty("name");
    if (stringIsNullOrEmpty(formData?.description))
      addInvalidProperty("description");
    if (stringIsNullOrEmpty(formData?.location))
      addInvalidProperty("location");

    setValidation(copyValidation);
    return copyValidation;

  }

  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
  };

  const darkTheme = useContext(DarkTheme)

  const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeSelect, onChangeMultipleSelect, onChangeDate, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<WarehouseViewModelResource>(
    CreateWarehouse,
    UpdateWarehouse,
    props.action.onCreate
  );

  const dtoEditResourceState = (state: RootState) => state.WarehouseCreateReducer.dto;
  let editResource = useSelector(dtoEditResourceState);

  useEffect(() => {
    if (editResource) {
      const copy = { ...editResource };
      setFormData(copy);
    }
  }, [editResource]);

  function onChangeWarehouseDate(data: Date): void {
    var copy = { ...formData } as WarehouseViewModel;
    copy.dateOpened = data;
    setNewDate(new Date(data))
    setFormData(copy);
  }
  // function setMultipleWarehouse(e: MultiValue<{ value: string; label: string; }>): void {
  //   var copy = { ...formData } as WarehouseViewModelResource;
  //   copy.claim = e.map(x => { return {type:x.value,value:x.label}  })
  //   setFormData(copy);
  // }

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">
      <form id="form" className='w-100' onChange={() => setChanged(true)}>
        <div className="row mx-0 col-12 px-0">
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.name} name={'name'} title={'Nome'} type={'text'} validation={validation} validationMessage={'Name obbligatorio'} />
          </div>
          <div className="col-12 col-md-6">
            <InputTextComponent onChange={onChange} value={formData?.description} name={'description'} title={'Descrizione'} type={'text'} validation={validation} validationMessage={'Descrizione obbligatorio'} />
          </div>
          <div className="col-12 col-md-6">
            <DatePickerComponent
              value={formData?.dateOpened}
              onChange={onChangeDate}
              name={'dateOpened'}
              title={'Data Apertura'}
              validation={validation}
              validationMessage={'Data obbligatoria'}
            />

          </div>
          <div className="col-12 col-md-6">
            <DatePickerComponent
              value={formData?.dateClosed}
              onChange={onChangeDate}
              name={'dateClosed'}
              title={'Data chiusura'}
              validation={validation}
              validationMessage={'Data obbligatoria'}
            />          </div>
          <div className="col-12 col-md-6">
            <InputPlaceComponent onChange={onChange} value={formData?.location} name={'location'} title={'Location'} type={'text'} validation={validation} validationMessage={'Location obbligatorio'} />
            <MappaConMarkerTrascinabile
              // googleMapsApiKey="AIzaSyBs_XCG7dgJ2zRlyJFxV3oWDx-ijDyKVto"
              indirizzoIniziale={formData?.location}
              onIndirizzoCambiato={(e) =>
                {
                 onChange("location", { currentTarget: { value: e } } as unknown as Event)
              
                }}

            />
          </div>


        </div>
        <div className="col-12 justify-content-end mt-4 d-flex footerModal">
          <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
            ANNULLA
          </button>
          <button className="text-uppercase voda-bold btn btn-danger px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, (e) => { refresh(false); })} type="button">
            SALVA
          </button>
        </div>
      </form>


    </div>
  );
}
export default WarehouseModal





