import React, { useEffect } from 'react';

const FocusHandler = () => {
  useEffect(() => {
    const handleFocusIn = (event) => {
      const target = event.target;
      const closest = target.closest(".tox-dialog-wrap .tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root .tox .tox-silver-sink .tox-tinymce-aux");

      if (closest) {
        event.stopImmediatePropagation();
      }
    };

    // Aggiunta del terzo parametro `true` per ascoltare durante la fase di cattura
    document.addEventListener('focusin', handleFocusIn, true);
    return () => {
      document.removeEventListener('focusin', handleFocusIn, true);
    };
    
  }, []);

  return null;  // Nessun output visivo, solo gestione degli eventi
};

export default FocusHandler;
