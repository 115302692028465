import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {  Warehouse_LOCAL, WarehouseViewModel, EDIT_Warehouse, WarehouseEdit, WarehouseViewModelResource } from '../../../Model/Warehouse';
import { WarehouseApi } from "../../../Business/WarehouseBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import localforage from 'localforage';
import { ResultDto } from '../../../Model/CommonModel';
import { GET_EDIT_Warehouse } from '../../../Model/Warehouse';

export async function UpdateWarehouse(data: WarehouseViewModelResource) {
	setLoader("ADD", "EditWarehouse");
	let api = new WarehouseApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.WarehouseEdit(data));
	let rtn = { dto: null, resultDto: result } as WarehouseEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Warehouse, payload: rtn });
	setLoader("REMOVE", "EditWarehouse");
	return rtn;
}
export async function GetWarehouseCreateResource(id?: string) {
	setLoader("ADD", "GetWarehouseEditResource");
	let api = new WarehouseApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<WarehouseViewModelResource>>>(() => api.WarehouseGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as WarehouseEdit;
	rootStore.dispatch({ type: GET_EDIT_Warehouse, payload: rtn });
	setLoader("REMOVE", "GetWarehouseEditResource");
	return rtn.dto;
}

// export const ChangeStatusWarehouseViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusWarehouseViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new WarehouseApi();
//     let result = await ApiCallWithErrorHandling(() => api.WarehouseItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Warehouse_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as WarehouseViewModelDetail });

//         let list = await localforage.getItem(Warehouse_LOCAL) as WarehouseViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Warehouse_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusWarehouseViewModel");
//     return
// }

// export const ChangeStatusWarehouseRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusWarehouseRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new WarehouseApi();
//     let result = await ApiCallWithErrorHandling(() => api.WarehouseItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Warehouse_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as WarehouseViewModelDetail });

//         let list = await localforage.getItem(Warehouse_LOCAL) as WarehouseViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Warehouse_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusWarehouseRejected");
//     return
// }

// export const RejectWarehouseViewModel = async (id: number) => {
//     setLoader("ADD", "RejectWarehouseViewModel");
//     let api = new WarehouseApi();
//     let result = await ApiCallWithErrorHandling(() => api.WarehouseItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Warehouse_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as WarehouseViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectWarehouseViewModel");
//     return
// }


