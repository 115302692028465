import { ResultDto } from "../Model/CommonModel";
import { ProcessImageRequestSave } from "../Model/InvoiceAreasModel";
import { GetHeader, basePath } from "./Common/configuration";

export class InvoiceAreasApi {
    GetInvoiceAreas = async (): Promise<ResultDto<ProcessImageRequestSave[]>> => {
      const options = {
        method: "Get",
        headers: await GetHeader(),  
      };
      let response = await fetch(basePath + "Movimentazione/GetPositions", options);
      var res = await response.json();
      return res;
    }
   AddInvoiceAreas = async (data :ProcessImageRequestSave): Promise<ResultDto<ProcessImageRequestSave[]>> => {
        const options = {
          method: "Post",
          headers: await GetHeader(),  
          body: JSON.stringify(data),

        };
        let response = await fetch(basePath + "Movimentazione/SavePositions", options);
        var res = await response.json();
        return res;
      }
      DeleteInvoiceAreas = async (data :string): Promise<ResultDto> => {
        const options = {
          method: "Delete",
          headers: await GetHeader(),  
 
        };
        let response = await fetch(basePath + "Movimentazione/SavePositions?name="+data, options);
        var res = await response.json();
        return res;
      }
}