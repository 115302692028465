import React, { useState, useEffect } from 'react'
import { FilterValueDto } from '../../Business/Common/CommonBusiness';
import { stringIsNullOrEmpty } from '../../Hook/Common/Utility';

interface Props {
    actionFilters: {
        getFiltriAttiviSearch(id: string, property: string): any,
    },
    property: string,
    overrideProperty: string
    FiltriAttivi: string | undefined
}

const FilterMenuSearchText: React.FC<Props> = (props) => {

    const [searchText, setSearchText] = useState<string>("")

    const Filter = (text: string) => {
        setSearchText(text)
        props.actionFilters.getFiltriAttiviSearch(text, props.property)
    }

    const Submit = (e: any, text: string) => {
        e.preventDefault();
        Filter(text)
    }

    const reset = () =>{
        Filter("")
        setSearchText("")
    }

    return (
        <div className="relative">
            <div className="filterSearch d-flex flex-row justify-content-between w-100">
                <form className="d-flex flex-row w-100" onSubmit={(e) => Submit(e, searchText)}>
                    <button type="submit" className="btn btnIcon btnTransaparent p-0" >
                        <img className="mr-1" src={require(`../../svg/Search_Gray.png`)}></img>
                    </button>
                    <input className="filterSearch w-100" value={!stringIsNullOrEmpty( props.FiltriAttivi) ? props.FiltriAttivi : searchText } onChange={(e) => setSearchText(e.target.value)} placeholder="Search" type="text"></input>
                </form>
                {!stringIsNullOrEmpty( searchText) || !stringIsNullOrEmpty( props.FiltriAttivi) ?
                    <button type="button" className="btn btnTransaparent p-0" style={{}} onClick={() => reset()}>
                        <img className="ml-1" height="10" src={require(`../../svg/close.png`)}></img>
                    </button>
                    : null}
            </div>
        </div>
    )
}

export default FilterMenuSearchText