import React, { useContext } from "react";
import { DarkTheme } from "../../App";
import '../../Css/Filters.css'
import '../../Css/Index.css'

interface Props {
    action?(item1?: number | string | boolean, e?: any): any
    icon: string
    item1?: number | string | boolean
    buttonClassName?: string
    imageClassName?: string
    disabled?: boolean
    active?: boolean
}

const ActionButtonIcon: React.FC<Props> = (props) => {

    const darkTheme = useContext(DarkTheme)

    return (
        <button type="button" disabled={props.disabled ?? false} className={`btn btn-icon trasparent-bg mx-1 ${props.buttonClassName} buttonAction ${props.disabled ? "disabled" : ""} ${props.active ? "active" : ""}`}
            onClick={(e) => props.action && props.action(props.item1, e)}>
            <img alt="icon" className={props.imageClassName} src={props.icon}></img>
            {props.children}
        </button>
    )
}
export default ActionButtonIcon
