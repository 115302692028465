import React, { useState, useEffect, useRef } from 'react';
import { PositionAndDates } from '../Model/VehicleModels';
import { simplifyPath, normalizeDate } from '../Business/Common/CommonBusiness';

interface MappaConPercorsoProps {
  posizioni: PositionAndDates[];
}

const MappaConPosizioni: React.FC<MappaConPercorsoProps> = ({ posizioni }) => {
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const [posizioniSemplificate, setPosizioniSemplificate] = useState<PositionAndDates[]>([]);
  const [dateRange, setDateRange] = useState<number>(0);
  const [dateRangeMin, setDateRangeMin] = useState<number>(0);
  const [dateRangeMax, setDateRangeMax] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (posizioni.length === 0) return;

    const epsilon = 0.0001; // Adegua questo valore in base alle tue esigenze
    const simplifiedPositions = simplifyPath(posizioni, epsilon);
    setPosizioniSemplificate(simplifiedPositions);

    const timestamps = simplifiedPositions.map(p => normalizeDate(new Date(p.date)));
    setDateRangeMin(Math.min(...timestamps));
    setDateRangeMax(Math.max(...timestamps));
    setDateRange(Math.max(...timestamps));
    setSelectedDate(new Date(Math.max(...timestamps)).toLocaleDateString());
  }, [posizioni]);

  useEffect(() => {
    if (!mapContainerRef.current) return;

    const map = new google.maps.Map(mapContainerRef.current, {
      zoom: 12,
      center: { lat: 41.9028, lng: 12.4964 },
    });
    setGoogleMap(map);
  }, []);

  useEffect(() => {
    const updateMarkers = () => {
      // Pulisci i marker precedenti
      markers.forEach(marker => marker.setMap(null));
      setMarkers([]);

      const filteredPositions = posizioniSemplificate.filter(pos => normalizeDate(new Date(pos.date)) === dateRange);
      
      const newMarkers = filteredPositions.map(pos => {
        const marker = new google.maps.Marker({
          position: { lat: pos.lat, lng: pos.lng },
          map: googleMap,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 3,
            strokeColor: '#FF0000',
          },
        });

        const infoWindow = new google.maps.InfoWindow({
          content: `Velocità: ${pos.speed}, Data: ${pos.date}`,
        });

        marker.addListener('mouseover', () => infoWindow.open(googleMap, marker));
        marker.addListener('mouseout', () => infoWindow.close());

        return marker;
      });

      setMarkers(newMarkers);
    };

    if (googleMap && posizioniSemplificate.length > 0) {
      updateMarkers();

      // Centra la mappa sull'ultimo punto visualizzato, se presente
      if (posizioniSemplificate.length > 0) {
        const lastPosition = posizioniSemplificate[posizioniSemplificate.length - 1];
        googleMap.setCenter(new google.maps.LatLng(lastPosition.lat, lastPosition.lng));
      }
    }
  }, [googleMap, posizioniSemplificate, dateRange]);

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDateRange = Number(e.target.value);
    setDateRange(newDateRange);
    setSelectedDate(new Date(newDateRange).toLocaleDateString());
  };

  return (
    <div>
      <div ref={mapContainerRef} id="map" style={{ width: '100%', height: '400px' }}></div>
      <div style={{ marginTop: '10px', textAlign: 'center' }}>
        <input
          type="range"
          min={dateRangeMin}
          max={dateRangeMax}
          value={dateRange}
          onChange={handleRangeChange}
          style={{ width: '100%' }}
        />
        <div>Data Selezionata: {selectedDate}</div>
      </div>
    </div>
  );
};

export default MappaConPosizioni;
