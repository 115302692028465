import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ProformaApi } from "../../../Business/ProformaBusiness";
import { ResultDto } from "../../../Model/CommonModel";
import { DELETE_Proforma } from "../../../Model/ProformaModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function deleteProforma(id: string) {
	setLoader("ADD", "deleteProforma");
	let api = new ProformaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProformaDelete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_Proforma, payload: rtn });
	setLoader("REMOVE", "deleteProforma");
	return rtn;
}

// export async function DeleteDeepProforma(id: number) {
// 	let api = new ProformaApi();
// 	setLoader("ADD", "DeleteDeepProforma");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProformaDeleteDeep(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: DELETE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "DeleteDeepProforma");
// 	return rtn;
// }

// export async function GetRelatedRecordsProforma(id: number) {
// 	let api = new ProformaApi();
// 	setLoader("ADD", "GetRelatedRecordsProforma");
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProformaGetRelatedRecords(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	if (result?.warning) rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: NotifyType.error }));
// 	setLoader("REMOVE", "GetRelatedRecordsProforma");
// 	return rtn;
// }

// export async function RestoreProforma(id: number) {
// 	setLoader("ADD", "RestoreProforma");
// 	let api = new ProformaApi();
// 	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProformaRestore(id));
// 	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
// 	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
// 	rootStore.dispatch({ type: RESTORE_DESIGN_COMPONENT, payload: rtn });
// 	setLoader("REMOVE", "RestoreProforma");
// 	return rtn;
// }
