import React, { useEffect } from 'react'
import AziendaModal from '../../Screen/Azienda/AziendaModal'
import ProdottoModal from '../../Screen/Prodotto/ProdottoModal'
import { useOperationTableCrud } from '../../Hook/Grid/useOperationTableCrud'
import { AziendaViewModelResource } from '../../Model/Azienda'
import { GetAziendaCreateResource } from '../../Redux/Action/Azienda/AziendaCreateAction'
import { deleteAzienda } from '../../Redux/Action/Azienda/AziendaDeleteAction'
import { ProdottoViewModelResource } from '../../Model/ProdottoModels'
import { GetProdottoCreateResource } from '../../Redux/Action/Prodotto/ProdottoCreateAction'
import { deleteProdotto } from '../../Redux/Action/Prodotto/ProdottoDeleteAction'
import { ProductSizeViewModel } from '../../Model/ProductSizeModel'
import { GetProductSizeCreateResource } from '../../Redux/Action/ProductSize/ProductSizeCreateAction'
import { deleteProductSize } from '../../Redux/Action/ProductSize/ProductSizeDeleteAction'
import { WarehouseViewModelQuery, WarehouseViewModelResource } from '../../Model/Warehouse'
import { GetWarehouseCreateResource } from '../../Redux/Action/Warehouse/WarehouseCreateAction'
import { deleteWarehouse } from '../../Redux/Action/Warehouse/WarehouseDeleteAction'
import WarehouseModal from '../../Screen/Warehouse/WarehouseModal'
import ProductSizeModal from '../../Screen/ProductSize/ProductSizeModal'
import { GetAziendaViewModel } from '../../Redux/Action/Azienda/AziendaGridAction'
import { GetProdotto } from '../../Redux/Action/Prodotto/ProdottoGridAction'
import { GetWarehouseViewModel } from '../../Redux/Action/Warehouse/WarehouseGridAction'
import { GetProductSizeViewModel } from '../../Redux/Action/ProductSize/ProductSizeGridAction'



interface Props {
    action: {
        closeModal(changed: boolean): any | void
        onCreate?: (data: AziendaViewModelResource | ProdottoViewModelResource | ProductSizeViewModel | any) => void
    }
    isVisibleModal: boolean
    edit: boolean
    new: boolean
    id?: string | null
    type: string | "" | "Azienda" | "Prodotto" | "ProductSize" | "InWerahouse"
}
export const ModalSelector: React.FC<Props> = (props) => {





    const { New: NewWarehouse, Edit: EditWarehouse, isVisibleModal: isWarehouseVisibleModal, edit: editWarehouse, confirm: confirmWarehouse, setConfirm: setConfirmWarehouse, closeModal: closeWarehouseModal, Delete: DeleteWarehouse, localStateHistory: WarehouseStateHistory, setLocalState: setWarehouseLocalState } = useOperationTableCrud<WarehouseViewModelResource, WarehouseViewModelResource>(
        GetWarehouseCreateResource,
        GetWarehouseCreateResource,
        deleteWarehouse,
    );

    const { New: NewAzienda, Edit: EditAzienda, isVisibleModal: isAziendaVisibleModal, edit: editAzienda, confirm: confirmAzienda, setConfirm: setConfirmAzienda, closeModal: closeAziendaModal, Delete: DeleteAzienda, localStateHistory: aziendaStateHistory, setLocalState: setAziendaLocalState } = useOperationTableCrud<AziendaViewModelResource, AziendaViewModelResource>(
        GetAziendaCreateResource,
        GetAziendaCreateResource,
        deleteAzienda,
    );
    const { New: NewProdotto, Edit: EditProdotto, isVisibleModal: isProdottoVisibleModal, edit: editProdotto, confirm: confirmProdotto, setConfirm: setConfirmProdotto, closeModal: closeProdottoModal, Delete: DeleteProdotto, localStateHistory: prodottoStateHistory, setLocalState: setProdottoLocalState } = useOperationTableCrud<ProdottoViewModelResource, ProdottoViewModelResource>(
        GetProdottoCreateResource,
        GetProdottoCreateResource,
        deleteProdotto,
    );
    const { New: NewProductSize, Edit: EditProductSize, isVisibleModal: isProductSizeVisibleModal, edit: editProductSize, confirm: confirmProductSize, setConfirm: setConfirmProductSize, closeModal: closeProductSizeModal, Delete: DeleteProductSize, localStateHistory: ProductSizeStateHistory, setLocalState: setProductSizeLocalState } = useOperationTableCrud<ProductSizeViewModel, ProductSizeViewModel>(
        GetProductSizeCreateResource,
        GetProductSizeCreateResource,
        deleteProductSize,
    );
    useEffect(() => {
        if (props.new) {
            switch (props.type) {
                case 'Azienda':
                    NewAzienda();
                    break;
                case 'Prodotto':
                    NewProdotto();
                    break;
                case 'ProductSize':
                    NewProductSize();
                    break;
                case 'Warehouse':
                    NewWarehouse();
                    break;
                default:
                    break;
            }
        }
    }, [props.type, props.edit, props.new]);

    useEffect(() => {
        if (props.id && props.edit) {
            switch (props.type) {
                case 'Azienda':
                    EditAzienda(props.id);
                    break;
                case 'Prodotto':
                    EditProdotto(props.id);
                    break;
                case 'ProductSize':
                    EditProductSize(props.id);
                    break;
                case 'Warehouse':
                    EditWarehouse(props.id);
                    break;
                default:
                    break;
            }
        }
    }, [props.id, props.type, props.edit]);

    const ReturnModal = (type: string) => {

        switch (type) {
            case "Azienda":
                return <AziendaModal action={{
                    closeModal: () => { props.action.closeModal(false); GetAziendaViewModel({}); },
                    onCreate: (newAzienda) => {
                        props.action.onCreate && props.action.onCreate(newAzienda)
                    },
                }} isVisibleModal={isAziendaVisibleModal} edit={editAzienda} />;
            case "Prodotto":
                return <ProdottoModal action={{
                    closeModal: () => { props.action.closeModal(false); GetProdotto({}) },
                    onCreate: (newProdotto) => {
                        props.action.onCreate && props.action.onCreate(newProdotto)
                    }
                }} isVisibleModal={isProdottoVisibleModal} edit={editProdotto} />;
            case "Warehouse":
                return <WarehouseModal action={{
                    closeModal: () => { props.action.closeModal(false); GetWarehouseViewModel({} as WarehouseViewModelQuery) },
                    onCreate: (newWarehouse) => {
                        props.action.onCreate && props.action.onCreate(newWarehouse)
                    }
                }} isVisibleModal={isWarehouseVisibleModal} edit={editWarehouse} />;
            case "ProductSize":
                return <ProductSizeModal action={{
                    closeModal: () => { props.action.closeModal(false); GetProductSizeViewModel({}); },
                    onCreate: (newProductSize) => {
                        props.action.onCreate && props.action.onCreate(newProductSize)
                    }
                }} isVisibleModal={isProductSizeVisibleModal} edit={editProductSize} />;
            default:
                return;
        }
    };

    return (
        <>
            {ReturnModal(props.type)}
        </>
    )
}


