import { useEffect, useState } from "react";
import { MultiValue } from "react-select";
import { DataModalConfirm, IOverrideBehavior, stateConfirm } from "../../Model/CommonModel";
import { setNotification } from "../../Redux/Action/NotificationAction";
import { NotifyType } from "../../Redux/Reducer/NotificationReducer";
import { rootStore } from "../../Redux/Store/rootStore";
import { lowerFirstLetter } from "../Common/Utility";

export function useFormTableCrud<FormObject extends {}>(CreateSaveFunction?: Function, EditSaveFunction?: Function, GetData?: Function) {
	let behavior: IOverrideBehavior[] = [];
	const [changed, setChanged] = useState<boolean>(false);
	const [validation, setValidation] = useState<{ response: boolean | null; property?: string[] } | null>(null);
	const [confirmForm, setConfirm] = useState<DataModalConfirm>(stateConfirm);
	const [formData, setFormData] = useState<FormObject | null>();
	const [inputValue, setInputValue] = useState<string>("");


	useEffect(() => {
 	}, [formData])

	//SALVA EDIT & NEW
	const save = (formData, edit: boolean, validationFunction: Function, callbackFunctionOnSave: Function, callbackFunctionRestore?: Function, orphanDeleted?: boolean) => {
		let copy = { ...formData };
		if (validationFunction(copy).response === true) {
			if (edit) {
				EditSaveFunction && EditSaveFunction(formData, orphanDeleted).then((x) => {
					if (x.resultDto?.warning === false && GetData) {
						GetData(x.resultDto?.data)
					}
					if (x.resultDto?.warning === false && callbackFunctionOnSave !== undefined) {
						callbackFunctionOnSave(x.resultDto?.data);
					}
				});
			} else {

				CreateSaveFunction && CreateSaveFunction(formData, orphanDeleted).then((x) => {
 					if (x.resultDto?.warning === false && GetData) {
						GetData(x.resultDto?.data)
					}
					if (callbackFunctionOnSave !== undefined) {
						callbackFunctionOnSave(x.resultDto?.data);
					}
				});
			}
		} else {
			rootStore.dispatch(setNotification({ message: "Check the fields entered", notifyType: NotifyType.warning }));
			return;
		}
	};

	const CancelConfirm = () => {
		setConfirm(stateConfirm);
	};

	const onChange = async (property: string, event: any, overrideBehavior: IOverrideBehavior | IOverrideBehavior[] | undefined = undefined) => {
 		setChanged(true);
 		var val: any = "";
		if (event.currentTarget.type === "checkbox") val = event.currentTarget.checked;
		else val = event.currentTarget.value;

		if (event.currentTarget.type === "radio" && (val === "true" || val === "false")) {
			val = val === "true";
		}

		let copy = { ...formData } as FormObject;
		copy[lowerFirstLetter(property)] = val;
		if (overrideBehavior) {
			behavior = behavior.concat(overrideBehavior);
			behavior.forEach(async (item) => {
				if (item.overrideProperty !== undefined && item.overrideProperty !== null) {
					if (property === item.overrideProperty) {
						copy = await item.operation(copy);
					}
				} else {
					copy = await item.operation(copy);
				}
			});
		}

		setFormData(copy);

		//Rimuovi Validazione
		if (validation?.property?.includes(property)) {
			let copy = { ...validation, property: [...validation.property] };
			let idxOfProperty = copy.property.indexOf(property);
			copy.property.splice(idxOfProperty, 1);
			setValidation(copy);
		}
	};
	const onChangeForceFormData = async (property: string,value :string,formValue:FormObject  ) => {
  		setChanged(true);
 		var val: any = value;
		let copy = { ...formValue } as FormObject;
		copy[lowerFirstLetter(property)] = val;	  
		setFormData(copy); 
		//Rimuovi Validazione
		if (validation?.property?.includes(property)) {
			let copy = { ...validation, property: [...validation.property] };
			let idxOfProperty = copy.property.indexOf(property);
			copy.property.splice(idxOfProperty, 1);
			setValidation(copy);
		}
	};

	//CHANGE DROPSELECT
	const onChangeSelect = (property: string, obj: { value: any; label: string }, overrideBehavior: IOverrideBehavior | IOverrideBehavior[] | undefined = undefined) => {
		setChanged(true);
		// setValidation(null);

		if (validation?.property?.includes(property)) {
			let copy = { ...validation, property: [...validation.property] };
			let idx = copy.property.indexOf(property);
			copy.property.splice(idx, 1);
			setValidation(copy);
		}

		let copy = { ...formData } as FormObject;
		copy[lowerFirstLetter(property)] = obj && obj["value"];
		if (overrideBehavior) {
			behavior = behavior.concat(overrideBehavior);
			behavior.forEach(async (item) => {
				if (item.overrideProperty !== undefined && item.overrideProperty !== null) {
					if (property === item.overrideProperty) {
						copy = await item.operation(copy);
						setFormData(copy);
					}
				} else {
					copy = await item.operation(copy);
					setFormData(copy);
				}
			});
		}
		setFormData(copy);

		//Rimuovi Validazione
		if (validation?.property?.includes(property)) {
			let copy = { ...validation, property: [...validation.property] };
			let idxOfProperty = copy.property.indexOf(property);
			copy.property.splice(idxOfProperty, 1);
			setValidation(copy);
		}
	};
	// function setMultipleRole(e: MultiValue<{ value: string; label: string; }>): void {
	//   var copy = {...formData} as UserModelResource ;
	//   copy.role = e.map(x=> {return {role:x.value,claim:[]} as RoleModel})
	//   setFormData(copy);
	// }
	const onChangeMultipleSelect = (property: string, obj: MultiValue<{ value: string; label: string; }>, overrideBehavior: IOverrideBehavior | IOverrideBehavior[] | undefined = undefined, reset: boolean = false) => {
		setChanged(true);
		setValidation(null);
		let copy = { ...formData } as FormObject;
		if (reset) {
			copy[lowerFirstLetter(property)] = [];
		}
		if (obj != null) {
			copy[lowerFirstLetter(property)].push(obj && obj["key"]);
		}
		if (overrideBehavior) {
			behavior = behavior.concat(overrideBehavior);
			behavior.forEach(async (item) => {
				if (item.overrideProperty !== undefined && item.overrideProperty !== null) {
					if (property === item.overrideProperty) {
						copy = await item.operation(copy);
					}
				} else {
					copy = await item.operation(copy);
				}
			});
		}
		setFormData(copy);
	};

	const promiseSelect = (x, functionToSearch: (x: string) => { key: number; value: string }) =>
		new Promise((resolve) => {
			setTimeout(() => {
				resolve(functionToSearch(inputValue));
			}, 1);
		});

	const onChangeDate = (property: string, dateEvent: any, overrideBehavior: IOverrideBehavior | IOverrideBehavior[] | undefined = undefined) => {
		setChanged(true);
		let copy = { ...formData } as FormObject;
		let newDate=new Date(dateEvent.currentTarget.value);
		if (newDate == null) {
			copy[lowerFirstLetter(property)] = null;
		} else {
			// const DateString = `${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`;
			copy[lowerFirstLetter(property)] = newDate;
		}

		if (overrideBehavior) {
			behavior = behavior.concat(overrideBehavior);
			behavior.forEach(async (item) => {
				if (item.overrideProperty !== undefined && item.overrideProperty !== null) {
					if (property === item.overrideProperty) {
						copy = await item.operation(copy);
					}
				} else {
					copy = await item.operation(copy);
				}
			});
		}

		setFormData(copy);

		//Rimuovi Validazione
		if (validation?.property?.includes(property)) {
			let copy = { ...validation, property: [...validation.property] };
			let idxOfProperty = copy.property.indexOf(property);
			copy.property.splice(idxOfProperty, 1);
			setValidation(copy);
		}
	};

	return {
		formData,
		setFormData,
		save,
		changed,
		validation,
		setValidation,
		onChange,
		onChangeSelect,
		setChanged,
		onChangeMultipleSelect,
		onChangeDate,
		inputValue,
		setInputValue,
		promiseSelect,
		confirmForm,
		onChangeForceFormData
	};
}
