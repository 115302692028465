import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { UserListApi } from "../../../Business/UserListBusiness";
import { ResultDto } from "../../../Model/CommonModel";
 import { CREATE_User, UserModel, EDIT_User, UserCreate, UserModelResource, GET_CREATE_User } from "../../../Model/UserModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetUserCreateResource( id?:string) {
	setLoader("ADD", "GetUserCreateResource");
	let api = new UserListApi();

	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto<UserModelResource>>>(() => api.UserGetResource(id));
	let rtn = { resultDto: null, dto: createResource?.data } as UserCreate;
	rootStore.dispatch({ type: GET_CREATE_User, payload: rtn });
	setLoader("REMOVE", "GetUserCreateResource");
	return rtn.dto;
}


export async function CreateUser(data: UserModel) {
	setLoader("ADD", "CreateUser");
	let api = new UserListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.UserListCreate(data ));
	let rtn = { dto: null, resultDto: result } as UserCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_User, payload: rtn });
	setLoader("REMOVE", "CreateUser");
	return rtn;
}
