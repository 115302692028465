 import { ADD_ProductSize_GRID, GET_ProductSize_FILTERS, GET_ProductSize_GRID, ProductSizeViewModelGrid } from '../../../Model/ProductSizeModel';

const initState: ProductSizeViewModelGrid = {
    gridResult: null,
    filter: null,
}

export const ProductSizeGridReducer = (state = initState, action: { type: string, payload: ProductSizeViewModelGrid }) => {
  if(action.payload != null && action.payload ){
  const {gridResult,filter}  = action.payload;
  switch (action.type) {
        case GET_ProductSize_GRID:
        case ADD_ProductSize_GRID:
            {
                return { ...state,gridResult }
            }
        case GET_ProductSize_FILTERS:
             return { ...state, filter }
        default:
            return state;
    }
}
return state;}

