import { CustomGridRender, FilterValueDto, RenderDetail, ResultDto } from "./CommonModel";

/**
 * 
 * @export
 * @interface AziendaViewModel
 */
 export interface AziendaViewModel {
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    imagePath?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModel
     */
    piva?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AziendaViewModel
     */
    seller?: boolean;
    tipo?: string;
}
/**
 * 
 * @export
 * @interface AziendaViewModelCustomGridRender
 */
export interface AziendaViewModelCustomGridRender {
    /**
     * 
     * @type {Array<RenderDetail>}
     * @memberof AziendaViewModelCustomGridRender
     */
    render?: Array<RenderDetail>;
}
/**
 * 
 * @export
 * @interface AziendaViewModelAzienda
 */
export interface AziendaViewModelAzienda {
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    imagePath?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelAzienda
     */
    piva?: string;
    /**
     * 
     * @type {number}
     * @memberof AziendaViewModelAzienda
     */
    quantita?: number;    
    tipo?: string;
}
/**
 * 
 * @export
 * @interface AziendaViewModelQuery
 */
export interface AziendaViewModelQuery {
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelQuery
     */
    lang?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelQuery
     */
    sortBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AziendaViewModelQuery
     */
    isSortAscending?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AziendaViewModelQuery
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof AziendaViewModelQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AziendaViewModelQuery
     */
    name?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AziendaViewModelQuery
     */
    description?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AziendaViewModelQuery
     */
    location?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AziendaViewModelQuery
     */
    phone?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AziendaViewModelQuery
     */
    email?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AziendaViewModelQuery
     */
    piva?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AziendaViewModelQuery
     */
    website?: Array<string>;
    tipo?:  Array<string>;
}
/**
 * 
 * @export
 * @interface AziendaViewModelQueryResultResponse
 */
export interface AziendaViewModelQueryResultResponse {
    /**
     * 
     * @type {number}
     * @memberof AziendaViewModelQueryResultResponse
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<AziendaViewModel>}
     * @memberof AziendaViewModelQueryResultResponse
     */
    items?: Array<AziendaViewModel>;
    /**
     * 
     * @type {AziendaViewModelCustomGridRender}
     * @memberof AziendaViewModelQueryResultResponse
     */
    gridRender?: AziendaViewModelCustomGridRender;
}
/**
 * 
 * @export
 * @interface AziendaViewModelQueryResultResponseResponseResult
 */
export interface AziendaViewModelQueryResultResponseResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof AziendaViewModelQueryResultResponseResponseResult
     */
    warning?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelQueryResultResponseResponseResult
     */
    info?: string;
    /**
     * 
     * @type {AziendaViewModelQueryResultResponse}
     * @memberof AziendaViewModelQueryResultResponseResponseResult
     */
    data?: AziendaViewModelQueryResultResponse;
}
/**
 * 
 * @export
 * @interface AziendaViewModelResource
 */
export interface AziendaViewModelResource {
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    imagePath?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    piva?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AziendaViewModelResource
     */
    seller?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResource
     */
    tipo?: string;
}
/**
 * 
 * @export
 * @interface AziendaViewModelResourceResponseResult
 */
export interface AziendaViewModelResourceResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof AziendaViewModelResourceResponseResult
     */
    warning?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AziendaViewModelResourceResponseResult
     */
    info?: string;
    /**
     * 
     * @type {AziendaViewModelResource}
     * @memberof AziendaViewModelResourceResponseResult
     */
    data?: AziendaViewModelResource;
}


export interface AziendaViewModelGrid {
    gridResult: AziendaListQueryResultDto | null
    filter: FilterValueDto[] | null
  }
   
  
  export interface AziendaModelDetail {
    dtoEdit: AziendaViewModelResource | null,
    resultDto: ResultDto<null> | null,
  }
   
  export interface AziendaCreate {
    dto: AziendaViewModelResource | null,
    resultDto: ResultDto<null> | null,
  }
  export interface AziendaEdit extends AziendaCreate{
    
  }
  export interface AziendaModelDownloaded {
    dtoDownload: number[],
  }
  
  export interface AziendaListQueryResultDto {
    /**
     *
     * @type {number}
     * @memberof AziendaListQueryResultDto
     */
    totalItems?: number;
    /**
     *
     * @type {Array<AziendaList>}
     * @memberof AziendaListQueryResultDto
     */
    items?: Array<AziendaViewModel>;
    /**
     *
     * @type {CustomGridRender}
     * @memberof AziendaListQueryResultDto
     */
    gridRender?: CustomGridRender;
  }
  export interface AziendaQueryResultDto {
    /**
     * 
     * @type {number}
     * @memberof AziendaViewModelQueryResultResponse
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<AziendaViewModel>}
     * @memberof AziendaViewModelQueryResultResponse
     */
    items?: Array<AziendaViewModel>;
    /**
     * 
     * @type {AziendaViewModelCustomGridRender}
     * @memberof AziendaViewModelQueryResultResponse
     */
    gridRender?: RenderDetail[];
  }
  
  
  export const GET_Azienda_GRID = "GET_Azienda_GRID"
  export const ADD_Azienda_GRID = "ADD_Azienda_GRID"
  export const GET_Azienda_FILTERS = "GET_Azienda_FILTERS"
  export const GET_Azienda_DETAIL = "GET_Azienda_DETAIL"
  export const GET_EDIT_Azienda = "GET_EDIT_Azienda"
  export const EDIT_Azienda = "EDIT_Azienda"
  export const CREATE_Azienda = "CREATE_Azienda"
  export const GET_CREATE_Azienda = "GET_CREATE_Azienda"
  export const DELETE_Azienda = "DELETE_Azienda"
  
  
  //#region offline definition
  
  export const Azienda_LOCAL = "Azienda_LOCAL"
  export const Azienda_FILTERS_LOCAL = "Azienda_FILTERS_LOCAL"
  
  export const Azienda_GRIDRENDER = "Azienda_GRIDRENDER"
  
  //#endregion
  