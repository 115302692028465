import React, { useEffect, useState } from 'react';
import CheckboxComponent from '../../../Components/FormAndFilters/CheckboxComponent';
import InputNumberComponent from '../../../Components/FormAndFilters/InputNumberComponent';
import InputTextComponent from '../../../Components/FormAndFilters/InputTextComponent';
import SelectComponent from '../../../Components/FormAndFilters/SelectComponent';
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon';
import { GetProdottoMovimentazione } from '../../../Redux/Action/Movimentazione/MovimentazioneGridAction';
import MovimentazioneVariant from '../Variant/MovimentazioneVariant';
import ProductInWarehouseComponentOut from '../Warehouse/ProductInWarehouseComponentOut';


const MovimentazioneFormComponent = ({
  i,
  x,
  removeMovimentazioneVariantCanvas,
  onchangeMovimentazioneVariantCanvas,
  onchangeMovimentazioneVariantCanvascreaInDatabase,
  GetProductSizeCanvasValue,
  ProductSizeResource,
  SetProductSizeCanvasSelect,
  GetProductAziendaCanvasValue,
  AziendeResource,
  SetProductAziendaCanvasSelect,
  addInWarehouseCanvas,
  formData,
  removeInWarehouseCanvas,
  onchangeMovimentazioneVariantCanvasInWarehouse,
  GetWarehouseCanvasValue,
  WarehouseResource,
  SetWarehouseCanvasSelect,
  onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut,
  returnProduct
}) => {

  const [shouldUpdate, setShouldUpdate] = useState(true);
  useEffect(() => {
    const updateModel = async () => {
      // Logica per aggiornare il modello sul server basato sulle modifiche rilevate
      if(shouldUpdate){
      console.log('Aggiornamento modello per cambiamenti su name o aziendaId');
      if (x.productVariant?.name && x.productVariant?.aziendaId && x.productVariant?.sizeId) {
        var tes = await GetProdottoMovimentazione(x.productVariant.name, x.productVariant.aziendaId, x.productVariant.sizeId);
        if (tes.warning === false) {
          setShouldUpdate(false); // Impedisce ulteriori aggiornamenti
          returnProduct(tes.data, i);
        }
      }
    }
    };

    updateModel();
   return () => setShouldUpdate(true);
  }, [x.productVariant.name, x.productVariant.aziendaId, x.productVariant.sizeId]);

  return (
    <MovimentazioneVariant key={"daii" + i} model={x} productName={x.productVariant.name} showWarehouse={true}

      action={{
        removeVariant(data) {
          removeMovimentazioneVariantCanvas(i);
        },
      }}
    >
      <>
          <div className='col-12  '>
            <InputTextComponent type='text' value={x.productVariant.name} onChange={(n, e) => onchangeMovimentazioneVariantCanvas(x, e, "productName", i)} name={'productName'} title={'Nome'} />
          </div>

        <div className='col-12 col-lg-6'>
          <InputNumberComponent value={x.productVariant.invoicePrice ?? x.price} onChange={(n, e) => onchangeMovimentazioneVariantCanvas(x, e, "price", i)} name={'price'} title={'Price'} />
        </div>
        <div className='col-12 col-lg-6'>
          {(x.productVariantId == null || x.productVariantId === undefined || x.productId == null || x.productId === undefined) ? <CheckboxComponent
            value={x.creaInDatabase}
            onChange={() => onchangeMovimentazioneVariantCanvascreaInDatabase(i, !x.creaInDatabase)}
            name={"crea"}
            title={x.productId == null || x.productId === undefined ? "crea prodotto e variante" : "☑️ prodotto presente in database crea variante"}
          ></CheckboxComponent> : <label>✅ presente in database</label>}
        </div>
          <div className='col-12'>
            <SelectComponent
              value={GetProductSizeCanvasValue(i)}
              options={ProductSizeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
              onChange={(e) => SetProductSizeCanvasSelect(e, i)}
              name={'sizeId'}
              title={'Size'}
              isMulti={false}
              modal={{ modalType: "ProductSize" }} />
          </div> 
     
          <div className='col-12'>
            <SelectComponent
              value={GetProductAziendaCanvasValue(i)}
              options={AziendeResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
              onChange={(e) => SetProductAziendaCanvasSelect(e, i)}
              name={'aziendaId'}
              title={'Azienda'}
              isMulti={false}
              modal={{ modalType: "Azienda" }} />
          </div>
        {/* <InputNumberComponent value={x.quantity} onChange={(n, e) => onchangeMoivimentazioneVariantCanvas(x, e, "quantity", i)} name={'quantity'} title={'Quantity'} /> */}
        <div className='bg-light mx-0 col-12 p-2 d-flex justify-content-center row w-100 border rounded container'>
        {formData?.operation === "In" || formData?.operation === 0 ?  <h4>Magazzino <button className='btn btnOutline' type='button' onClick={() => addInWarehouseCanvas(x, i)}>Aggiungi</button></h4> : null}

          {formData?.operation === "In" || formData?.operation === 0 ?

           x?.productVariant.inWarehouseViewModel?.map((s, index) => (
              <div className='col-12' key={"warehouse" + i + index}>
                <div className='d-flex justify-content-end'>
                  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => removeInWarehouseCanvas(i, index)} />
                </div>
                <InputNumberComponent value={s.quantity} onChange={(n, e) => onchangeMovimentazioneVariantCanvasInWarehouse(x, e, "quantity", i, index)} name={'quantity'} title={'Quantity'} />
                <SelectComponent
                  value={GetWarehouseCanvasValue(i, index)}
                  options={WarehouseResource?.items?.map(x => { return { value: x.id ?? "", label: x.name ?? "" }; })}
                  onChange={(e) => SetWarehouseCanvasSelect(e, i, index)}
                  name={'warehouseId'}
                  title={'Warehouse'}
                  isMulti={false}
                  modal={{ modalType: "Warehouse" }} />
                <InputTextComponent value={s.position ?? "none"} onChange={(n, e) => onchangeMovimentazioneVariantCanvasInWarehouse(x, e, "position", i, index)} name={'position'} title={'Position'} type={'text'} />
                <hr />
              </div>
            ))
            :
            <>
              {x?.productVariant.inWarehouseViewModel?.filter(x => x.id === undefined || x.id == null).map((s, index) => (
                <div className='col-12' key={"warehouse" + i + index}>
                  <div className='d-flex justify-content-end'>
                    <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => removeInWarehouseCanvas(i, index)} />
                  </div>
                  <InputNumberComponent value={s.quantity} onChange={(n, e) => onchangeMovimentazioneVariantCanvasInWarehouse(x, e, "quantity", i, index)} name={'quantity'} title={'Quantity'} />

                  <hr />
                </div>
              ))}
              {x?.productVariant.inWarehouseViewModelDb?.filter(x => x.id !== undefined || x.id !== null).map((s, index) => (
                <div className='col-12' key={"warehouse2" + i + index}>
                  <ProductInWarehouseComponentOut model={s} action={{
                    changeQuantity(value, id) {
                      onchangeMovimentazioneVariantCanvasInWarehouseQuantityOut(value, i, index);
                    },
                  }}></ProductInWarehouseComponentOut>
                </div>
              ))}
            </>}
        </div>
      </>
    </MovimentazioneVariant>
  );
};

export default MovimentazioneFormComponent;
