import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { ProductSizeViewModel, ProductSize_LOCAL, ProductSize_FILTERS_LOCAL } from "../../../Model/ProductSizeModel"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreProductSize = async (ProductSizedata: ProductSizeViewModel, fileName: string) => {
  let ProductSize: ProductSizeViewModel[] = await localforage.getItem(ProductSize_LOCAL) ?? [];
  // ProductSize.fileName = fileName;
  // ProductSize.on = formatDateWithTime(new Date())?.toString() ?? "";
  ProductSize.push(ProductSizedata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(ProductSize.map(item =>
    [item[key], item])).values()];
  localforage.setItem(ProductSize_LOCAL, arrayUniqueByKey);
}

export const StoreProductSizeFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(ProductSize_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(ProductSize_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


