import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_Movimentazione_GRID, MovimentazioneViewModelGrid, MovimentazioneViewModelQuery, MovimentazioneViewModel, Movimentazione_GRIDRENDER, ADD_Movimentazione_GRID, GET_Movimentazione_FILTERS, MovimentazioneQueryResultDto, MovimentazioneProductVariantViewModel } from '../../../Model/MovimentazioneModel';
import { FilterValueDto, QueryResultDto, ResultDto } from '../../../Model/CommonModel';
import { MovimentazioneApi, MovimentazioneApiOffline } from "../../../Business/MovimentazioneBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreMovimentazioneFilters } from './MovimentazioneStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetMovimentazioneViewModel = async (tableQuery: MovimentazioneViewModelQuery) => {
    setLoader("ADD", "GetMovimentazioneViewModel");
    let api = navigator.onLine ? new MovimentazioneApi():new MovimentazioneApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.MovimentazioneGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,Movimentazione_GRIDRENDER);
    rootStore.dispatch({ type: GET_Movimentazione_GRID, payload: { gridResult: result?.data, filter: null } as MovimentazioneViewModelGrid });
    setLoader("REMOVE", "GetMovimentazioneViewModel");

}
export const GetAddResultToGrid = async (tableQuery: MovimentazioneViewModelQuery, dataToConcat: MovimentazioneViewModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new MovimentazioneApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.MovimentazioneGetGrid(tableQuery));
    if (data && !arrayIsNullOrEmpty(data.data.items) && data.data.items !== undefined) {
        let arr = dataToConcat?.concat(data.data.items as any[])
        // data.totalItems = data.totalItems;
        data.data.items = arr;
    }
    rootStore.dispatch({ type: ADD_Movimentazione_GRID, payload: { gridResult: data?.data, filter: null } as MovimentazioneViewModelGrid });
    return data?.data.items
}


export async function GetFilterColumnMovimentazioneViewModel(columName: string, columValue: string, queryFilter?: MovimentazioneViewModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new MovimentazioneApi(): new MovimentazioneApiOffline();
    // setLoader("ADD", "GetFilterColumnMovimentazioneViewModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.MovimentazioneGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.MovimentazioneGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_Movimentazione_FILTERS, payload: { gridResult: null, filter: result?.data } as MovimentazioneViewModelGrid });

    result && StoreMovimentazioneFilters(result.data ?? [],columName);
    return result
}



export const GetProdottoMovimentazione = async (name: string, aziendaId: string,sizeId :string) :Promise<ResultDto<MovimentazioneProductVariantViewModel>>=> {
    // setLoader("ADD", "GetProdottoMovimentazione");
    let api = new MovimentazioneApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<MovimentazioneProductVariantViewModel>>>(() => api.GetProdottoMovimentazione(name,aziendaId,sizeId));
    return data
}

