import React, { useEffect, useState } from 'react';
import { useSelector,   } from 'react-redux';
import { RootState } from '../Redux/Store/rootStore';
import '../Css/Index.css'
import { NotifyType } from '../Redux/Reducer/NotificationReducer';
import ReactDOM from 'react-dom';

 interface Props {
    OnModal: boolean,
    ShowFixed?: boolean,
    isOpen: boolean,
    notifyType: NotifyType,
    message: string,
}


const  Notification: React.FC<Props> = (props) => {
     const [errorType, setErrorType] = useState("")
    const [isOpen, setIsOpen] = useState(props.isOpen)
    const error = props.message;

    const LayoutData = (state: RootState) => state.layoutReducer;
    let layout = useSelector(LayoutData);

    function handleClose() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen && !props.ShowFixed) {
            setTimeout(handleClose, 4000)
        }
    }, [isOpen === true])

    useEffect(() => {
        switch (props.notifyType) {
            case 0:
                return setErrorType("noNotify");
            case 1:
                return setErrorType("success");
            case 2:
                return setErrorType("error");
            case 3:
                return setErrorType("warning");
            default:
                return
        }
    }, [props.notifyType]);

    const rtnBreakPoint = () => {
        switch (layout.breakpoint) {
            case "xs":
            case "sm":
                return true
            default:
                return false
        }
    }

    return (
        <>
            {isOpen &&  (
                <div className={`d-flex ${rtnBreakPoint() ? "col px-4" : "px-3"} mb-1 justify-content-end`}>
                    <div className={`notification w-100 d-flex flex-row align-content-start ${errorType}`}>
                        <div className="col d-flex align-items-center">
                            <span className="regular">{error}</span>
                        </div>
                        <div>
                            <button className="btn btn-icon trasparent-bg d-flex align-items-center justify-content-center mt-2" onClick={() => handleClose()}>
                                <img className="" src={require("../img/close.png")} alt="close" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Notification;