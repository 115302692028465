import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { RoleApi } from "../../../Business/RoleBusiness";
import { ResultDto } from '../../../Model/CommonModel';
import { EDIT_Role, RoleCreate, RoleModelResource } from '../../../Model/RoleModels';
import { NotifyType } from '../../Reducer/NotificationReducer';
import { rootStore } from '../../Store/rootStore';
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';

export async function UpdateRole(data: RoleModelResource) {
	setLoader("ADD", "CreateRole");
	let api = new RoleApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.RoleEdit(data));
	let rtn = { dto: null, resultDto: result } as RoleCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Role, payload: rtn });
	setLoader("REMOVE", "CreateRole");
	return rtn;
}

// export const ChangeStatusRoleModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusRoleModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new RoleApi();
//     let result = await ApiCallWithErrorHandling(() => api.RoleItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Role_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as RoleModelDetail });

//         let list = await localforage.getItem(Role_LOCAL) as RoleModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Role_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusRoleModel");
//     return
// }

// export const ChangeStatusRoleRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusRoleRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new RoleApi();
//     let result = await ApiCallWithErrorHandling(() => api.RoleItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Role_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as RoleModelDetail });

//         let list = await localforage.getItem(Role_LOCAL) as RoleModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Role_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusRoleRejected");
//     return
// }

// export const RejectRoleModel = async (id: number) => {
//     setLoader("ADD", "RejectRoleModel");
//     let api = new RoleApi();
//     let result = await ApiCallWithErrorHandling(() => api.RoleItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Role_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as RoleModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectRoleModel");
//     return
// }


