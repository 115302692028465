import React, { SetStateAction, useContext } from 'react';
import { useSelector } from 'react-redux';
import { DarkTheme } from '../../App';
import '../../Css/AziendaList.css';
import { arrayIsNullOrEmpty } from '../../Hook/Common/Utility';
import { SelectFilterTypeGrid, SelectGridType } from '../../Hook/Grid/GridRender';
import { useFilterTableCrud } from '../../Hook/Grid/useFilterTableCrud';
import { DataModalConfirm, QueryObject, RenderDetail } from '../../Model/CommonModel';
import { WarehouseViewModel } from '../../Model/Warehouse';
import { GetFilterColumnWarehouseViewModel } from '../../Redux/Action/Warehouse/WarehouseGridAction';
import { RootState } from '../../Redux/Store/rootStore';
import WarehouseComponent from '../Component/Warehouse/WarehouseComponent';

interface Props {
  action: {
    setConfirm(data: DataModalConfirm): any
    setQuery(query: SetStateAction<QueryObject> | undefined): any
    Edit(id: string | undefined): any;
    onDelete(id: string | undefined): any;

  }
  isMobile: boolean;
  renderGrid: RenderDetail[] | undefined;
  data: WarehouseViewModel[]  
  query: QueryObject
  downloadedList: number[]
}

const WarehouseGrid: React.FC<Props> = (props) => {

  const darkTheme = useContext(DarkTheme)

  const getFiltersData = (state: RootState) => state.WarehouseGridReducer.filter;
  let filterData = useSelector(getFiltersData);

  const {
    filtriAttivi,
    resetFilter,
    closeAll,
    setDateToChildren,
    orderBy,
    resetFilterDate,
    getFilters,
    updateCount,
    getFiltriAttivi,
    count,
    checkFilterinValue,
    checkFilterDateinValue,
    isVisibleFiltriString,
    setIsVisibleFiltriString,
    getFiltriAttiviSearch
  } = useFilterTableCrud<QueryObject>(props.action.setQuery, GetFilterColumnWarehouseViewModel, props.query);


  const thAction = { orderBy };
  const actionFilterCheckbox = { closeAll, updateCount, getFiltriAttivi, orderBy, getFilters, checkFilter: checkFilterinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilter };
  const actionFilterSearch = { closeAll, updateCount, getFiltriAttiviSearch, orderBy };
  const actionFilterDate = { setDateToChildren, checkFilter: checkFilterDateinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilterDate };
  const thActionDate = { orderBy };

  return (
    <div className="row mx-0 col-12 p-0 d-flex justify-content-center">

      <div className="col-12 mx-0">

        {props.isMobile ? <div className='row'>
          {props.data?.map((item, i) => <WarehouseComponent key={item.id ?? "vabbe" + i + "asdasdas"}  model={item as WarehouseViewModel} action={{
            addWarehouse: function (data: WarehouseViewModel) {
              props.action.Edit(item.id);

            },removeWarehouse:()=> props.action.onDelete(item.id)
 

          }} />)}
        </div> :
          <table className="vma-table table   table-responsivetable-responsive table-striped w-100 minHeight">
            <thead>
              <tr className="intestazione border-top">
                {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((item, i) =>
                  SelectFilterTypeGrid(
                    item.propertyName,
                    item.propertyText,
                    item.type,
                    props.query?.isSortAscending,
                    filtriAttivi,
                    actionFilterDate,
                    props.query?.sortBy,
                    filterData,
                    count,
                    actionFilterCheckbox,
                    thAction,
                    thActionDate,
                    isVisibleFiltriString,
                    actionFilterSearch
                  )
                )}
                <th className="gridHead px-0"  >
                  <div className="row mx-0 d-flex align-content-start">
                    <div className="px-2 w-100 d-flex align-items-start flex-row justify-content-between labelTh align-content-start">
                      <div className="relative">
                        <div className="filterSearch d-flex flex-row justify-content-between w-100">
                          Action
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!arrayIsNullOrEmpty(props.data) ? props.data?.map((item, i) => (
                <tr className={`dati`} key={i}>
                  {props.renderGrid?.sort((a, b) => a.order - b.order)?.filter((x) => x.show)?.map((td, index) =>
                    SelectGridType(item[td.propertyName], td.propertyName, td.type))}
                  <td className={`dati`}>
                    <div className="cell">
                      <div className="d-flex flex-row">
                        <button type="button" className="btn btn-link" onClick={() => props.action.Edit(item.id)}>
                          <img className="btnEdit" src={require("../../img/edit.png")} alt="edit" />
                        </button>
                        <button type="button" className="btn btn-link" onClick={() => props.action.onDelete(item.id)}>
                          <img className="btnEdit" src={require("../../img/delete.png")} alt="delete" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>

              )) :
                <tr>
                  <div className="w-100 my-2">
                    <h5>La ricerca non ha prodotto risultati</h5>
                  </div></tr>
              }
            </tbody>
          </table>
        }
      </div>
    </div>
  );
}
export default WarehouseGrid
