import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {  ProformaViewModel, EDIT_Proforma, ProformaEdit, SendEmailFatturaViewModel,   } from '../../../Model/ProformaModels';
import { ProformaApi } from "../../../Business/ProformaBusiness";
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';
import { NotifyType } from '../../Reducer/NotificationReducer';
import { ResultDto } from '../../../Model/CommonModel';
import { GET_EDIT_Proforma } from '../../../Model/ProformaModels';

export async function UpdateProforma(data: ProformaViewModel) {
	setLoader("ADD", "EditProforma");
	let api = new ProformaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ProformaEdit(data));
	let rtn = { dto: null, resultDto: result } as ProformaEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Proforma, payload: rtn });
	setLoader("REMOVE", "EditProforma");
	return rtn;
}
export async function GetProformaCreateResource(id?: string) {
	setLoader("ADD", "GetProformaEditResource");
	let api = new ProformaApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<ProformaViewModel>>>(() => api.ProformaGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as ProformaEdit;
	rootStore.dispatch({ type: GET_EDIT_Proforma, payload: rtn });
	setLoader("REMOVE", "GetProformaEditResource");
	return rtn.dto;
}


export async function SendEMail(data: SendEmailFatturaViewModel) {
	setLoader("ADD", "SendEMail");
	let api = new ProformaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.SendEMail(data));
	let rtn = { dto: null, resultDto: result } as ProformaEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
 	setLoader("REMOVE", "SendEMail");
	return rtn;
}


export async function ReSendEMail(id: string) {
	setLoader("ADD", "ReSendEMail");
	let api = new ProformaApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ReSendEMail(id));
	let rtn = { dto: null, resultDto: result } as ProformaEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
 	setLoader("REMOVE", "ReSendEMail");
	return rtn;
}

// export const ChangeStatusProformaViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusProformaViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new ProformaApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProformaItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Proforma_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProformaViewModelDetail });

//         let list = await localforage.getItem(Proforma_LOCAL) as ProformaViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Proforma_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusProformaViewModel");
//     return
// }

// export const ChangeStatusProformaRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusProformaRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new ProformaApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProformaItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Proforma_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProformaViewModelDetail });

//         let list = await localforage.getItem(Proforma_LOCAL) as ProformaViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Proforma_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusProformaRejected");
//     return
// }

// export const RejectProformaViewModel = async (id: number) => {
//     setLoader("ADD", "RejectProformaViewModel");
//     let api = new ProformaApi();
//     let result = await ApiCallWithErrorHandling(() => api.ProformaItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Proforma_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as ProformaViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectProformaViewModel");
//     return
// }


