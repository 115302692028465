import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DataModalConfirm, stateConfirm } from "../../Model/CommonModel";
import { ResultDto } from "../../Model/CommonModel";

interface dispacthOperationKey {
	KeyCreateResource: string;
	KeyEdAziendaesource: string;
}

export function useOperationTableCrud<ModelCreate, ModelEdit>(
	functionForNewResource?: Function,
	functionForEdAziendaesource?: Function,
	functionForDelete?: (id: string) => Promise<ResultDto<null>>,
	functionCallbackDelete?: Function,
	// functionForRestore?: (id: number) => Promise<ResultDto>
) {
	const [isVisibleModal, setVisibleModal] = useState(false);
	const [edit, setEdit] = useState(false);
	const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm);
	const history = useHistory();
	const [localStateHistory, setLocalState] = useState<{ id: string | null; tab: string; prevPage: string } | undefined>();

	//APERTURE MODALI CARICAMENTO RISORSE
	const New = async () => {
		functionForNewResource && functionForNewResource().then((x) => {
			setEdit(false);
			setVisibleModal(true);
		});
	};

	const Edit = (id: string) => {
		functionForEdAziendaesource && functionForEdAziendaesource(id).then((x) => {
			setEdit(true);
			setVisibleModal(true);
		});
	};

	const Delete = (id: string, orphan?: boolean) => {
		setConfirm({
			title: orphan === true ? "Delete orphan record" : orphan === false ? "Delete record in use" : "Cancella",
			message:
				orphan === true
					? "This item is not currently in use in another entity, do you want to delete it?"
					: orphan === false
						? "This item is currently in use in another entity, do you want to delete it?"
						: "Vuoi eliminare questo elemento?",
			buttonText: "Cancella",
			isOpen: true,
			actions: {
				cancel: () => CancelConfirm(),
				confirm: () => ConfirmDelete(id),
			},
		});
	};

	const Cancel = () => {
		setConfirm({
			title: "Conferma",
			message: "Sei sicuro di voler uscire? Le modifiche non salvate andranno perse",
			buttonText: "Esci",
			isOpen: true,
			actions: {
				cancel: () => CancelConfirm(),
				confirm: () => closeModal(),
			},
		});
	};

	const closeModal = (changed?: boolean) => {
		
		if (changed) {
			Cancel();
		} else {
			setConfirm(stateConfirm);
			setVisibleModal(false);
			if (history.location.state != null && history.location.state !== undefined) {
				let location = { pathname: history.location.pathname, search: "", state: undefined };
				history.replace(location);
				// setLocalState(undefined);
			}
		}
	};

	//ANNULLA MODAL CONFIRM
	const CancelConfirm = () => {
		setConfirm(stateConfirm);
	};

	//CONFIRM MODALE CONFIRM DELETE
	const ConfirmDelete = (id: string) => {
		functionForDelete && functionForDelete(id).then((x) => {
 			if (x.warning === false && functionCallbackDelete !== undefined) {
				functionCallbackDelete();
			}
			CancelConfirm();

		});      
	};

	return { New, Edit, isVisibleModal, edit, confirm, closeModal, Delete, localStateHistory, setLocalState, setConfirm }; // Restore
}
