 import { ADD_Vehicle_GRID, GET_Vehicle_FILTERS, GET_Vehicle_GRID, VehicleViewModelGrid } from '../../../Model/VehicleModels';

const initState: VehicleViewModelGrid = {
    gridResult: null,
    filter: null,
}

export const VehicleGridReducer = (state = initState, action: { type: string, payload: VehicleViewModelGrid }) => {
  if(action.payload != null && action.payload ){
  const {gridResult,filter}  = action.payload;
  switch (action.type) {
        case GET_Vehicle_GRID:
        case ADD_Vehicle_GRID:
            {
                return { ...state,gridResult }
            }
        case GET_Vehicle_FILTERS:
             return { ...state, filter }
        default:
            return state;
    }
}
return state;}

