import {  CREATE_Vehicle, VehicleCreate,GET_CREATE_Vehicle } from "../../../Model/VehicleModels"

const initState: VehicleCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const VehicleCreateReducer = (state = initState, action: { type: string, payload: VehicleCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case CREATE_Vehicle:
            {

                return { ...state, resultDto }
            }
        case GET_CREATE_Vehicle:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
