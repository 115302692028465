import React from 'react'
import MaterialIcon from 'react-google-material-icons'
import ButtonActionIcon from '../../../Components/Various/ButtonActionIcon'
import ButtonActionIconGoogleIcon from '../../../Components/Various/ButtonActionIconGoogleIcon'
import { ProductSizeViewModel } from '../../../Model/ProductSizeModel'

interface Props {
  action?: {
    addProductSize?(data: ProductSizeViewModel): any | void,
    removeProductSize?(data: ProductSizeViewModel): any | void,
    chartBar?(data: ProductSizeViewModel): any | void,} 
  model: ProductSizeViewModel,
}
const ProductSizeComponent: React.FC<Props> = (props) => {



  return (
    <div className='col-12 mb-3'  >
      <div className='card '>
        <div className='card-title '>
          <div className='float-left mt-3'><MaterialIcon icon="star" height={30} /><label>{props.model.name}</label></div>
          <div className='float-right mt-3 mr-1 row'>
            {props.action && props.action.chartBar && <div className='col mr-0'>  <ButtonActionIconGoogleIcon icon="bar_chart" size={20} action={() => props.action && props.action.chartBar && props.action.chartBar(props.model)} /></div>}
            {props.action && props.action.addProductSize && <div className='col mr-0'> <ButtonActionIcon icon={require("../../../img/edit.png")} height={15} action={() => props.action && props.action.addProductSize && props.action.addProductSize(props.model)} /></div>}
            {props.action && props.action.removeProductSize && <div className='col '>  <ButtonActionIcon icon={require("../../../img/delete.png")} height={15} action={() => props.action && props.action.removeProductSize && props.action.removeProductSize(props.model)} /></div>}                 
            {props.children}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
              
            
            <div className='col-12 col-md-6'> <label><MaterialIcon icon="description" />{props.model.description} colli di default :{props.model.colliDefault}  </label></div>
           </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSizeComponent