import localforage from "localforage"
import { FilterValueDto } from "../../../Model/CommonModel";
import { VehicleViewModel, Vehicle_LOCAL, Vehicle_FILTERS_LOCAL } from "../../../Model/VehicleModels"
import { formatDateWithTime, formatTime } from '../../../Hook/Common/TimeAndDate';

export const StoreVehicle = async (Vehicledata: VehicleViewModel, fileName: string) => {
  let Vehicle: VehicleViewModel[] = await localforage.getItem(Vehicle_LOCAL) ?? [];
  // Vehicle.fileName = fileName;
  // Vehicle.on = formatDateWithTime(new Date())?.toString() ?? "";
  Vehicle.push(Vehicledata);
  const key = 'id';
  const arrayUniqueByKey = [...new Map(Vehicle.map(item =>
    [item[key], item])).values()];
  localforage.setItem(Vehicle_LOCAL, arrayUniqueByKey);
}

export const StoreVehicleFilters = async (filters: FilterValueDto[], column: string) => {
  if (filters && filters.length) {
    let filterList: FilterValueDto[] = await localforage.getItem(Vehicle_FILTERS_LOCAL + column) ?? [];
    var union = [...new Set([...filterList, ...filters])];
    const key = 'text';
    const arrayUniqueByKey = [...new Map(union.map(item =>
      [item[key], item])).values()];
    localforage.setItem(Vehicle_FILTERS_LOCAL + column, arrayUniqueByKey);
  }
}


