import { ResultDto, SaveGrid } from "../Model/CommonModel";
import { basePath, GetHeader } from "./Common/configuration";

export class GridApi {


  Delete = async (id: string): Promise<ResultDto > => {
    const options = {
      method: "DELETE",
      headers:await GetHeader(),
      body: JSON.stringify(id),
    };
    let response = await fetch(basePath + "Grid/Delete",options);
    return await response.json() as ResultDto ;
  }


  Save = async (data: SaveGrid): Promise<ResultDto > => {

    const options = {
      method: "POST",
      headers:await GetHeader(),
      body: JSON.stringify(data),
    };
    let response = await fetch(basePath + "Grid",options);
    return await response.json() as ResultDto ;
  }
}

//MANCANO LE OPTIONS*********************************
