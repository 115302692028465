import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_Warehouse_GRID, WarehouseViewModelGrid, WarehouseViewModelQuery, WarehouseViewModel, Warehouse_GRIDRENDER, ADD_Warehouse_GRID, GET_Warehouse_FILTERS, WarehouseQueryResultDto } from '../../../Model/Warehouse';
import { FilterValueDto, QueryResultDto, ResultDto } from '../../../Model/CommonModel';
import { WarehouseApi, WarehouseApiOffline } from "../../../Business/WarehouseBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreWarehouseFilters } from './WarehouseStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetWarehouseViewModel = async (tableQuery: WarehouseViewModelQuery) => {
    setLoader("ADD", "GetWarehouseViewModel");
    let api = navigator.onLine ? new WarehouseApi():new WarehouseApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.WarehouseGetGrid(tableQuery ));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,Warehouse_GRIDRENDER);
    rootStore.dispatch({ type: GET_Warehouse_GRID, payload: { gridResult: result?.data, filter: null } as WarehouseViewModelGrid });
    setLoader("REMOVE", "GetWarehouseViewModel");

}
export const GetAddResultToGrid = async (tableQuery: WarehouseViewModelQuery, dataToConcat: WarehouseViewModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new WarehouseApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.WarehouseGetGrid(tableQuery));
    if (data && !arrayIsNullOrEmpty(data.data.items) && data.data.items !== undefined) {
        let arr = dataToConcat?.concat(data.data.items as any[])
        // data.totalItems = data.totalItems;
        data.data.items = arr;
    }
    rootStore.dispatch({ type: ADD_Warehouse_GRID, payload: { gridResult: data?.data, filter: null } as WarehouseViewModelGrid });
    return data?.data.items
}


export async function GetFilterColumnWarehouseViewModel(columName: string, columValue: string, queryFilter?: WarehouseViewModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new WarehouseApi(): new WarehouseApiOffline();
    // setLoader("ADD", "GetFilterColumnWarehouseViewModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.WarehouseGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.WarehouseGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_Warehouse_FILTERS, payload: { gridResult: null, filter: result?.data } as WarehouseViewModelGrid });

    result && StoreWarehouseFilters(result.data ?? [],columName);
    return result
}


