import {  CREATE_Warehouse, WarehouseCreate,GET_CREATE_Warehouse, WarehouseViewModelResource } from "../../../Model/Warehouse"

const initState: WarehouseCreate = {
    dto: {        
     } as WarehouseViewModelResource , 
    resultDto:  null,
}

export const WarehouseCreateReducer = (state = initState, action: { type: string, payload: WarehouseCreate }) => {
    if (action.payload != null) {
        const { dto, resultDto } = action.payload;

        switch (action.type) {
            case CREATE_Warehouse:
                return { ...state, resultDto }
            case GET_CREATE_Warehouse:
                return { ...state, dto }
            default:
                return state;
        }
    }
    return state;
}
