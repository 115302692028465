import { rootStore } from '../../Store/rootStore';
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { GET_Trip_GRID, TripViewModelGrid, TripViewModelQuery, TripViewModel, Trip_GRIDRENDER, ADD_Trip_GRID, GET_Trip_FILTERS } from '../../../Model/TripModels';
import { FilterValueDto, QueryResultDto, ResultDto } from '../../../Model/CommonModel';
import { TripApi, TripApiOffline } from "../../../Business/TripBusiness";
import setLoader from '../LoaderAction';
import { arrayIsNullOrEmpty } from '../../../Hook/Common/Utility';
import { StoreTripFilters } from './TripStore';
import { StoreGridRender } from '../../../Business/Store/CommonStore';

export const GetTripViewModel = async (tableQuery: TripViewModelQuery) => {
    setLoader("ADD", "GetTripViewModel");
    let api = navigator.onLine ? new TripApi():new TripApiOffline();
    let result = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.TripGetGrid(tableQuery));
    result?.data?.gridRender && StoreGridRender(result?.data.gridRender ,Trip_GRIDRENDER);
    rootStore.dispatch({ type: GET_Trip_GRID, payload: { gridResult: result?.data, filter: null } as TripViewModelGrid });
    setLoader("REMOVE", "GetTripViewModel");

}
export const GetAddResultToGrid = async (tableQuery: TripViewModelQuery, dataToConcat: TripViewModel[]) => {
    setLoader("ADD", "GetAddResultToGrid");
    let api = new TripApi();
    let data = await ApiCallWithErrorHandling<Promise<ResultDto<QueryResultDto>>>(() => api.TripGetGrid(tableQuery));
    if (data && !arrayIsNullOrEmpty(data.data.items) && data.data.items !== undefined) {
        let arr = dataToConcat?.concat(data.data.items as any[])
        // data.totalItems = data.totalItems;
        data.data.items = arr;
    }
    rootStore.dispatch({ type: ADD_Trip_GRID, payload: { gridResult: data?.data, filter: null } as TripViewModelGrid });
    return data?.data.items
}


export async function GetFilterColumnTripViewModel(columName: string, columValue: string, queryFilter?: TripViewModelQuery) {

  let result: ResultDto<FilterValueDto[]> | undefined;
    let api = navigator.onLine ? new TripApi(): new TripApiOffline();
    // setLoader("ADD", "GetFilterColumnTripViewModel");
    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.TripGetFilters(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<ResultDto<FilterValueDto[]>>>(() => api.TripGetFilters(columName, columValue));
    }

    rootStore.dispatch({ type: GET_Trip_FILTERS, payload: { gridResult: null, filter: result?.data } as TripViewModelGrid });

    result && StoreTripFilters(result.data ?? [],columName);
    return result
}


