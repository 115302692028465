import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { TripApi } from "../../../Business/TripBusiness";
import { ResultDto } from '../../../Model/CommonModel';
import { EDIT_Trip, GET_EDIT_Trip, TripEdit, TripViewModel, } from '../../../Model/TripModels';
import { NotifyType } from '../../Reducer/NotificationReducer';
import { rootStore } from '../../Store/rootStore';
import setLoader from '../LoaderAction';
import { setNotification } from '../NotificationAction';

export async function UpdateTrip(data: TripViewModel) {
	setLoader("ADD", "EditTrip");
	let api = new TripApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.TripEdit(data));
	let rtn = { dto: null, resultDto: result } as TripEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_Trip, payload: rtn });
	setLoader("REMOVE", "EditTrip");
	return rtn;
}
export async function GetTripCreateResource(id?: string) {
	setLoader("ADD", "GetTripEditResource");
	let api = new TripApi();

	let EditResource = await ApiCallWithErrorHandling<Promise<ResultDto<TripViewModel>>>(() => api.TripGetResource(id));
	let rtn = { resultDto: null, dto: EditResource?.data } as TripEdit;
	rootStore.dispatch({ type: GET_EDIT_Trip, payload: rtn });
	setLoader("REMOVE", "GetTripEditResource");
	return rtn.dto;
}

// export const ChangeStatusTripViewModel = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusTripViewModel");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new TripApi();
//     let result = await ApiCallWithErrorHandling(() => api.TripItemChangeStatus(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Trip_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as TripViewModelDetail });

//         let list = await localforage.getItem(Trip_LOCAL) as TripViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Trip_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusTripViewModel");
//     return
// }

// export const ChangeStatusTripRejected = async (id: number, file?: Blob) => {
//     setLoader("ADD", "ChangeStatusTripRejected");
//     let model = { id: id, file: file } as ApprovedModel
//     let api = new TripApi();
//     let result = await ApiCallWithErrorHandling(() => api.TripItemChangeStatusRejected(model));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Trip_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as TripViewModelDetail });

//         let list = await localforage.getItem(Trip_LOCAL) as TripViewModel[]
//         let listFiltered = list?.filter(x => x.id !== id)

//         let arr = [] as number[]
//         listFiltered?.map(x => {
//             arr.push(x.id)
//         })
//         rootStore.dispatch({ type: "REPLACE_DOWNLOAD", payload: arr });
//         localforage.setItem(Trip_LOCAL, listFiltered)
//     }

//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "ChangeStatusTripRejected");
//     return
// }

// export const RejectTripViewModel = async (id: number) => {
//     setLoader("ADD", "RejectTripViewModel");
//     let api = new TripApi();
//     let result = await ApiCallWithErrorHandling(() => api.TripItemReject(id));
//     if (result && !result.warning) {
//         rootStore.dispatch({ type: GET_Trip_DETAIL, payload: { dtoEdit: result.data, resultDto: null } as TripViewModelDetail });
//     }
//     rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
//     setLoader("REMOVE", "RejectTripViewModel");
//     return
// }


