import {  CREATE_Proforma, ProformaCreate,GET_CREATE_Proforma } from "../../../Model/ProformaModels"

const initState: ProformaCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const ProformaCreateReducer = (state = initState, action: { type: string, payload: ProformaCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case CREATE_Proforma:
            {

                return { ...state, resultDto }
            }
        case GET_CREATE_Proforma:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
