import { FilterValueDto } from "../Business/Common/CommonBusiness";
import { CustomGridRender, QueryObject, RenderDetail, ResultDto } from "./CommonModel";



export interface ProductSizeViewModel {
  id: string;
  name: string;
  description: string;
  colliDefault?: number;
  productSizeGroupedViewModels?:ProductSizeGroupedViewModel[] | null
}
export interface ProductSizeViewModelQuery extends QueryObject {
  name?: string[];
  description?: string[];
  colliDefault?: number;
}
export interface ProductSizeGroupedViewModel {
  id: string;
  name: string;
  description: string | null;
  colliDefault: number;
}
/**
* 
* @export
* @interface ProductSizeViewModelQueryResultResponse
*/
export interface ProductSizeQueryResultDto {
  /**
   * 
   * @type {number}
   * @memberof ProductSizeViewModelQueryResultResponse
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<ProductSizeViewModel>}
   * @memberof ProductSizeViewModelQueryResultResponse
   */
  items?: Array<ProductSizeViewModel>;
  /**
   * 
   * @type {ProductSizeViewModelCustomGridRender}
   * @memberof ProductSizeViewModelQueryResultResponse
   */
  gridRender?: RenderDetail[];
}
/**
* 
* @export
* @interface ProductSizeViewModelResponseResult
*/
export interface ProductSizeViewModelResponseResult {
    /**
     * 
     * @type {boolean}
     * @memberof ProductSizeViewModelResponseResult
     */
    warning?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSizeViewModelResponseResult
     */
    info?: string;
    /**
     * 
     * @type {ProductSizeViewModel}
     * @memberof ProductSizeViewModelResponseResult
     */
    data?: ProductSizeViewModel;
  }
  export interface ProductSizeViewModelGrid {
    gridResult: ProductSizeListQueryResultDto | null
    filter: FilterValueDto[] | null
  }
  
  
  export interface ProductSizeModelDetail {
    dtoEdit: ProductSizeViewModel | null,
    resultDto: ResultDto<null> | null,
  }
  
  export interface ProductSizeCreate {
    dto: ProductSizeViewModel | null,
    resultDto: ResultDto<null> | null,
  }
  export interface ProductSizeEdit extends ProductSizeCreate {
  
  }
  export interface ProductSizeModelDownloaded {
    dtoDownload: number[],
  }


export interface ProductSizeListQueryResultDto {
    /**
     *
     * @type {number}
     * @memberof ProductSizeListQueryResultDto
     */
    totalItems?: number;
    /**
     *
     * @type {Array<ProductSizeList>}
     * @memberof ProductSizeListQueryResultDto
     */
    items?: Array<ProductSizeViewModel>;
    /**
     *
     * @type {CustomGridRender}
     * @memberof ProductSizeListQueryResultDto
     */
    gridRender?: CustomGridRender;
  }


export const GET_ProductSize_GRID = "GET_ProductSize_GRID"
export const ADD_ProductSize_GRID = "ADD_ProductSize_GRID"
export const GET_ProductSize_FILTERS = "GET_ProductSize_FILTERS"
export const GET_ProductSize_DETAIL = "GET_ProductSize_DETAIL"
export const GET_EDIT_ProductSize = "GET_EDIT_ProductSize"
export const EDIT_ProductSize = "EDIT_ProductSize"
export const CREATE_ProductSize = "CREATE_ProductSize"
export const GET_CREATE_ProductSize = "GET_CREATE_ProductSize"
export const DELETE_ProductSize = "DELETE_ProductSize"


//#region offline definition

export const ProductSize_LOCAL = "ProductSize_LOCAL"
export const ProductSize_FILTERS_LOCAL = "ProductSize_FILTERS_LOCAL"

export const ProductSize_GRIDRENDER = "ProductSize_GRIDRENDER"

//#endregion
