import * as pdfjs from "pdfjs-dist";
import { basePath } from "../Common/configuration";
pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js';

export async function PdfToImage(file: File): Promise<HTMLImageElement> {
  const loadingTask = pdfjs.getDocument(await file.arrayBuffer());
  const pdf = await loadingTask.promise;
  const page = await pdf.getPage(1);
  const viewport = page.getViewport({ scale: 1 });
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };
  await page.render(renderContext).promise;
  const image = new Image();
  image.src = canvas.toDataURL();
  return image;
}
export async function PdfToImageFile(file: File): Promise<File> {
  const formData = new FormData();
  formData.append("pdfFile", file, file?.name); 
 let response = await fetch(basePath+"Movimentazione/ConvertPdfToImage", {
    method: "POST",
    body: formData,
  });
  
   const blob = await response.blob();
  let fileName = file?.name.replace("pdf","jpeg")
 let fileReturn = blobToFile(blob,fileName);
  return fileReturn;
}
function blobToFile(blob: Blob, fileName: string): File {
  // Crea un oggetto File a partire dal blob
  const file = new File([blob], fileName, { type: blob.type });

  return file;
}