
import { rootStore } from "../../Redux/Store/rootStore";
import { NotifyType } from "../../Redux/Reducer/NotificationReducer";
import { setNotification } from "../../Redux/Action/NotificationAction";
import { ResultDto, ReturnFile } from "../../Model/CommonModel";
import { PositionAndDates } from "../../Model/VehicleModels";

/**
 *
 * @export
 * @class BaseAPI
 */

export const BASE_PATH = window.location.origin.replace(/\/+$/, "");

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name!: "RequiredError"
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface FilterValueDto
 */
export interface FilterValueDto {
  /**
   *
   * @type {string}
   * @memberof FilterValueDto
   */
  value: string;
  /**
   *
   * @type {string}
   * @memberof FilterValueDto
   */
  text: string;
}



export async function ApiCallWithErrorHandling<T extends Promise<ResultDto>>(arg: () => T) {
  try {
    let rtn: T = arg();
    let result: ResultDto<any> = await rtn;
    //  if (result.warning === true) {
    //    rootStore.dispatch(setNotification({ message: result.info, notifyType: NotifyType.error }));
    //   return;
    // }
    return result;
  } catch (error) {
    console.log("❌", error)
    if ((error as any).status === 401) {
      window.location.replace(BASE_PATH + "/error")
      rootStore.dispatch(setNotification({ message: "Errore 🧨", notifyType: NotifyType.error }));

    }
    if ((error as any).status === 400) {
      rootStore.dispatch(setNotification({ message: "Bad Request", notifyType: NotifyType.error }));
    }
  }


}
export async function ApiCallWithErrorHandlingFile<TDto extends Promise<ReturnFile>>(arg: () => TDto) {

  try {
    let result = await arg();

    return result;
  } catch (error) {
    if ((error as any).status === 401) {
      window.location.replace(BASE_PATH + "/error")
    }

    if ((error as any).status === 400) {
      rootStore.dispatch(setNotification({ message: "Bad Request", notifyType: NotifyType.error }));
    }
  }
}

export interface CommonValidation {
  response: boolean;
  property: string[];
}

export function simplifyPath(points: PositionAndDates[], epsilon: number): PositionAndDates[] {
  // Calcola la distanza di un punto da una linea
  const getPerpendicularDistance = (point: PositionAndDates, lineStart: PositionAndDates, lineEnd: PositionAndDates) => {
    let dx = lineEnd.lat - lineStart.lat;
    let dy = lineEnd.lng - lineStart.lng;
    // Normalizza
    const mag = Math.sqrt(dx * dx + dy * dy);
    if (mag > 0) {
      dx /= mag;
      dy /= mag;
    }
    const pvx = point.lat - lineStart.lat;
    const pvy = point.lng - lineStart.lng;
    // Coordinata di punto vettoriale
    const pvdot = dx * pvx + dy * pvy;
    // Coordinata x, y del punto più vicino
    const dsx = pvdot * dx;
    const dsy = pvdot * dy;
    // Differenza
    const ax = pvx - dsx;
    const ay = pvy - dsy;
    return Math.sqrt(ax * ax + ay * ay);
  };

  // Funzione ricorsiva per la semplificazione effettiva
  const simplifyRecursive = (startIdx: number, endIdx: number, epsilon: number) => {
    let maxDist = 0;
    let index = 0;
    for (let i = startIdx + 1; i < endIdx; i++) {
      let dist = getPerpendicularDistance(points[i], points[startIdx], points[endIdx]);
      if (dist > maxDist) {
        index = i;
        maxDist = dist;
      }
    }
    if (maxDist > epsilon) {
      let res1 = simplifyRecursive(startIdx, index, epsilon);
      let res2 = simplifyRecursive(index, endIdx, epsilon);
      return res1.slice(0, res1.length - 1).concat(res2);
    } else {
      return [points[startIdx], points[endIdx]];
    }
  };

  return simplifyRecursive(0, points.length - 1, epsilon);
}
export const normalizeDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
};
export const formatPriceWithLocale = (price) => {
  // Mappatura dalla locale alla valuta
  const localeToCurrencyMap = {
    'en-US': 'USD',
    'it-IT': 'EUR',
    'en-GB': 'GBP',
    'ja-JP': 'JPY',
    'en-AU': 'AUD',
    'en-CA': 'CAD',
    'de-CH': 'CHF',
    'sv-SE': 'SEK',
    'zh-TW': 'TWD',
    'fr-FR': 'EUR',
    'de-DE': 'EUR',
    'es-ES': 'EUR',
    'ru-RU': 'RUB',
    'ko-KR': 'KRW',
    'pt-BR': 'BRL',
    'nl-NL': 'EUR',
    'da-DK': 'DKK',
    'fi-FI': 'EUR',
    'nb-NO': 'NOK',
    'pl-PL': 'PLN',
  };

  // Ottieni la locale dal browser dell'utente
  const userLocale = navigator.language;

  // Trova la valuta appropriata per la locale. Se la locale non è mappata, usa 'EUR' come fallback.
  const currency = localeToCurrencyMap[userLocale] || 'EUR';

  // Crea un'istanza di Intl.NumberFormat con la locale e la valuta ottenute, e applica la formattazione al prezzo.
  const formatter = new Intl.NumberFormat(userLocale, {
    style: 'currency',
    currency: currency,
  });

  // Restituisce il prezzo formattato.
  return formatter.format(formatPrice(price));
}

function formatPrice(price) {


  try {
    // Verifica se il prezzo è un numero
    if (!isNaN(parseFloat(price)) && isFinite(price)) {
      // Se il prezzo è un numero, lo formatta e lo ritorna
      return price.toFixed(2); // Ad esempio, formatta il prezzo con due decimali
    } else {
      // Se il prezzo non è un numero, ritorna 0
      return 0;
    }
  } catch (error) {
    return price;
  }

}