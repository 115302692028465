 import { ADD_Warehouse_GRID, GET_Warehouse_FILTERS, GET_Warehouse_GRID, WarehouseViewModelGrid } from '../../../Model/Warehouse';

const initState: WarehouseViewModelGrid = {
    gridResult: null,
    filter: null,
}

export const WarehouseGridReducer = (state = initState, action: { type: string, payload: WarehouseViewModelGrid }) => {
  if(action.payload != null && action.payload ){
  const {gridResult,filter}  = action.payload;
  switch (action.type) {
        case GET_Warehouse_GRID:
        case ADD_Warehouse_GRID:
            {
                return { ...state,gridResult }
            }
        case GET_Warehouse_FILTERS:
             return { ...state, filter }
        default:
            return state;
    }
}
return state;}

