import {  CREATE_Movimentazione, MovimentazioneCreate,GET_CREATE_Movimentazione } from "../../../Model/MovimentazioneModel"

const initState: MovimentazioneCreate = {
    dto: null,
    resultDto: null,
}
//const dispatch = useDispatch();


export const MovimentazioneCreateReducer = (state = initState, action: { type: string, payload: MovimentazioneCreate }) => {
  if(action.payload != null && action.payload ){
    const {dto,resultDto}  = action.payload;
  switch (action.type) {
        case CREATE_Movimentazione:
            {

                return { ...state, resultDto }
            }
        case GET_CREATE_Movimentazione:
             return { ...state, dto }
        default:
            return state;
    }
    }
    return state;
}
